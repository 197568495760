import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { IAppContext, AppContext } from '../../containers/App';
import { IApi, IBrand, ICart, ISession } from '../../state/data-types';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { Catalog } from '../../globals/constants';
import Navigation from '../common/Navigation';
import Categories from './Categories';
import Products from './Products';
import { isBlank } from '../../state/utils';

export interface ICatalogManagerProps {
  cart: ICart;
  path: string;
  data: IApi;
  brands: IBrand[];
  brand?: string;
  params: any;
  authenticated: boolean;
  session: ISession;
  showPrices: boolean;
}

const useStyles = makeStyles(() => ({
  emptyListMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '1.1rem',
  },
}));

export default function CatalogManager(props: ICatalogManagerProps, context: IAppContext) {
  const { cart, params, brands, brand, data, session, showPrices } = props;
  const { catalog, family, group, category, subcategory } = params;
  const classes = useStyles();
  const { dispatch, push, actions, lang } = context;

  useEffect(() => {
    if (subcategory === undefined && group !== undefined && group === category) {
      dispatch(push(`/catalogs/${catalog}/${family}/${group}/${!isBlank(brand) ? `?brand=${brand}` : ''}`));
    } else {
      actions.api.getCatalogItems(props.authenticated, catalog, brand, family, group, category, subcategory);
    }
  }, [family, group, category, subcategory]);

  let view = null;
  if (!data.catalogs.error) {
    if (data.catalogs.breadcrumbs && data.catalogs.breadcrumbs.length > 3) {
      view = <Products showPrices={showPrices} session={session} cart={cart} product={data.catalogs.product} breadcrumbs={data.catalogs.breadcrumbs} loading={data.catalogs.loading} catalog={params.catalog} brand={brand} family={params.family} group={params.group} category={params.category} subcategory={params.subcategory} />;
    } else {
      view = <Categories loading={data.catalogs.loading} breadcrumbs={data.catalogs.breadcrumbs} brands={brands} brand={brand} data={data.catalogs.items} catalog={params.catalog} family={params.family} group={params.group} category={params.category} />;
    }
  } else {
    view = (
      <section className="static-page" style={{ backgroundColor: 'white' }}>
        <div className="hero" style={{ backgroundColor: getPrimaryColor(catalog as Catalog).main }}>
          <h1>
            {lang('catalogs.CatalogManager.ourProducts')}
          </h1>
          <h4>
            <Navigation breadcrumbs={data.catalogs.breadcrumbs} catalog={catalog} brand={brand} />
          </h4>
        </div>
        <Container className="content">
          <div className="content">
            <span className={classes.emptyListMessage}>
              {lang('catalogs.CatalogManager.productNotFound')}
            </span>
          </div>
        </Container>
      </section>
    );
  }

  return view;
}

CatalogManager.contextTypes = { ...AppContext };
