import React from 'react';
import { Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { AppContext, IAppContext } from '../../containers/App';

export interface IThankYouForSigningUpProps {}

export default function ThankYouForSigningUp(_props: IThankYouForSigningUpProps, context: IAppContext) {
  const { lang } = context;

  return (
    <div className="static-page">
      <Helmet>
        <title>{lang('user.ThankYouForSigningUp.title')}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.ThankYouForSigningUp.title')}
        </h1>
        <h4 style={{ whiteSpace: 'pre-line' }}>
          {lang('user.ThankYouForSigningUp.subtitle')}
        </h4>
      </div>
      <div
        className="section content"
        style={{
          textAlign: 'center',
          maxWidth: '512px',
        }}
      >
        {lang('user.ThankYouForSigningUp.description')}

        <div style={{ marginTop: '1rem' }}>
          <Link href="/">
            {lang('user.ThankYouForSigningUp.link')}
          </Link>
        </div>
      </div>
    </div>
  );
}

ThankYouForSigningUp.contextTypes = { ...AppContext };
