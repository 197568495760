import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { itIT } from '@material-ui/core/locale';
import { overrides } from './theme.style';

const palette = {
  primary: {
    light: '#FF6561',
    main: '#E4211C',
    dark: '#C21C16',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FF6561',
    main: '#E4211C',
    dark: '#C21C16',
    contrastText: '#fff',
  },
  accent: {
    light: '#FF6561',
    main: '#E4211C',
    dark: '#C21C16',
  },
  text: {
    primary: '#444444',
    dark: '#222222',
  },
};

const proximaTheme = createTheme({
  palette,
  overrides
}, itIT);

export default responsiveFontSizes(proximaTheme);
