import { RSAA } from 'redux-api-middleware';
import Redux, { Dispatch } from 'redux';

import types from '../action-types';
import { getApiEndpoint } from '../utils';
import { ApiEndpoints, Catalog, DEFAULT_CATALOG } from '../../globals/constants';
import { ICart, IContactFormValues, IQuote } from '../data-types';

export function getBrands(catalog: string | undefined) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.BRANDS),
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
        },
        method: 'GET',
        types: [
          types.GET_BRANDS,
          types.GET_BRANDS_SUCCESS,
          types.GET_BRANDS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getCatalogs(auth: boolean, catalog: string | undefined, brand: string | undefined) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(auth ? ApiEndpoints.CATALOGS_AUTH : ApiEndpoints.CATALOGS),
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
          brand,
        },
        ...(auth ? { headers: { Authorization: '' } } : {}),
        method: 'GET',
        types: [
          types.GET_CATALOGS,
          types.GET_CATALOGS_SUCCESS,
          types.GET_CATALOGS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getCatalogItems(auth: boolean, catalog: string | undefined, brand: string | undefined, family?: string, group?: string, category?: string, subcategory?: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    const endpoint = getApiEndpoint(auth ? ApiEndpoints.CATALOGS_ITEMS_AUTH : ApiEndpoints.CATALOGS_ITEMS)
      .replace('/:family', family ? `/${family.toString()}` : '')
      .replace('/:group', group ? `/${group.toString()}` : '')
      .replace('/:category', category ? `/${category.toString()}` : '')
      .replace('/:subcategory', subcategory ? `/${subcategory.toString()}` : '');
    return dispatch({
      [RSAA]: {
        endpoint,
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
          brand,
        },
        ...(auth ? { headers: { Authorization: '' } } : {}),
        method: 'GET',
        types: [
          types.GET_CATALOGS,
          types.GET_CATALOGS_SUCCESS,
          types.GET_CATALOGS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getNews() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.NEWS),
        method: 'GET',
        types: [
          types.GET_NEWS,
          types.GET_NEWS_SUCCESS,
          types.GET_NEWS_FAILURE
        ],
      },
    });
  };
}

export function getFeatured(catalog: Catalog, brand?: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.FEATURED),
        method: 'GET',
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
          brand
        },
        types: [
          types.GET_FEATURED,
          types.GET_FEATURED_SUCCESS,
          types.GET_FEATURED_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getRecentlySeen() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.RECENTLY_SEEN),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.GET_RECENTLY_SEEN,
          types.GET_RECENTLY_SEEN_SUCCESS,
          types.GET_RECENTLY_SEEN_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getPartners(catalog: Catalog, brand?: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.PARTNERS),
        method: 'GET',
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
          brand
        },
        types: [
          types.GET_PARTNERS,
          types.GET_PARTNERS_SUCCESS,
          types.GET_PARTNERS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getBanners(count?: number) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.BANNERS),
        query: {
          count: count || 6
        },
        method: 'GET',
        types: [
          types.GET_BANNERS,
          types.GET_BANNERS_SUCCESS,
          types.GET_BANNERS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getReviews(count?: number) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.REVIEWS),
        query: {
          count: count || 6
        },
        method: 'GET',
        types: [
          types.GET_REVIEWS,
          types.GET_REVIEWS_SUCCESS,
          types.GET_REVIEWS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function searchProducts(catalog: Catalog, brand: string | undefined, search: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.SEARCH).replace(':search', search),
        query: {
          catalogsType: catalog || DEFAULT_CATALOG,
          brand,
        },
        method: 'GET',
        types: [
          types.SEARCH_PRODUCTS,
          types.SEARCH_PRODUCTS_SUCCESS,
          types.SEARCH_PRODUCTS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function getArticlePrice(id: number) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.ARTICLE).replace(':id', id.toString()),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.GET_ARTICLE_PRICE,
          types.GET_ARTICLE_PRICE_SUCCESS,
          types.GET_ARTICLE_PRICE_FAILURE
        ],
      },
    });
  };
}

export function sendForm(body: IContactFormValues) {
  const formData = new FormData();
  Object.keys(body).forEach((k) => {
    formData.append(k, (body as any)[k]);
  });
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.SEND_FORM),
        method: 'POST',
        body: formData,
        types: [
          types.SEND_FORM,
          types.SEND_FORM_SUCCESS,
          types.SEND_FORM_FAILURE
        ],
      },
    });
  };
}

export function requestCredentials(body: IContactFormValues) {
  const formData = new FormData();
  Object.keys(body).forEach((k) => {
    formData.append(k, (body as any)[k]);
  });
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.REQUEST_CREDENTIALS),
        method: 'POST',
        body: formData,
        types: [
          types.REQUEST_CREDENTIALS,
          types.REQUEST_CREDENTIALS_SUCCESS,
          types.REQUEST_CREDENTIALS_FAILURE
        ],
      },
    });
  };
}

export function getMetaData(slug: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.META).replace(':slug', slug),
        method: 'GET',
        types: [
          types.API_META_INFO,
          types.API_META_INFO_SUCCESS,
          types.API_META_INFO_FAILURE
        ],
      },
    });
  };
}

export function getOrders(skip = 0, limit = 10) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.ORDERS).replace(':skip', skip.toString()).replace(':limit', limit.toString()),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.API_GET_ORDERS,
          types.API_GET_ORDERS_SUCCESS,
          types.API_GET_ORDERS_FAILURE
        ],
      },
    });
  };
}

export function getFavouriteProducts(skip = 0, limit = 10) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.FAVOURITE_PRODUCT_ITEMS).replace(':skip', skip.toString()).replace(':limit', limit.toString()),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.API_GET_FAVOURITE_PRODUCTS,
          types.API_GET_FAVOURITE_PRODUCTS_SUCCESS,
          types.API_GET_FAVOURITE_PRODUCTS_FAILURE
        ],
      },
    });
  };
}

export function getBackOrders() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.BACK_ORDERS),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.API_GET_BACK_ORDERS,
          types.API_GET_BACK_ORDERS_SUCCESS,
          types.API_GET_BACK_ORDERS_FAILURE
        ],
      },
    });
  };
}

export function refreshOrders() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.REFRESH_ORDERS),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.API_REFRESH_ORDERS,
          types.API_REFRESH_ORDERS_SUCCESS,
          types.API_REFRESH_ORDERS_FAILURE
        ],
      },
    });
  };
}

export function getDocuments(visibility: 'private' | 'public') {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(visibility === 'private' ? ApiEndpoints.DOCUMENT : ApiEndpoints.DOCUMENT_PUBLIC),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.GET_DOCUMENT_CATEGORIES,
          types.GET_DOCUMENT_CATEGORIES_SUCCESS,
          types.GET_DOCUMENT_CATEGORIES_FAILURE
        ],
      },
    });
  };
}

export function getDocument(slug: string, visibility: 'private' | 'public') {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(visibility === 'private' ? ApiEndpoints.DOCUMENT_DETAIL : ApiEndpoints.DOCUMENT_DETAIL_PUBLIC).replace(':slug', slug),
        method: 'GET',
        headers: {
          Authorization: '',
        },
        types: [
          types.GET_DOCUMENT_CATEGORY_FILES,
          types.GET_DOCUMENT_CATEGORY_FILES_SUCCESS,
          types.GET_DOCUMENT_CATEGORY_FILES_FAILURE
        ],
      },
    });
  };
}

export function saveQuote(cart: ICart) {
  const formData = new FormData();
  formData.append('showClient', 'false');
  formData.append('temporaryCart', 'false');
  formData.append('cartJson', JSON.stringify(cart));

  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.WISHLIST_EDIT).replace('/:id', ''),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        body: formData,
        types: [
          { type: types.API_SAVE_QUOTE },
          { type: types.API_SAVE_QUOTE_SUCCESS },
          { type: types.API_SAVE_QUOTE_FAILURE }
        ],
      },
    });
  };
}

export function editQuote(id: number, quote: IQuote) {
  const formData = new FormData();
  formData.append('showClient', String(quote.showClient));
  formData.append('temporaryCart', 'false');
  formData.append('cartJson', JSON.stringify(quote.cartJson));
  formData.append('wishlistId', String(id));

  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.WISHLIST_EDIT),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        body: formData,
        types: [
          { type: types.API_SAVE_QUOTE },
          { type: types.API_SAVE_QUOTE_SUCCESS },
          { type: types.API_SAVE_QUOTE_FAILURE }
        ],
      },
    });
  };
}

export function deleteQuote(id: number) {
  const formData = new FormData();
  formData.append('temporaryCart', 'false');
  formData.append('wishlistId', String(id));

  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.WISHLIST_DELETE),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        body: formData,
        types: [
          { type: types.API_DELETE_QUOTE },
          { type: types.API_DELETE_QUOTE_SUCCESS },
          { type: types.API_DELETE_QUOTE_FAILURE }
        ],
      },
    });
  };
}

export function getSavedQuotes(skip = 0, limit = 10) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.WISHLISTS).replace(':skip', skip.toString()).replace(':limit', limit.toString()),
        method: 'GET',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        types: [
          types.API_GET_QUOTES,
          types.API_GET_QUOTES_SUCCESS,
          types.API_GET_QUOTES_FAILURE
        ],
      },
    });
  };
}
