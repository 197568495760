import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Hidden, CircularProgress, Box, Theme, Button, Typography, Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { AppContext, IAppContext } from '../../containers/App';
import { ICart, ICustomer, IOrder, IOrderArticle } from '../../state/data-types';
import { AddOrderToCartDialog } from './AddOrderToCartDialog';
import { priceToString } from '../../globals/localization';
import { partitionOutAndInCatalog } from '../../utils/orders/partition';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '2rem'
  },
  productHeader: {
    width: '100%',
    display: 'flex',
    padding: '1.5rem 2rem 1rem',
    color: '#666',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    backgroundColor: '#f9f9f9',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
    }
  },
  tableHeader: {
    padding: '0rem 2rem',
    position: 'relative',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      }
    }
  },
  productFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 2rem',
  },
  productContainer: {
    borderRadius: '3px',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

export interface IBackOrdersProps {
  cart: ICart;
  orders: IOrder[];
  loading: boolean;
  refreshing: boolean;
  user?: ICustomer;
  showPrices: boolean;
}

export default function BackOrders(props: IBackOrdersProps, context: IAppContext) {
  const { orders, loading, refreshing, cart, showPrices } = props;
  const classes = useStyles();

  React.useEffect(() => {
    context.actions.api.getBackOrders();
  }, []);

  const refreshData = () => {
    context.actions.api.refreshOrders().then(response => {
      if (!response.error) {
        context.actions.api.getBackOrders();
      }
    });
  };

  const { lang } = context;

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.BackOrders.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.BackOrders.title')}
        </h1>
      </div>
      <Hidden xsUp={loading}>
        <Container style={{ marginTop: '2rem', marginBottom: '0' }}>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ position: 'relative', width: '14.5rem' }}>
                <Button className="animate-glow green-glow" variant="contained" color="primary" disabled={refreshing} onClick={() => refreshData()}>
                  {lang('user.BackOrders.refreshData')}
                </Button>
                <Hidden xsUp={!refreshing}>
                  <div className={classes.loader}>
                    <CircularProgress size={20} />
                  </div>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      <BackOrderSection showPrices={showPrices} cart={cart} user={props.user} loading={loading} orders={orders} />
    </section>
  );
}

BackOrders.contextTypes = { ...AppContext };

export interface IOrderProps {
  cart: ICart;
  orders: IOrder[];
  loading: boolean;
  limit?: number;
  user?: ICustomer;
  showPrices: boolean;
}

export function BackOrderSection(props: IOrderProps, context: IAppContext) {
  const { loading, orders, limit, cart, showPrices } = props;
  const classes = useStyles();

  const { lang } = context;

  const [viewOrderDialog, showOrderDialog] = React.useState<string | null>(null);

  return (
    <Container className="content">
      <Hidden xsUp={!loading}>
        <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress size={30} />
        </div>
      </Hidden>
      <Hidden xsUp={loading || orders.length === 0}>
        <Grid container>
          {
            orders.slice(0, limit || orders.length).map((order: IOrder, index: number) => {
              let deliveryPoint;
              if (order.indirizzoCon && order.localitaCon && order.capCon && order.nazioneCon) {
                deliveryPoint = `${order.indirizzoCon} - ${order.capCon} ${order.localitaCon} (${order.nazioneCon})`;
              }
              const { articlesInCatalog, articlesOutOfCatalog/* , discounts */ } = partitionOutAndInCatalog(order.articles);

              return (
                <Grid item xs={12} key={`${order.numeroDocumento}_${index}`}>
                  <Box boxShadow={1} className={classes.productContainer} style={{ width: '100%' }}>
                    <Grid container className={classes.productHeader}>
                      <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          DATA:
                        </span>
                        <span>
                          {moment(order.dataDocumento).format('D MMMM YYYY')}
                        </span>
                      </Grid>
                      <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          ORDINE:
                        </span>
                        <span>
                          {`#${order.numeroDocumento}`}
                        </span>
                      </Grid>
                      <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Hidden xsUp={deliveryPoint === undefined}>
                          <span>
                            {lang('user.BackOrders.orderStatus')}
                          </span>
                          <span>
                            {order.statoDocumento || '--'}
                          </span>
                        </Hidden>
                      </Grid>
                      <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Hidden xsUp={deliveryPoint === undefined}>
                          <span>
                            {lang('user.BackOrders.deliveredTo')}
                          </span>
                          <span>
                            {deliveryPoint}
                          </span>
                        </Hidden>
                      </Grid>
                      <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          variant="outlined"
                          disableElevation
                          color="primary"
                          size="small"
                          style={{ fontWeight: 600, marginLeft: 'auto', padding: '4px 12px' }}
                          onClick={() => { showOrderDialog(`${order.numeroDocumento}_${index}`); }}
                        >
                          {lang('user.BackOrders.orderAgain')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%', marginBottom: '1.5rem', opacity: 0.8 }} />
                    <Hidden smDown>
                      <Grid container className={classes.tableHeader}>
                        <Grid item xs={2} className={classes.label} />
                        <Grid item xs={2} className={classes.label}>
                          {lang('user.BackOrders.code')}
                        </Grid>
                        <Grid item xs={showPrices ? 3 : 4} className={classes.label}>
                          {lang('user.BackOrders.product')}
                        </Grid>
                        <Grid item xs={1} className={classes.label}>
                          {lang('user.BackOrders.quantity')}
                        </Grid>
                        <Grid item xs={1} className={classes.label}>
                          {lang('user.BackOrders.outstandingQuantity')}
                        </Grid>
                        {showPrices
                        && (
                        <Grid item xs={1} className={`${classes.label} end`}>
                          {lang('user.BackOrders.price')}
                        </Grid>
                        )}
                        <Grid item xs={2} className={`${classes.label} end`}>
                          {lang('user.BackOrders.fulfillmentDate')}
                        </Grid>
                      </Grid>
                    </Hidden>
                    <div style={{ width: '100%' }}>
                      {
                        articlesInCatalog.map((item: IOrderArticle, i: number) => (
                          <div key={i}>
                            <BackOrderRow showPrices={showPrices} item={item} index={i} />
                          </div>
                        ))
                      }
                      {articlesOutOfCatalog.length > 0 && (
                      <>
                        <div style={{ padding: '1rem 2rem 0.5rem', fontWeight: 'bolder' }}>
                          {lang('user.BackOrders.outOfCatalogItems')}
                        </div>
                        {
                        articlesOutOfCatalog.map((item: IOrderArticle, i: number) => (
                          <div key={i}>
                            <BackOrderRow showPrices={showPrices} item={item} index={i} />
                          </div>
                        ))
                      }
                      </>
                      )}
                      {/* {discounts.length > 0 && (
                      <>
                        <div style={{ padding: '1rem 2rem 0.5rem', fontWeight: 'bolder' }}>
                          {lang('user.Orders.discounts')}
                        </div>
                        {
                        discounts.map((item: IOrderArticle, i: number) => (
                          <div key={i}>
                            <OrderRow showPrices={showPrices} item={{item, totalPrice: item.price * item.quantity}} index={i} />
                          </div>
                        ))
                      }
                      </>
                      )} */}
                    </div>
                    {showPrices && (
                    <>
                      <Divider />
                      <div className={classes.productFooter}>
                        <span style={{ fontWeight: 600, textTransform: 'uppercase', color: '#666' }}>
                          {lang('user.BackOrders.total', { total: priceToString('€', order.totaleImponibile) })}
                        </span>
                      </div>
                    </>
                    )}
                  </Box>
                  { viewOrderDialog === `${order.numeroDocumento}_${index}`
                    && (
                    <AddOrderToCartDialog
                      showPrices={showPrices}
                      articles={order.articles}
                      onClose={() => { showOrderDialog(null); }}
                      onConfirm={(articles) => {
                        context.actions.session.addArticlesToCart(cart, articles);
                        context.dispatch(context.push('/checkout'));
                      }}
                    />
                    ) }
                </Grid>
              );
            })
          }
        </Grid>
      </Hidden>
      <Hidden xsUp={loading || orders.length > 0}>
        <Typography variant="h5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {lang('user.BackOrders.noBackorders')}
        </Typography>
      </Hidden>
    </Container>
  );
}

BackOrderSection.contextTypes = { ...AppContext };

const itemStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: '.8rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem'
    }
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& span': {
      fontWeight: 'bold',
      fontSize: '.8rem',
      color: '#636363',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
      marginTop: '1rem',
    },
    '& a': {
      textDecoration: 'none !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '.9rem !important',
      '&:hover': {
        textDecoration: 'underline !important',
      }
    },
    '&.inner': {
      color: '#636363',
      '& span': {
        color: '#636363',
      },
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
    '&.end': {
      alignItems: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      }
    }
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '.5rem',
  },
}));

export interface IBackOrderRowProps {
  item: IOrderArticle;
  index: number;
  showPrices: boolean;
}

function BackOrderRow(props: IBackOrderRowProps, context: IAppContext) {
  const { item, index, showPrices } = props;
  const { preview, code, description, quantity, quantityUnfilled, totalPrice, price, deliveryAt } = item;
  const classes = itemStyles();
  const { lang } = context;

  return (
    <Grid container className={`${classes.container} ${index % 2 !== 0 ? classes.even : ''}`}>
      <Grid item xs={12} md={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        { preview ? <img src={preview} alt={description} style={{ maxWidth: '2.5rem', maxHeight: '2.5rem' }} /> : null }
      </Grid>
      <Grid item xs={12} md={2} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.code')}</span>
        </Hidden>
        <NavLink to={item.url || '#'} style={{ fontWeight: 600 }}>
          {code}
        </NavLink>
      </Grid>
      <Grid item xs={12} md={showPrices ? 3 : 4} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.product')}</span>
        </Hidden>
        <NavLink to={item.url || '#'}>
          {description}
        </NavLink>
      </Grid>
      <Grid item xs={12} md={1} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.quantity')}</span>
        </Hidden>
        {quantity}
      </Grid>
      <Grid item xs={12} md={1} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.outstandingQuantity')}</span>
        </Hidden>
        {quantityUnfilled}
      </Grid>
      {showPrices
      && (
      <Grid item xs={12} md={1} className={`${classes.label} end`} style={{ fontWeight: 600 }}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.price')}</span>
        </Hidden>
        {priceToString('€', (totalPrice ?? (quantity * price)))}
      </Grid>
      )}
      <Grid item xs={12} md={2} className={`${classes.label} end`} style={{ fontWeight: 600 }}>
        <Hidden mdUp>
          <span>{lang('user.BackOrders.fulfillmentDate')}</span>
        </Hidden>
        {moment(deliveryAt).format('D MMMM YYYY')}
      </Grid>
    </Grid>
  );
}

BackOrderRow.contextTypes = { ...AppContext };
