/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Theme, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppContext, IAppContext } from '../../containers/App';
import { supportedLangs } from '../../state/initial-state';
import { palette } from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontSize: '13px',
    width: '100%',
    backgroundColor: '#ededed',
    color: '#292929',
    overflow: 'hidden',
    minHeight: '420px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 2rem'
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      color: '#292929'
    },
    '& a': {
      color: '#292929'
    }
  },
  socialList: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      color: '#292929'
    },
    '& a': {
      color: '#292929'
    }
  },
  image: {
    width: '100%',
  },
  info: {
    textAlign: 'center',
    padding: '1rem',
    fontSize: '0.8rem',
    color: '#292929',
    fontStyle: 'italic'
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  socialLink: {
    width: 24,
    height: 24,
    borderRadius: 9999,
    background: '#0176AB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  socialLinkIcon: {
    fill: 'white',
    width: 12,
    height: 12,
  }
}));

export interface IFooterProps {
  // catalog: Catalog;
}

export default function Footer(_props: object, context: IAppContext) {
  const classes = useStyles();
  const { lang } = context;

  return (
    <footer className={classes.container}>
      <div className="section">
        <Grid container item xs={12} spacing={3} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <Grid item xs={12} md={4}>
            <Link href="/" style={{ lineHeight: 0, marginBottom: '40px', display: 'block' }}>
              <img src={require('../../images/logo.svg')} alt={lang('common.Header.companyShortName')} style={{ maxWidth: 224 }} />
            </Link>
            <div className={classes.list}>
              <span>
                <span>{lang('common.Footer.companyAddress.street')}</span>{' '}&ndash;{' '}
                <span>{lang('common.Footer.companyAddress.zipCode')}{' '}{lang('common.Footer.companyAddress.city')}</span>{' '}&ndash;{' '}
                <span>{lang('common.Footer.companyAddress.country')}</span>
              </span>
              <span>{lang('common.Footer.phone')}{' '}<a href={`tel:${lang('common.Footer.companyPhone.number')}`}>{lang('common.Footer.companyPhone.humanReadable')}</a></span>
              <span>{lang('common.Footer.fax')}{' '}{lang('common.Footer.companyFax.humanReadable')}</span>
              <span><a href={`mailto:${lang('common.Footer.companyEmail')}`}>{lang('common.Footer.companyEmail')}</a></span>
            </div>
            <div style={{ marginTop: 20 }}>
              <strong>{lang('common.Footer.otherProjects')}</strong>
              <div style={{ marginTop: 14 }} className={classes.list}>
                <a rel="noopener" target="_blank" href="https://barreantistatiche.it/">{lang('common.Footer.links.barreAntistatiche')}</a>
                <a rel="noopener" target="_blank" href="https://webcleaning.it/">{lang('common.Footer.links.webCleaning')}</a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <strong style={{ textTransform: 'uppercase' }}>{lang('common.Footer.blogCategories')}</strong>
              <div style={{ marginTop: 14 }} className={classes.list}>
                <a rel="noopener" target="_blank" href="https://airex.it/approfondimenti/innovazione/">{lang('common.Footer.links.innovazione')}</a>
                <a rel="noopener" target="_blank" href="https://airex.it/approfondimenti/risparmio/">{lang('common.Footer.links.risparmio')}</a>
                <a rel="noopener" target="_blank" href="https://airex.it/approfondimenti/efficienza/">{lang('common.Footer.links.efficienza')}</a>
                <a rel="noopener" target="_blank" href="https://airex.it/approfondimenti/sicurezza/">{lang('common.Footer.links.sicurezza')}</a>
                <a rel="noopener" target="_blank" href="https://airex.it/approfondimenti/problemi-risolti/">{lang('common.Footer.links.problemiRisolti')}</a>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <strong style={{ textTransform: 'uppercase' }}>{lang('common.Footer.followUs')}</strong>
              <div style={{ marginTop: 14 }} className={classes.socialList}>
                <a href="https://www.facebook.com/airexspa/" rel="noopener" target="_blank" className={classes.socialLink}>
                  <span className="sr-only">Facebook</span>
                  <svg className={classes.socialLinkIcon} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                </a>
                <a href="https://www.instagram.com/airex_spa/" rel="noopener" target="_blank" className={classes.socialLink}>
                  <span className="sr-only">Instagram</span>
                  <svg className={classes.socialLinkIcon} viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                </a>
                <a href="https://www.linkedin.com/company/airex-s.p.a./" rel="noopener" target="_blank" className={classes.socialLink}>
                  <span className="sr-only">LinkedIn</span>
                  <svg className={classes.socialLinkIcon} viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="https://airex.it/" title={lang('common.Footer.airexMainSite')} style={{ fontWeight: 600, color: palette.accent.main, fontSize: 16 }}>{lang('common.Footer.airexMainSite')}</a>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {lang('common.Footer.companyRecap')}{' '}|{' '}
            <a href="https://www.iubenda.com/privacy-policy/85431356" className="iubenda-nostyle iubenda-embed" title={lang('common.Footer.privacyPolicy')}>{lang('common.Footer.privacyPolicy')}</a>{' '}|{' '}
            <a href="https://www.iubenda.com/privacy-policy/85431356/cookie-policy" className="iubenda-nostyle iubenda-embed" title={lang('common.Footer.cookiePolicy')}>{lang('common.Footer.cookiePolicy')}</a>{' '}|{' '}
            <a href="https://www.iubenda.com/termini-e-condizioni/85431356" className="iubenda-nostyle iubenda-embed" title={lang('common.Footer.termsAndConditions')}>{lang('common.Footer.termsAndConditions')}</a>{' '}|{' '}
            {/* <NavLink to="/company">{lang('common.Footer.company')}</NavLink> */}
            {/* <NavLink to="/contacts">{lang('common.Footer.contacts')}</NavLink> */}
          </Grid>
          <Grid item xs={12} md={4}>
            {lang('common.Footer.companyRecapFinancials')}
          </Grid>
        </Grid>
        <div style={{ paddingTop: 24 }}>
          <div style={{ paddingBottom: 10, textAlign: 'center' }}>
            {lang('common.Footer.changeLanguage')}
          </div>
          <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
            {supportedLangs.map((isoLang) => <Button key={isoLang} color="inherit" onClick={() => context.actions.app.setLang(isoLang)}>{isoLang}</Button>)}
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.contextTypes = { ...AppContext };
