import React, { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { NavLink } from 'react-router-dom';
import { Typography, Container, Card, CardActionArea, CardMedia, CardContent, Hidden, CircularProgress, Theme, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WallpaperRounded, ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';
import { IAppContext, AppContext } from '../../containers/App';
import { IProductSection } from '../../state/data-types';
import { Catalog, DEFAULT_CATALOG } from '../../globals/constants';
import themeStyle from '../../styles/theme.style';
import { catalogDisplayName } from '../catalogs/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '0 2rem 4rem',
    position: 'relative',
  },
  slideContainer: {
    display: 'flex',
    willChange: 'transform',
    margin: '2rem 2rem 4rem',
  },
  slide: {
    display: 'flex',
    width: '100%',
  },
  slideItem: {
    flex: '0 0 auto',
    position: 'relative',
  },
  title: {
    marginTop: '3rem',
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '1.3rem',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: '1rem',
    padding: '0 3rem'
  },
  logo: {
    position: 'absolute',
    left: '0rem',
    top: '3.2rem',
    height: '1.5rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonLeft: {
    position: 'absolute',
    bottom: '-50px',
    left: '-30px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonRight: {
    position: 'absolute',
    bottom: '-50px',
    right: '-30px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sliderDots: {
    bottom: '-32px',
    '& li button:before': {
      backgroundColor: 'transparent',
      border: `3px solid ${theme.palette.primary.main}`,
      width: '11px',
      height: '11px',
      marginTop: '2px',
      borderRadius: '50px',
      opacity: 1,
      content: '\' \'',
      transition: 'all .5s'
    },
    '& li.slick-active button:before': {
      backgroundColor: theme.palette.primary.main,
      width: '15px',
      height: '15px',
      marginTop: 0,
      borderRadius: '50px',
      opacity: 1,
      content: '\' \'',
      transition: 'all .5s'
    }
  }
}));

export interface IFeaturedProps {
  featured: Array<IProductSection>;
  loading: boolean;
  catalog: Catalog;
}

export default function Featured(props: IFeaturedProps, context: IAppContext) {
  const { lang } = context;
  const { featured, loading, catalog } = props;
  const classes = useStyles();
  const slider = useRef<Slider | null>(null);

  useEffect(() => {
    context.actions.api.getFeatured(catalog);
  }, [catalog]);

  const lgUp = useMediaQuery(themeStyle.breakpoints.up('lg'));
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: lgUp ? 4 : 1,
    slidesToScroll: lgUp ? 4 : 1,
    dots: true,
    dotsClass: `slick-dots ${classes.sliderDots}`
  } as Settings;

  return (
    <section>
      <Container className={classes.container} maxWidth="xl">
        <Typography variant="body1" className={classes.title}>
          {lang('home.Featured.title')}
        </Typography>
        <Hidden xsUp={catalog === DEFAULT_CATALOG}>
          <img className={classes.logo} src={require(`../../images/logo-${catalog}.svg`)} alt={catalogDisplayName[catalog](lang)} />
        </Hidden>

        <Hidden xsUp={!loading}>
          <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loading}>
          <div style={{ position: 'relative', width: '100%' }}>
            <Slider
              {...settings}
              ref={(s) => { slider.current = s; }}
            >
              {
                (featured.length > 5 ? featured : featured.concat(featured)).map((element, index) => {
                  return (
                    <div key={`${element.slug}_${index}`} className={classes.slideItem}>
                      <FeaturedItem product={element} />
                    </div>
                  );
                })
              }
            </Slider>
            <IconButton color="primary" className={classes.buttonLeft} onClick={() => slider.current?.slickPrev()}>
              <ChevronLeftRounded fontSize="large" />
            </IconButton>
            <IconButton color="primary" className={classes.buttonRight} onClick={() => slider.current?.slickNext()}>
              <ChevronRightRounded fontSize="large" />
            </IconButton>
          </div>
        </Hidden>
      </Container>
    </section>
  );
}

Featured.contextTypes = { ...AppContext };

const itemStyles = makeStyles(() => ({
  cardLink: {
    textDecoration: 'none !important'
  },
  item: {
    border: '1px solid white',
    borderBottom: '2px solid white',
    borderRadius: '0px',
    margin: '.8rem',
    height: '100%',

    '&:hover': {
      border: '1px solid #E3E3E3',
      borderBottom: '2px solid rgba(0,0,0,0.64)',
    },

    '& button:hover > span': {
      display: 'none'
    }
  },
  media: {
    height: '200px',
    objectFit: 'contain',
  },
  placeholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    padding: '.5rem 0.2rem',
    minHeight: '4rem',
  }
}));

export interface IFeaturedItemProps {
  product: IProductSection;
}

function FeaturedItem(props: IFeaturedItemProps) {
  const { product } = props;
  const classes = itemStyles();

  return (
    <NavLink to={`/catalogs/${product.catalog}/${product.family}/${product.category}/${product.subcategory}/${product.slug}`} className={classes.cardLink}>
      <Card className={classes.item}>
        <CardActionArea>
          { product.preview ? (
            <CardMedia
              className={classes.media}
              component="img"
              image={product.preview}
              title={product.label}
              alt={product.image_alt_tag}
            />
          ) : (
            <div className={classes.placeholder}>
              <WallpaperRounded style={{ fontSize: '4rem', opacity: '0.5' }} />
            </div>
          )}
          <CardContent>
            <Typography variant="h6" component="h2" className={classes.label}>
              {product.label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </NavLink>
  );
}

FeaturedItem.contextTypes = { ...AppContext };
