import keyMirror from 'keymirror';

export const ApiEndpoints = keyMirror({
  BRANDS: null,
  CATALOGS: null,
  CATALOGS_ITEMS: null,
  CATALOGS_AUTH: null,
  CATALOGS_ITEMS_AUTH: null,
  PRODUCTS: null,
  SEARCH: null,
  NEWS: null,
  FEATURED: null,
  RECENTLY_SEEN: null,
  PARTNERS: null,
  BANNERS: null,
  REVIEWS: null,
  SEND_FORM: null,
  REQUEST_CREDENTIALS: null,
  LOGIN: null,
  PASW_RECOVERY: null,
  CHANGE_PASW: null,
  META: null,
  USER: null,
  CLIENTS: null,
  PRICES: null,
  ARTICLE: null,
  ORDERS: null,
  FAVOURITE_PRODUCT_ITEMS: null,
  SET_FAVOURITE_PRODUCT_ITEM: null,
  BACK_ORDERS: null,
  REFRESH_ORDERS: null,
  CREATE_ORDER: null,
  DOCUMENT: null,
  DOCUMENT_DETAIL: null,
  DOCUMENT_PUBLIC: null,
  DOCUMENT_DETAIL_PUBLIC: null,
  TEMPORARY_CART: null,
  WISHLISTS: null,
  WISHLIST_EDIT: null,
  WISHLIST_DELETE: null,
  FAST_CHECKOUT_CHECK: null,
});

export default {
  api: {
    BRANDS: ':endpoint/api/brands',
    CATALOGS: ':endpoint/api/catalogs',
    CATALOGS_ITEMS: ':endpoint/api/catalogs/:family/:group/:category/:subcategory',
    CATALOGS_AUTH: ':endpoint/apisec/catalogs',
    CATALOGS_ITEMS_AUTH: ':endpoint/apisec/catalogs/:family/:group/:category/:subcategory',
    SEARCH: ':endpoint/api/search/:search',
    NEWS: ':endpoint/api/news',
    FEATURED: ':endpoint/api/featuredProducts',
    RECENTLY_SEEN: ':endpoint/apisec/product-recently-seen',
    PARTNERS: ':endpoint/api/partner',
    BANNERS: ':endpoint/api/banners',
    REVIEWS: ':endpoint/api/reviews',
    SEND_FORM: ':endpoint/api/contacts',
    REQUEST_CREDENTIALS: ':endpoint/api/request-credentials',
    LOGIN: ':endpoint/apisec/login',
    PASW_RECOVERY: ':endpoint/apisec/password-recovery',
    CHANGE_PASW: ':endpoint/apisec/change-password',
    META: ':endpoint/api/staticpage/:slug',
    USER: ':endpoint/apisec/user',
    CLIENTS: ':endpoint/apisec/clients',
    ARTICLE: ':endpoint/apisec/prices/article/:id',
    PRICES: ':endpoint/apisec/prices/:slug',
    ORDERS: ':endpoint/apisec/orders/:skip/:limit',
    FAVOURITE_PRODUCT_ITEMS: ':endpoint/apisec/product-favorite/:skip/:limit',
    SET_FAVOURITE_PRODUCT_ITEM: ':endpoint/apisec/product-favorite/edit',
    BACK_ORDERS: ':endpoint/apisec/orders-unfilled',
    REFRESH_ORDERS: ':endpoint/apisec/request-update-orders',
    CREATE_ORDER: ':endpoint/apisec/order',
    DOCUMENT: ':endpoint/apisec/docs',
    DOCUMENT_DETAIL: ':endpoint/apisec/docs/:slug',
    DOCUMENT_PUBLIC: ':endpoint/api/public-docs',
    DOCUMENT_DETAIL_PUBLIC: ':endpoint/api/public-docs/:slug',
    TEMPORARY_CART: ':endpoint/apisec/temporary-cart',
    WISHLISTS: ':endpoint/apisec/wishlists/:skip/:limit',
    WISHLIST_EDIT: ':endpoint/apisec/wishlist/edit',
    WISHLIST_DELETE: ':endpoint/apisec/wishlist/delete',
    FAST_CHECKOUT_CHECK: ':endpoint/apisec/checkCheckout',
  },
};

export const enum Catalog {
  AIREX = 'airex',
  PROXIMA = 'proxima',
}
export const DEFAULT_CATALOG = Catalog.AIREX;
