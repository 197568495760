import Redux, { Middleware } from 'redux';
import { History, createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { readSession, writeSession } from './session/actions';
import { IState } from './data-types';
import initialState from './initial-state';

// eslint-disable-next-line import/no-mutable-exports
let configureStore: (initialState: IState, routerMiddleware: Middleware, history: History<any>) => Redux.Store;
if (process.env.NODE_ENV === 'production') {
  configureStore = require('./store.prod').default;
} else {
  configureStore = require('./store.dev').default;
}

if (module.hot) {
  module.hot.accept('./store.dev', () => {
    configureStore = require('./store.dev').default;
  });
}

const initialValues = {
  ...initialState,
  session: { ...initialState.session, ...readSession() }
};

const history = createBrowserHistory();
const middleware = routerMiddleware(history);
const store = configureStore(initialValues, middleware, history);

// Listen to changes and pesist the session store
store.subscribe(() => {
  const session = { ...store.getState().session };
  writeSession(session);
});

export { store, history };
