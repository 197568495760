import React, { useEffect } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { AppContext, IAppContext } from '../../containers/App';
import { INews } from '../../state/data-types';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: '3rem 0',
  },
  image: {
    width: '90%'
  }
}));

export interface INewsProps {
  news: Array<INews>;
}

export default function News(props: INewsProps, context: IAppContext) {
  const classes = useStyles();
  const { news } = props;

  useEffect(() => {
    context.actions.api.getNews();
  }, []);

  const lastNews = news.length > 0 ? news[0] : null;

  return lastNews ? (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={9} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2rem' }}>
          <img className={classes.image} src={lastNews.icon} alt={lastNews.label} />
        </Grid>
        <Grid item xs={12} lg={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '1rem' }}>
          <Typography variant="h4" style={{ marginTop: '3rem', marginBottom: '1rem', textAlign: 'left' }}>
            {lastNews.label}
          </Typography>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: lastNews.description || '' }} />
        </Grid>
      </Grid>
    </Container>
  ) : null;
}

News.contextTypes = { ...AppContext };
