import { Redirect } from 'react-router';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import ClientsPage from '../../components/agent/ClientsPage';
import CatalogManager from '../../components/catalogs/CatalogManager';
import Catalogs from '../../components/catalogs/Catalogs';
import Search from '../../components/catalogs/Search';
import AgentNavBar from '../../components/common/AgentNavBar';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import Company from '../../components/company/Company';
import Contacts from '../../components/contacts/Contacts';
import Home from '../../components/home/Home';
import Privacy from '../../components/privacy/Privacy';
import Checkout from '../../components/user/Checkout';
import DocumentDetail from '../../components/user/DocumentDetail';
import Documents from '../../components/user/Documents';
import Login from '../../components/user/Login';
import Orders from '../../components/user/Orders';
import UserPage from '../../components/user/UserPage';
import { Catalog, DEFAULT_CATALOG } from '../../globals/constants';
import { IApi, ISession, IState, Role } from '../../state/data-types';
import proximaTheme from '../../styles/proximaTheme.style';
import theme from '../../styles/theme.style';
import { AppContext, IAppContext } from '../App';
import styles from './HomePage.style';
import BackOrders from '../../components/user/BackOrders';
import Favourite from '../../components/user/Favourite';
import FastCheckout from '../../components/user/FastCheckout';
import { useLocalStorageState } from '../../globals/local-storage';
import Quotes from '../../components/user/Quotes';
import ThankYouForSigningUp from '../../components/user/ThankYouForSigningUp';

export const getTheme = (catalog: Catalog) => {
  switch (catalog) {
    case Catalog.AIREX: return theme;
    case Catalog.PROXIMA: return proximaTheme;
    default: return theme;
  }
};

export const getPrimaryColor = (catalog: Catalog) => {
  return getTheme(catalog).palette.primary;
};

interface IProps {
  match: any;
  location: any;
  classes: any;
  api: IApi;
  session: ISession;
  search: string;
  dispatch: any;
}

const useStyles = makeStyles(styles);

const HomePage = (props: IProps, context: IAppContext) => {
  const classes = useStyles();
  const { actions } = context;
  const { match, api, session, search } = props;
  const [showPricesPreference] = useLocalStorageState('showPrices', true); // session.showPrices ?? false;
  const showPrices = showPricesPreference && session.authenticated;

  let view = <Home api={api} />;
  const catalog = match.params.catalog ? (match.params.catalog as Catalog) : DEFAULT_CATALOG;

  useEffect(() => {
    context.actions.api.getBrands(catalog);
    if (session.authenticated) {
      if (session.role === Role.Client) {
        actions.api.getSavedQuotes(0, 1); // refresh whether we have at least one quote associated with the client (an agent has always access to them).
        actions.session.getUser();
      } else if (session.role === Role.Agent) {
        actions.session.getClients();
      }
    }
  }, [catalog]);

  const brand = queryString.parse(search).brand as string;

  if (match.path === '/login') {
    view = <Login session={session} redirectTo={queryString.parse(search).redirectTo as string | undefined} />;
  } else if (match.path === '/clients' || (session.role === Role.Agent && !session.agent?.selectedClient)) {
    view = <ClientsPage session={session} />;
  } else if (match.path === '/company') {
    view = <Company />;
  } else if (match.path === '/catalogs/:catalog' || match.path === '/' || match.path === '') {
    view = <Catalogs authenticated={session.authenticated} brands={api.catalogs.brands} brand={brand} catalogs={api.catalogs.items} catalog={catalog} loading={api.catalogs.loading} api={api} />;
  } else if (match.path === '/catalogs/:catalog/search') {
    const q = queryString.parse(search).q || '';
    view = <Search loading={api.search.loading} search={(q as string)} brand={brand} catalog={catalog} data={api.search.products} />;
  } else if (
    match.path === '/catalogs/:catalog/:family'
    || match.path === '/catalogs/:catalog/:family/:group'
    || match.path === '/catalogs/:catalog/:family/:group/:category'
    || match.path === '/catalogs/:catalog/:family/:group/:category/:subcategory'
  ) {
    view = <CatalogManager showPrices={showPrices} authenticated={session.authenticated} cart={session.cart} path={match.path} brands={api.catalogs.brands} brand={brand} params={match.params} data={api} session={session} />;
  } else if (match.path === '/contacts') {
    view = <Contacts loading={api.contacts.loading} error={api.contacts.error} />;
  } else if (match.path === '/privacy') {
    view = <Privacy />;
  } else if (match.path === '/public-documents') {
    view = <Documents visibility="public" documents={api.documents} authenticated={session.authenticated} />;
  } else if (match.path === '/public-documents/:documentId') {
    view = <DocumentDetail visibility="public" documents={api.documents} params={match.params} authenticated={session.authenticated} />;
  } else if (match.path === '/thank-you-for-signing-up') {
    view = <ThankYouForSigningUp />;
  } else {
    // protected routes
    // eslint-disable-next-line no-lonely-if
    if (!session.authenticated) {
      view = <Redirect to={`/login?redirectTo=${encodeURIComponent(match.url)}`} />;
    } else if (match.path === '/user') {
      view = <UserPage session={session} api={api} />;
    } else if (match.path === '/documents') {
      view = <Documents visibility="private" documents={api.documents} authenticated={session.authenticated} />;
    } else if (match.path === '/documents/:documentId') {
      view = <DocumentDetail visibility="private" documents={api.documents} params={match.params} authenticated={session.authenticated} />;
    } else if (match.path === '/orders') {
      view = <Orders showPrices={showPrices} user={session.user} cart={session.cart} loading={api.orders.loading} orders={api.orders.items} refreshing={api.orders.refreshing} pageCount={api.orders.pageCount} />;
    } else if (match.path === '/favourite') {
      view = <Favourite user={session.user} products={api.favouriteProducts.products} loading={api.favouriteProducts.loading} pageCount={api.favouriteProducts.pageCount} cart={session.cart} />;
    } else if (match.path === '/back-orders') {
      view = <BackOrders showPrices={showPrices} user={session.user} cart={session.cart} loading={api.backOrders.loading} orders={api.backOrders.items} refreshing={api.backOrders.refreshing} />;
    } else if (match.path === '/checkout') {
      view = <Checkout showPrices={showPrices} session={session} loadingOrders={session.loadingOrders} loadingUser={session.loadingUser} items={session.cart?.items} orderChecked={session.cart.orderChecked} />;
    } else if (match.path === '/fast-checkout') {
      view = <FastCheckout session={session} loadingUser={session.loadingUser} />;
    } else if (match.path === '/my-quotes') {
      view = <Quotes showPrices={showPrices} user={session.user} session={session} cart={session.cart} loading={api.quotes.loading} quotes={api.quotes.items} pageCount={api.quotes.pageCount} />;
    }
  }

  return (
    <ThemeProvider theme={getTheme(catalog)}>
      <div className={classes.root}>
        {
          api.meta ? (
            <Helmet>
              <title>{api.meta.meta_title || ''}</title>
              <meta name="description" content={api.meta.meta_description || ''} />
            </Helmet>
          ) : null
        }
        <Header match={match} checkoutOrders={session.cart.items || []} session={session} catalog={catalog} />
        <AgentNavBar match={match} session={session} catalog={catalog} />
        { view }
        <Footer />
      </div>
    </ThemeProvider>
  );
};

function mapStateToProps(state: IState) {
  return {
    api: state.api,
    session: state.session,
    search: state.router.location.search,
  };
}

HomePage.contextTypes = { ...AppContext };

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(HomePage);
