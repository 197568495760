import React from 'react';
import { Hidden, Typography, Card, alpha, Divider } from '@material-ui/core';
import { RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { AppContext, IAppContext } from '../../containers/App';
import { IDeliveryPoint } from '../../state/data-types';
import themeStyle from '../../styles/theme.style';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressName: {
    fontWeight: 600,
    width: '100%',
  },
  address: {
    fontSize: '.9rem !important',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '2rem',
  }
}));

export interface IOfficeCardProps {
  office: IDeliveryPoint;
  selected?: boolean;
  onClick?: () => void;
  dense?: boolean;
}

export default function OfficeCard(props: IOfficeCardProps, context: IAppContext) {
  const { office, selected, dense = false, onClick } = props;
  const { lang } = context;
  const classes = useStyles();

  return (
    <Card
      style={{
        backgroundColor: selected ? alpha(themeStyle.palette.primary.main, 0.05) : '',
        border: selected ? `1px solid ${alpha(themeStyle.palette.primary.main, 0.4)}` : '1px solid #f1f1f1',
        padding: dense ? '.4rem .8rem' : '.8rem 1.2rem',
        cursor: onClick !== undefined ? 'pointer' : '',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <Hidden xsUp={selected === undefined}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1.5rem' }}>
          { selected ? <RadioButtonCheckedRounded style={{ color: themeStyle.palette.primary.main }} /> : <RadioButtonUncheckedRounded /> }
        </div>
      </Hidden>
      <div className={classes.column} style={{ flex: 1 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body2" className={classes.addressName} style={{ textTransform: 'none' }}>
            {office.name}
          </Typography>
          <Typography variant="overline" style={{ opacity: 0.6 }}>
            {office.isMainAddress ? lang('user.OfficeCard.main') : ''}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.address}>
          {`${office.address}, ${office.streetNumber}`}
        </Typography>
        <Hidden xsUp={!office.addressExtra}>
          <Typography variant="body2" className={classes.address}>
            {office.addressExtra}
          </Typography>
        </Hidden>
        <Typography variant="body2" className={classes.address}>
          {`${office.cap} ${office.city} (${office.province}), ${office.state}`}
        </Typography>

        <Hidden xsUp={!office.agentCode}>
          <Typography variant="overline" className={classes.addressName} style={{ marginTop: '1rem' }}>
            Agente:
          </Typography>
          <Typography variant="body2" className={classes.address}>
            {`${office.agentName || ''} (${office.agentCode})`}
          </Typography>
        </Hidden>
        <Hidden xsUp={!office.modalita && !office.porto && !office.vettore && !office.pagamento}>
          <Divider style={{ margin: '1rem auto 1rem 0', width: '25%' }} />
        </Hidden>
        <Hidden xsUp={!office.modalita}>
          <Typography variant="body2" className={classes.address}>
            {lang('user.OfficeCard.mode', { mode: office.modalita })}
          </Typography>
        </Hidden>
        <Hidden xsUp={!office.vettore}>
          <Typography variant="body2" className={classes.address}>
            Vettore: {office.vettore}
          </Typography>
        </Hidden>
        <Hidden xsUp={!office.porto}>
          <Typography variant="body2" className={classes.address}>
            Porto: {office.porto}
          </Typography>
        </Hidden>
        <Hidden xsUp={!office.pagamento}>
          <Typography variant="body2" className={classes.address}>
            {lang('user.OfficeCard.payment', { payment: office.pagamento })}
          </Typography>
        </Hidden>
      </div>
    </Card>
  );
}

OfficeCard.contextTypes = { ...AppContext };
