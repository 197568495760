import isError from 'lodash.iserror';
import { IApi } from './data-types';
import constants from '../globals/constants';

export const parseErrorMessage = (error: any) => {
  let msg = 'ERROR';
  if (typeof error === 'string') msg = error;
  else if (Array.isArray(error)) msg = error.join(', ');
  else if (isError(error)) {
    const err: any = error;
    msg = err.message;
    if (err.response && typeof err.response === 'string') {
      msg = msg.concat(', ').concat(err.response);
    } else if (err.response && typeof err.response === 'object' && err.response.errors && Array.isArray(err.response.errors)) {
      msg = msg.concat(', ').concat(err.response.errors.join(', '));
    }
  }

  return msg;
};

export const getApiEndpoint = (endpoint: keyof typeof constants.api) => {
  return constants.api[endpoint].replace(':endpoint', window.configs.ENDPOINT);
};

type CallbackType = (state: any, action?: any) => any;

interface Reducer {
  types: string | [string] | [string, string, string];
  stateKey?: keyof IApi;
  baseState?: [any, any, any];
  data?: [CallbackType | boolean | undefined, CallbackType | boolean | undefined, CallbackType | boolean | undefined];
  callbacks?: [CallbackType, CallbackType, CallbackType];
}

export const reducerGenerator = (r: Reducer[]) => {
  const reducers: { [key: string]: any } = {};
  r.forEach((reducer: Reducer) => {
    const { stateKey = 'noop', types, baseState = [], data, callbacks } = reducer;

    let t;
    let tsuccess;
    let terror;
    let c;
    let csuccess;
    let cerror;

    if (typeof types === 'string') {
      t = types;
      tsuccess = `${types}_SUCCESS`;
      terror = `${types}_ERROR`;
    } else if (Array.isArray(types) && types.length === 1) {
      [t] = types;
      tsuccess = `${types[0]}_SUCCESS`;
      terror = `${types[0]}_ERROR`;
    } else {
      [t, tsuccess, terror] = types;
    }

    const key = stateKey;

    c = (state: any, action: any) => {
      const s: { [key: string]: any } = {};
      const merge: any = { loading: true, error: false };

      // Default behavior
      if (!data || (data && data[0] === true)) {
        /* do nothing */
      } else if (data && typeof data[0] === 'function') {
        // Function behavior
        merge.data = (data[0] as any)(state, action);
      }
      // Nope behavior

      const is = baseState[0].length === 0 ? state[key] : baseState[0];
      s[key] = { ...is, ...merge };
      return { ...state, ...s };
    };

    csuccess = (state: any, action: any) => {
      const s: { [key: string]: any } = {};
      const merge: any = { loading: false, error: false, messageTime: action.messageTime };

      // Default behavior
      if (!data || (data && data[1] === true)) merge.data = action.payload || state[key].data;
      // Function behavior
      else if (data && typeof data[1] === 'function') merge.data = (data[1] as any)(state, action);
      // Nope behavior

      const is = baseState[1].length === 0 ? state[key] : baseState[1];
      s[key] = { ...is, ...merge };

      if (action.mid) merge.data.mid = action.mid;

      return { ...state, ...s };
    };

    cerror = (state: any, action: any) => {
      const s: { [key: string]: any } = {};
      const merge: any = { loading: false, error: true, messageTime: action.messageTime };

      // Default behavior
      if (!data || (data && data[2] === true)) { /* do nothing */
      // Function behavior
      } else if (data && data[2] && typeof data[2] === 'function') merge.data = (data[2] as any)(state, action);
      // Nope behavior

      const is = baseState[2].length === 0 ? state[key] : baseState[2];
      s[key] = { ...is, ...merge };

      if (action.mid) merge.data.mid = action.mid;

      return { ...state, ...s };
    };

    if (callbacks && Array.isArray(callbacks) && callbacks.length === 3) {
      c = callbacks[0] || c;
      csuccess = callbacks[1] || csuccess;
      cerror = callbacks[2] || cerror;
    }

    if (t) reducers[t] = c;
    if (tsuccess) reducers[tsuccess] = csuccess;
    if (terror) reducers[terror] = cerror;
  });
  return reducers;
};

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isBlank(str?: string) {
  return (!str || /^\s*$/.test(str.trim()));
}

export function cutOffAtLine(str?: string | null, lines?: number) {
  if (!str) return '';
  const maxChar = (lines || 2) * 20;
  return `${str.substring(0, maxChar).trim()}${str.length > maxChar ? '...' : ''}`;
}
