import Redux from 'redux';
import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, SET_LANG } from '../app/actions';
import initialState from '../initial-state';
import { IApp, INotification } from '../data-types';
import types from '../action-types';

export default function appReducer(state: IApp = initialState.app, action: Redux.AnyAction) {
  let res = state;

  switch (action.type) {
    case types.API_LOGOUT_SUCCESS:
      return initialState.app;
    case ENQUEUE_SNACKBAR:
      res = {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
      break;

    case CLOSE_SNACKBAR:
      res = {
        ...state,
        notifications: state.notifications.map((notification: INotification) => (
          (action.dismissAll || notification.key === action.key)
            ? ({ ...notification, dismissed: true })
            : ({ ...notification, })
        )),
      };
      break;

    case REMOVE_SNACKBAR:
      res = { ...state,
        ...state,
        notifications: state.notifications.filter(
          (notification: INotification) => notification.key !== action.key
        ),
      };
      break;

    case SET_LANG:
      res = { ...state,
        ...state,
        lang: action.lang,
      };
      localStorage.setItem('lang', action.lang);
      break;

    default: {
      // do nothing
      break;
    }
  }

  return res;
}
