import queryString from 'query-string';
import _ from 'lodash';
import React, { useState, useReducer, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import {
  Button,
  Card,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  alpha,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  CloseRounded,
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { AppContext, IAppContext } from '../../containers/App';
import { ICustomer, IDeliveryPoint, ISession } from '../../state/data-types';
import themeStyle from '../../styles/theme.style';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 0 0 1rem',
    backgroundColor: '#ececec',
    borderRadius: '4px',
    minWidth: '100%',
    minHeight: '3rem',
    marginTop: '.5rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  clientName: {
    fontWeight: 600,
    width: '100%',
    marginBottom: '.4rem',
    lineHeight: '1rem',
  },
  info: {
    fontSize: '.9rem !important',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
}));

export interface IClientsPageProps {
  session: ISession;
}

export default function ClientsPage(
  props: IClientsPageProps,
  context: IAppContext
) {
  const { session } = props;
  const { actions, dispatch, push, lang } = context;
  const classes = useStyles();

  const [page, setPage] = useState(0);

  const getClients = useCallback(
    _.debounce(
      (s: string) => {
        actions.session.getClients(page, s);
      },
      300,
      { leading: false, trailing: true }
    ),
    [page]
  );

  const [search, setSearch] = useReducer((_state, newState) => {
    getClients(newState);
    return newState;
  }, '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };

  React.useEffect(() => {
    actions.session.getClients(page, search);
  }, [page]);

  const [tempClient, checkCart] = useState<ICustomer | undefined>(undefined);

  const changeClient = (client: ICustomer) => {
    actions.session.selectClient(client);
    if (location.pathname === '/clients') {
      const { redirectTo } = queryString.parse(location.search) as {redirectTo?: string};
      if (redirectTo !== undefined && !redirectTo.startsWith('/login')) {
        dispatch(push(redirectTo));
      } else {
        dispatch(push('/user'));
      }
    }
    actions.session.requestSavedCart();
  };

  const mainAddressToString = useCallback((offices: IDeliveryPoint[]) => {
    const mainOffice = offices.find(office => office.isMainAddress) ?? offices[0];
    return `${mainOffice.city} (${mainOffice.province})`; // , ${mainOffice.state}
  }, []);

  const contentView = (
    <div className={classes.container}>
      <Collapse in={session.agent !== undefined}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '.2rem', alignItems: 'stretch' }}
        >
          {session.agent?.clients && session.agent?.clients.length > 0 ? (
            session.agent?.clients.map((client: ICustomer, index: number) => {
              const selected = session.agent?.selectedClient?.id === client.id;
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={`client_${client.id}_${index}`}
                >
                  <Card
                    style={{
                      height: '100%',
                      backgroundColor: selected
                        ? alpha(themeStyle.palette.primary.main, 0.05)
                        : '',
                      border: selected
                        ? `1px solid ${alpha(
                          themeStyle.palette.primary.main,
                          0.4
                        )}`
                        : '1px solid #f1f1f1',
                      padding: '.6rem .8rem',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      if (
                        session.cart
                        && session.cart.items
                        && session.cart.items.length > 0
                      ) {
                        checkCart(client);
                      } else {
                        changeClient(client);
                      }
                    }}
                  >
                    <Hidden xsUp={selected === undefined}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '1.5rem',
                        }}
                      >
                        {selected ? (
                          <RadioButtonCheckedRounded
                            style={{ color: themeStyle.palette.primary.main }}
                          />
                        ) : (
                          <RadioButtonUncheckedRounded />
                        )}
                      </div>
                    </Hidden>
                    <div className={classes.column} style={{ flex: 1 }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="overline"
                          className={classes.clientName}
                        >
                          {`${client.name}${
                            client.description ? client.description : ''
                          }`}
                        </Typography>
                      </div>
                      <Hidden xsUp={!client.code || client.offices.length === 0}>
                        <Typography variant="body2" className={classes.info}>
                          {mainAddressToString(client.offices)}
                        </Typography>
                      </Hidden>
                      <Hidden xsUp={!client.piva}>
                        <Typography variant="body2" className={classes.info}>
                          {lang('agent.ClientPage.vatNumber', {
                            vatNumber: client.piva,
                          })}
                        </Typography>
                      </Hidden>
                      <Hidden xsUp={!client.code}>
                        <Typography variant="body2" className={classes.info}>
                          {lang('agent.ClientPage.clientCode', {
                            clientCode: client.code,
                          })}
                        </Typography>
                      </Hidden>
                    </div>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Typography variant="body1" style={{ margin: '0 auto' }}>
              {lang('agent.ClientPage.noClientFound')}
            </Typography>
          )}
        </Grid>
      </Collapse>
      {tempClient !== undefined ? (
        <Dialog
          open
          onClose={() => checkCart(undefined)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{lang('agent.ClientPage.warning')}</DialogTitle>
          <DialogContent>
            {lang('agent.ClientPage.changeClientWarning')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => checkCart(undefined)}>
              {lang('agent.ClientPage.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => changeClient(tempClient)}
              color="primary"
            >
              {lang('agent.ClientPage.changeClient')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('agent.ClientPage.chooseClient')}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="hero">
        <h1>{lang('agent.ClientPage.chooseClient')}</h1>
      </div>
      <Container className="content">
        <div
          className={classes.row}
          style={{ marginTop: 0, justifyContent: 'center' }}
        >
          <Card className={classes.searchContainer}>
            <InputBase
              placeholder={lang('agent.ClientPage.searchDotDotDot')}
              onChange={handleChange}
              value={search}
              style={{ flex: 1 }}
              autoFocus
            />
            <IconButton onClick={() => setSearch('')}>
              <CloseRounded color="disabled" />
            </IconButton>
          </Card>
        </div>
        <Hidden xsUp={!session.loadingClients}>
          <div
            style={{
              display: 'flex',
              padding: '3rem',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden
          xsUp={
            session.loadingClients
            || session.error !== undefined
            || session.agent === undefined
            || session.agent === null
          }
        >
          {contentView}
        </Hidden>
        <Hidden xsUp={!session.agent?.totalCount}>
          <div
            className={classes.row}
            style={{ marginBottom: 0, justifyContent: 'flex-end' }}
          >
            <Pagination
              count={
                session.agent?.totalCount
                  ? Math.ceil(session.agent.totalCount / 50)
                  : 0
              }
              page={page + 1}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </div>
        </Hidden>
        <Hidden xsUp={session.error === undefined}>
          <Typography
            variant="h5"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {session.error}
          </Typography>
        </Hidden>
      </Container>
    </section>
  );
}

ClientsPage.contextTypes = { ...AppContext };
