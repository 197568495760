import * as api from './api/actions';
import * as session from './session/actions';
import * as app from './app/actions';

export const actions = {
  api,
  session,
  app,
};

type ActionParameters<T> = T extends (...args: infer P) => any ? P : never;
export type IActions<T, P = any> = {
  [K1 in keyof T]: {
    [K2 in keyof T[K1]]: (...args: ActionParameters<T[K1][K2]>) => Promise<P>
  }
};

export default actions;
