import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdatedRef } from '../react-extra/hooks';

export type ControllerProps<T> = {
  value: T;
  onChange?(newValue: T): void;
  children: (props: {
    value: T;
    onChange(newValue: T): void;
    onCommit(newValue?: T): void;
  }) => ReactNode;
};

export function Controller<T>({
  value: externalValue,
  children,
  onChange: externalOnChange,
}: ControllerProps<T>): JSX.Element {
  const [value, _setValue] = useState(externalValue);
  const latestValueRef = useRef(value);
  const setValue = useCallback((v: T) => {
    _setValue(v);
    latestValueRef.current = v;
  }, []);
  useEffect(() => {
    setValue(externalValue);
  }, [externalValue, setValue]);
  const onChange = useCallback(
    (newValue: T) => {
      setValue(newValue);
    },
    [setValue],
  );
  const externalOnChangeRef = useUpdatedRef(externalOnChange);
  const onCommit = useCallback(
    (newValue?: T) => {
      if (newValue !== undefined) {
        setValue(newValue);
        // eslint-disable-next-line no-unused-expressions
        externalOnChangeRef.current?.(newValue);
      } else {
        // eslint-disable-next-line no-unused-expressions
        externalOnChangeRef.current?.(latestValueRef.current);
      }
    },
    [externalOnChangeRef, setValue],
  );
  const childrenProps = useMemo(
    () => ({
      value,
      onChange,
      onCommit,
    }),
    [onChange, onCommit, value],
  );
  return <>{children(childrenProps)}</>;
}
