import { ICartArticle, IOrderArticle } from '../../state/data-types';

export function sortByOutOfCatalogLast<T extends ICartArticle | IOrderArticle>(
  articles: T[]
): T[] {
  return articles.slice().sort((a, b) => {
    if (a.id && !b.id) {
      return -1;
    }
    if (!a.id && b.id) {
      return 1;
    }
    return 0;
  });
}
