import { ICartArticle, IOrderArticle } from '../../state/data-types';

export function partitionOutAndInCatalog<
  T extends ICartArticle | IOrderArticle
>(
  articles: T[]
): {
  articlesOutOfCatalog: T[];
  articlesInCatalog: T[];
  discounts: T[];
} {
  const articlesInCatalog: T[] = [];
  const articlesOutOfCatalog: T[] = [];
  const discounts: T[] = [];
  for (let i = 0; i < articles.length; i++) {
    if (articles[i].code === 'Sconto commerciale') {
      discounts.push(articles[i]);
    } else if (articles[i].id) {
      articlesInCatalog.push(articles[i]);
    } else {
      articlesOutOfCatalog.push(articles[i]);
    }
  }
  return { articlesOutOfCatalog, articlesInCatalog, discounts };
}
