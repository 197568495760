import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import {
  Box,
  Container, Grid, Hidden, IconButton, makeStyles, Theme, Typography, useMediaQuery
} from '@material-ui/core';
import { ChevronLeftRounded, ChevronRightRounded, StarBorderRounded, StarRounded } from '@material-ui/icons';

import { AppContext, IAppContext } from '../../containers/App';
import { IApi } from '../../state/data-types';
import themeStyle from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '0 2rem 4rem',
    position: 'relative',
  },
  slideContainer: {
    display: 'flex',
    willChange: 'transform',
    margin: '2rem 2rem 4rem',
  },
  slide: {
    display: 'flex',
    width: '100%',
  },
  buttonLeft: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: '-30px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonRight: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: '-30px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  image: {
    borderRadius: '200px',
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    objectPosition: 'top',
  }
}));

export interface IReviewsProps {
  api: IApi;
}

export default function Reviews(props: IReviewsProps, context: IAppContext) {
  const classes = useStyles();
  const slider = useRef<Slider | null>(null);

  const { reviews } = props.api;

  React.useEffect(() => {
    context.actions.api.getReviews();
  }, []);

  const lgUp = useMediaQuery(themeStyle.breakpoints.up('lg'));
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: lgUp ? 2 : 1,
    slidesToScroll: lgUp ? 2 : 1,
  } as Settings;

  return (
    <section>
      <Container className={classes.container} maxWidth="xl">
        <div style={{ position: 'relative', width: '100%' }}>
          <Container maxWidth="lg">
            <Slider
              {...settings}
              ref={(s) => { slider.current = s; }}
            >
              {
                reviews.items.length > 0 ? reviews.items.map((element, index) => {
                  return (
                    <div key={`${element.id}_${index}`}>
                      <Grid container style={{ width: '90%', maxWidth: '30rem', margin: '4rem auto 2rem' }}>
                        <Hidden xsUp={!element.image}>
                          <Grid item xs={12} lg={6}>
                            <img className={classes.image} src={element.image || ''} alt={element.image_alt_tag} />
                          </Grid>
                        </Hidden>
                        <Grid item xs={12} lg={!element.image ? 12 : 6}>
                          <Typography variant="h6" style={{ marginTop: '3rem', marginBottom: '.5rem', textAlign: 'left', whiteSpace: 'pre', lineHeight: '1.5rem' }}>
                            {`${element.name}\n${element.job}`}
                          </Typography>
                          <Typography style={{ fontSize: '.9rem', opacity: 0.8, letterSpacing: '-.2px' }}>
                            {element.description}
                          </Typography>
                          <Box display="flex" flexDirection="row" marginTop="0.5rem">
                            {
                              [1, 2, 3, 4, 5].map((idx) => (idx <= element.star ? (
                                <StarRounded key={idx} style={{ color: '#FDD410', marginRight: '.2rem', fontSize: '2rem' }} />
                              ) : (
                                <StarBorderRounded key={idx} style={{ color: '#FDD410', marginRight: '.2rem', fontSize: '2rem' }} />
                              )))
                            }
                            <Typography variant="body1" style={{ marginLeft: '.4rem', fontStyle: 'italic', marginTop: '.3rem', opacity: 0.8 }}>
                              {element.star}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }) : null
              }
            </Slider>
          </Container>
          <Hidden xsUp={reviews.items.length < 3}>
            <IconButton color="primary" className={classes.buttonLeft} onClick={() => slider.current?.slickPrev()}>
              <ChevronLeftRounded fontSize="large" />
            </IconButton>
            <IconButton color="primary" className={classes.buttonRight} onClick={() => slider.current?.slickNext()}>
              <ChevronRightRounded fontSize="large" />
            </IconButton>
          </Hidden>
        </div>
      </Container>
    </section>
  );
}

Reviews.contextTypes = { ...AppContext };
