import Redux from 'redux';
import _ from 'lodash';
import initialState from '../initial-state';
import types from '../action-types';
import { ISession, Role } from '../data-types';
import { clearCartSession } from './actions';

const wrongEsito = [
  'Coupon non valido o già utilizzato',
  'Coupon non valido o già utilizzato.',
  'Coupon non valido o gia utilizzato',
  'Coupon non valido o gia utilizzato.',
  'Coupon già utilizzato',
  'Coupon già utilizzato.',
  'Coupon gia utilizzato',
  'Coupon gia utilizzato.',

  "Coupon non valido o gia' utilizzato",
  "Coupon non valido o gia' utilizzato.",
  "Coupon gia' utilizzato",
  "Coupon gia' utilizzato.",

  'Coupon non valido',
  'Coupon non valido.',
].map(x => x.toLowerCase());

export default function sessionReducer(state: ISession = initialState.session, action: Redux.AnyAction): ISession {
  switch (action.type) {
    case types.API_LOGIN:
      return { ...state,
        authenticated: false,
        token: undefined,
        loading: !action.error,
        error: undefined,
        role: Role.Client,
      };
    case types.API_LOGIN_SUCCESS:
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'login'
      });
      return { ...state,
        authenticated: true,
        token: action.payload.token,
        loading: false,
        role: action.payload.role,
        error: action.payload.status === false ? action.payload.error : undefined,
      };
    case types.API_LOGIN_FAILURE:
      return { ...state,
        authenticated: false,
        token: undefined,
        loading: false,
        error: '',
      };
    case types.SEND_FORM:
    case types.REQUEST_CREDENTIALS:
      return { ...state,
        loadingSignUp: !action.error,
        error: undefined,
      };
    case types.SEND_FORM_SUCCESS:
    case types.REQUEST_CREDENTIALS_SUCCESS:
      return { ...state,
        loadingSignUp: false,
      };
    case types.SEND_FORM_FAILURE:
    case types.REQUEST_CREDENTIALS_FAILURE:
      return { ...state,
        loadingSignUp: false,
        error: action.payload.status === false ? action.payload.error : undefined,
      };
    case types.API_LOGOUT_SUCCESS:
      return initialState.session;
    case types.API_PASW_RECOVERY:
    case types.API_CHANGE_PASW:
      return { ...state,
        loading: !action.error
      };
    case types.API_PASW_RECOVERY_SUCCESS:
    case types.API_CHANGE_PASW_SUCCESS:
      return { ...state,
        loading: false,
        error: action.payload.status === false ? action.payload.error : undefined,
      };
    case types.API_PASW_RECOVERY_FAILURE:
    case types.API_CHANGE_PASW_FAILURE:
      return { ...state,
        loading: false,
        error: '',
      };
    case types.API_GET_USER:
      return { ...state,
        loadingUser: !action.error,
        error: undefined,
      };
    case types.API_GET_USER_SUCCESS:
      return { ...state,
        user: action.payload.user,
        loadingUser: false,
        error: action.payload.status === false ? action.payload.error : undefined,
      };
    case types.API_GET_USER_FAILURE:
      return { ...state,
        user: undefined,
        loadingUser: false,
        error: '',
      };
    case types.SELECT_CLIENT:
      return { ...state,
        agent: {
          ...state.agent!,
          selectedClient: action.client,
        }
      };
    case types.API_GET_CLIENTS:
      return { ...state,
        loadingClients: !action.error,
        error: undefined,
      };
    case types.API_GET_CLIENTS_SUCCESS:
      return { ...state,
        agent: {
          ...state.agent,
          clients: _.unionBy(action.payload.clients, 'id') as any,
          totalCount: action.payload.totalCount,
        },
        loadingClients: false,
        error: action.payload.status === false ? action.payload.error : undefined,
      };
    case types.API_GET_CLIENTS_FAILURE:
      return { ...state,
        loadingClients: false,
        error: '',
      };
    case types.API_GET_CART: {
      return {
        ...state,
        cart: initialState.session.cart,
      };
    }
    case types.API_GET_CART_SUCCESS: {
      const cart = {
        ...state.cart,
        ...action.payload.temporaryCart?.cartJson,
      };
      clearCartSession(cart);
      return {
        ...state,
        cart,
      };
    }
    case types.API_SAVE_CART: {
      return {
        ...state,
        loadingOrders: true,
      };
    }
    case types.API_SAVE_CART_SUCCESS: {
      return {
        ...state,
        loadingOrders: false,
        cart: {
          ...action.meta.cart,
          synced: true
        }
      };
    }
    case types.API_SAVE_CART_FAILURE: {
      return {
        ...state,
        loadingOrders: false,
      };
    }
    case types.API_REQUEST_ORDER_AVAILABILITY:
      return {
        ...state,
        loadingOrders: true,
      };
    case types.API_REQUEST_ORDER_AVAILABILITY_SUCCESS: {
      const i = _.cloneDeep(state.cart.items);
      return { ...state,
        loadingOrders: false,
        cart: {
          ...state.cart,
          items: action.payload.status ? _.values(_.merge(_.keyBy(i, 'code'), _.keyBy(action.payload.order.articles, 'code'))) : i,
          orderChecked: action.payload.status,
          totaleDocumento: action.payload.status ? action.payload.order.totaleDocumento : 0,
          totaleImponibile: action.payload.status ? action.payload.order.totaleImponibile : 0,
          totaleImposta: action.payload.status ? action.payload.order.totaleImposta : 0,
          totaleSconto: (action.payload.status && !wrongEsito.includes(action.payload.order.esitoCoupon?.toLowerCase())) ? action.payload.order.totaleSconto : 0,
          totaleMerce: action.payload.status ? action.payload.order.totaleMerce : 0,
          esitoCoupon: action.payload.status ? action.payload.order.esitoCoupon : undefined,
          note: action.payload.status ? action.payload.note : undefined,
          coupon: action.payload.status ? action.payload.order.coupon : undefined,
          synced: false
        },
      };
    }
    case types.API_REQUEST_ORDER_AVAILABILITY_FAILURE:
      return { ...state,
        loadingOrders: false,
        cart: {
          ...state.cart,
          orderChecked: false,
          synced: false
        }
      };
    case types.API_SEND_ORDER:
      return {
        ...state,
        loadingOrders: true
      };
    case types.API_SEND_ORDER_SUCCESS:
      return { ...state,
        loadingOrders: false,
        cart: {
          ...state.cart,
          items: [],
          orderChecked: false,
        }
      };
    case types.API_SEND_ORDER_FAILURE:
      return { ...state,
        loadingOrders: false
      };
    case types.API_GET_QUOTES_SUCCESS:
      return { ...state,
        hasQuotes: action.payload.wishlists.length > 0,
      };
    default:
      return state;
  }
}
