import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    marginTop: '100px',

    [theme.breakpoints.down('md')]: {
      marginTop: '60px',
    }
  }
});
