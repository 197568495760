import Redux, { Dispatch } from 'redux';
import { INotification } from '../data-types';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SET_LANG = 'SET_LANG';

export function enqueueSnackbar(notification: INotification) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    const key = notification.options && notification.options.key;
    return dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    });
  };
}

export function closeSnackbar(key: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      type: CLOSE_SNACKBAR,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    });
  };
}

export function removeSnackbar(key: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      type: REMOVE_SNACKBAR,
      key,
    });
  };
}

export function setLang(lang: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      type: SET_LANG,
      lang,
    });
  };
}
