import SecureLS from 'secure-ls';
import { RSAA } from 'redux-api-middleware';
import _ from 'lodash';
import Redux, { Dispatch } from 'redux';

import types from '../action-types';
import { getApiEndpoint } from '../utils';
import { ApiEndpoints } from '../../globals/constants';
import { ICart, ICartArticle, ICustomer, IOrderRequest, ISession } from '../data-types';
import initialState from '../initial-state';

const emptyCartCouponOverride = {
  totaleSconto: undefined,
  esitoCoupon: undefined,
  coupon: undefined,
};

const emptyCartOverride: ICart = {
  items: [],
  orderChecked: false,
  totaleImponibile: undefined,
  totaleImposta: undefined,
  totaleDocumento: undefined,
  totaleMerce: undefined,
  note: undefined,
  synced: false,
  ...emptyCartCouponOverride,
};

export const SESSION_NAME = 'airex_session';

export function readSession() {
  let session = { ...initialState.session };
  try {
    const ls = new SecureLS({ encodingType: 'aes' });
    const sessionJson = ls.get(SESSION_NAME);
    session = JSON.parse(sessionJson || '{}');
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
  return session;
}

export function writeSession(session: ISession) {
  const ls = new SecureLS({ encodingType: 'aes' });
  ls.set(SESSION_NAME, JSON.stringify(session));
  return true;
}

export function login(email: string, password: string) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.LOGIN),
        method: 'POST',
        body: formData,
        types: [
          types.API_LOGIN,
          types.API_LOGIN_SUCCESS,
          types.API_LOGIN_FAILURE
        ],
      },
    });
  };
}

export function recoveryPasw(email: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.PASW_RECOVERY),
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
        types: [
          types.API_PASW_RECOVERY,
          types.API_PASW_RECOVERY_SUCCESS,
          types.API_PASW_RECOVERY_FAILURE
        ],
      },
    });
  };
}

export function changePasw(oldPassword: string, password: string, passwordConfirm: string) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.CHANGE_PASW),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        body: JSON.stringify({
          oldPassword,
          password,
          passwordConfirm,
        }),
        types: [
          types.API_CHANGE_PASW,
          types.API_CHANGE_PASW_SUCCESS,
          types.API_CHANGE_PASW_FAILURE
        ],
      },
    });
  };
}

export function getUser() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.USER),
        method: 'GET',
        headers: {
          Authorization: ''
        },
        types: [
          types.API_GET_USER,
          types.API_GET_USER_SUCCESS,
          types.API_GET_USER_FAILURE
        ],
      },
    });
  };
}

export function getClients(page = 0, search = '') {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.CLIENTS),
        method: 'GET',
        query: {
          count: 50,
          skip: page * 50,
          terms: search
        },
        headers: {
          Authorization: ''
        },
        types: [
          types.API_GET_CLIENTS,
          types.API_GET_CLIENTS_SUCCESS,
          types.API_GET_CLIENTS_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function selectClient(client: ICustomer) {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({ type: types.SELECT_CLIENT, client });
  };
}

export function logout() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({ type: types.API_LOGOUT_SUCCESS });
  };
}

export function saveCart(cart: ICart) {
  const formData = new FormData();
  formData.append('temporaryCart', 'true');
  formData.append('cartJson', JSON.stringify(cart));
  const meta = { cart };

  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.WISHLIST_EDIT),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        body: formData,
        types: [
          { type: types.API_SAVE_CART, meta },
          { type: types.API_SAVE_CART_SUCCESS, meta },
          { type: types.API_SAVE_CART_FAILURE, meta }
        ],
      },
    });
  };
}

export function addArticlesToCart(cart: ICart, articles: Partial<ICartArticle>[], prepend = false, note?: string) {
  const cartItems = _.cloneDeep(cart.items);
  articles.forEach((article) => {
    const itemIndex = _.findIndex(cartItems, (item: Partial<ICartArticle>) => item.code === article.code);
    if (itemIndex > -1) {
      cartItems[itemIndex].quantity = (article?.quantity || 0) + cartItems[itemIndex].quantity || 0;
    } else if (prepend) {
      cartItems.unshift(article as ICartArticle);
    } else {
      cartItems.push(article as ICartArticle);
    }
  });

  return saveCart({
    ...cart,
    ...emptyCartOverride,
    note: note ?? cart.note,
    coupon: cart.coupon,
    items: cartItems,
  });
}

export function updateCartArticleQuantity(cart: ICart, article: ICartArticle, quantity: number) {
  const cartItems = _.cloneDeep(cart.items);
  const itemIndex = _.findIndex(cartItems, (item: ICartArticle) => item.code === article.code);
  if (itemIndex > -1) {
    cartItems[itemIndex].quantity = quantity;
  }
  return saveCart({
    ...cart,
    ...emptyCartOverride,
    note: cart.note,
    coupon: cart.coupon,
    items: cartItems,
  });
}

export function removeArticleFromCart(cart: ICart, article: ICartArticle) {
  const items = cart.items.filter((item: Partial<ICartArticle>) => item.code !== article.code);
  return saveCart({
    ...cart,
    ...emptyCartOverride,
    items,
    note: cart.note,
    coupon: items.length > 0 ? cart.coupon : undefined,
    orderChecked: cart.orderChecked && !article.available,
  });
}

export function updateCartArticles(cart: ICart, articles: ICartArticle[]) {
  return saveCart({
    ...cart,
    items: articles,
    orderChecked: true,
  });
}

export function orderUpdated(cart: ICart) {
  return saveCart({
    ...cart,
    orderChecked: false,
  });
}

export function clearCart(cart: ICart) {
  return saveCart({
    ...cart,
    ...emptyCartOverride,
  });
}

export function clearCartSession(cart: ICart) {
  return saveCart({
    ...cart,
    ...emptyCartOverride,
    note: cart.note,
    items: cart.items,
  });
}

export function requestSavedCart() {
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.TEMPORARY_CART),
        method: 'GET',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        types: [
          types.API_GET_CART,
          types.API_GET_CART_SUCCESS,
          types.API_GET_CART_FAILURE
        ],
      },
    });
  };
}

export function requestOrderAvailability(cart: ICart, body: Partial<IOrderRequest>) {
  const formData = new FormData();
  Object.keys(body).forEach((k) => {
    if (k === 'articles') {
      formData.append(k, JSON.stringify((body as any)[k]));
    } else {
      formData.append(k, (body as any)[k]);
    }
  });
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.CREATE_ORDER),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        body: formData,
        types: [
          types.API_REQUEST_ORDER_AVAILABILITY,
          types.API_REQUEST_ORDER_AVAILABILITY_SUCCESS,
          types.API_REQUEST_ORDER_AVAILABILITY_FAILURE
        ],
      },
    });
  };
}

export function sendOrder(body: Partial<IOrderRequest>) {
  const formData = new FormData();
  Object.keys(body).forEach((k) => {
    if (k === 'articles') {
      formData.append(k, JSON.stringify((body as any)[k]));
    } else {
      formData.append(k, (body as any)[k]);
    }
  });
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.CREATE_ORDER),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        body: formData,
        types: [
          types.API_SEND_ORDER,
          types.API_SEND_ORDER_SUCCESS,
          types.API_SEND_ORDER_FAILURE
        ],
      },
    });
  };
}

export function setFavouriteProductItem({
  articleId,
  favourite,
}: {articleId: number; favourite: boolean}) {
  const formData = new FormData();
  formData.append('articleId', String(articleId));
  formData.append('status', String(favourite));
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.SET_FAVOURITE_PRODUCT_ITEM),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        body: formData,
        types: [
          types.API_SET_FAVOURITE_PRODUCT_ITEM,
          types.API_SET_FAVOURITE_PRODUCT_ITEM_SUCCESS,
          types.API_SET_FAVOURITE_PRODUCT_ITEM_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}

export function checkCheckout(articles: Array<{
  articleCode: string;
  quantity: number;
}>) {
  const formData = new FormData();
  formData.append('articles', JSON.stringify(articles));
  return (dispatch: Dispatch<Redux.AnyAction, Redux.Store>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.FAST_CHECKOUT_CHECK),
        method: 'POST',
        headers: {
          Authorization: ''
        },
        options: { timeout: '60000' },
        body: formData,
        types: [
          types.API_FAST_CHECKOUT_CHECK,
          types.API_FAST_CHECKOUT_CHECK_SUCCESS,
          types.API_FAST_CHECKOUT_CHECK_FAILURE
        ],
      },
    } as Redux.AnyAction);
  };
}
