import { RSAA } from 'redux-api-middleware';
import { MiddlewareAPI } from 'redux';
import { IState } from '../state/data-types';

const authMiddleware = (store: MiddlewareAPI<any, IState>) => (next: any) => (action: any) => {
  if (action[RSAA] !== undefined && action[RSAA].headers.Authorization !== undefined) {
    action[RSAA].headers.Authorization = `Bearer ${store.getState().session.token}`; // eslint-disable-line no-param-reassign
    action[RSAA].credentials = 'include'; // eslint-disable-line no-param-reassign
  }
  return next(action);
};

export default authMiddleware;
