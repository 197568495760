import React, { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import {
  Card, CardActionArea, CardContent, CardMedia, CircularProgress, Container, Fade, Hidden,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { LocalOfferRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { AppContext, IAppContext } from '../../containers/App';
import { IDocumentCategory, IDocumentItem } from '../../state/data-types';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '0rem'
  },
  grid: {
    margin: '3rem 0 4rem'
  },
  title: {
    marginTop: '5rem'
  },
  subtitle: {
    textAlign: 'center',
    margin: '1rem auto',
    padding: '0 3rem',
    maxWidth: '880px',
  }
}));

export interface IDocumentsProps {
  documents: {
    loading: boolean;
    error: string | null;
    categories: IDocumentCategory[];
    items: IDocumentItem[];
  };
  visibility: 'private' | 'public';
  authenticated: boolean;
}

export default function Documents(props: IDocumentsProps, context: IAppContext) {
  const { documents, visibility, authenticated } = props;
  const { loading, categories } = documents;
  const { lang } = context;
  const classes = useStyles();

  useEffect(() => {
    context.actions.api.getDocuments(visibility);
  }, [visibility, authenticated]);

  return (
    <section className="static-page" style={{ backgroundColor: 'white' }}>
      <Helmet>
        <title>{lang('user.Documents.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.Documents.title')}
        </h1>
      </div>
      <Container className={classes.container} maxWidth="xl">
        <div className="content">
          <Hidden xsUp={!loading}>
            <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CircularProgress size={30} />
            </div>
          </Hidden>
          <Hidden xsUp={loading}>
            <Grid container>
              {
                categories && categories.length > 0 ? categories.map((element, index) => {
                  const isHead = index % 5 === 0;
                  const isTail = index % 5 === 4;
                  return (
                    <Grid container item xs={12} md={4} lg={isHead || isTail ? 3 : 2} key={`${element.slug}_${index}`}>
                      <Grid item lg={4} implementation="css" mdDown lgUp={!isHead} component={Hidden} />
                      <Grid item xs={12} lg={isHead || isTail ? 8 : 12}>
                        <DocumentItem
                          url={visibility === 'private' ? `/documents/${element.slug}` : `/public-documents/${element.slug}`}
                          slug={element.slug}
                          icon={element.icon}
                          label={element.label}
                          index={index}
                        />
                      </Grid>
                    </Grid>
                  );
                }) : (
                  <Typography variant="body1" style={{ margin: '0 auto' }}>
                    {lang('user.Documents.noDocumentAvailable')}
                  </Typography>
                )
              }
            </Grid>
          </Hidden>
        </div>
      </Container>
    </section>
  );
}

Documents.contextTypes = { ...AppContext };

const itemStyles = makeStyles(() => ({
  cardLink: {
    textDecoration: 'none !important',
    width: '100%',
  },
  item: {
    border: '1px solid white',
    borderBottom: '2px solid white',
    borderRadius: '0px',
    margin: '.8rem',
    display: 'flex',

    '&:hover': {
      border: '1px solid #E3E3E3',
      borderBottom: '2px solid rgba(0,0,0,0.64)',
    },

    '& button:hover > span': {
      display: 'none'
    }
  },
  media: {
    height: '100%',
    objectFit: 'contain',
  },
  placeholder: {
    width: '100%',
    height: '150px',
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    padding: '0 0.2rem .5rem',
  },
  extraLabel: {
    textAlign: 'left',
    fontSize: '1.6rem',
    lineHeight: '1.8rem',
    padding: '.5rem 0.2rem 0.2rem',
    fontWeight: 'bold'
  }
}));

export interface IDocumentItemProps {
  slug: string | number;
  icon?: string;
  label: string;
  index: number;
  url?: string;
}

function NavLink2(props: {children: ReactNode; to: string; className?: string}) {
  return (
    <>{
    props.to.match(/^(.+?:\/\/)/g)
      ? (
        <a target="_blank" rel="noreferrer noopener" href={props.to} className={props.className}>
          {props.children}
        </a>
      )
      : <NavLink to={props.to} className={props.className}>{props.children}</NavLink>
  }
    </>
  );
}

export function DocumentItem(props: IDocumentItemProps) {
  const { url, icon, label, index } = props;
  const classes = itemStyles();

  return (
    <Fade in timeout={1000 + (index * 200)} enter>
      <NavLink2 to={url || '#'} className={classes.cardLink}>
        <Card className={classes.item}>
          <CardActionArea>
            { icon ? (
              <div className={classes.placeholder}><CardMedia
                className={classes.media}
                component="img"
                image={icon}
                title={label}
              />
              </div>
            ) : (
              <div className={classes.placeholder}>
                <LocalOfferRounded style={{ fontSize: '4rem', opacity: '0.5' }} />
              </div>
            )}
            <CardContent>
              <Typography variant="h6" component="h2" className={classes.label}>
                {label}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </NavLink2>
    </Fade>
  );
}

DocumentItem.contextTypes = { ...AppContext };
