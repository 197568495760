import { useCallback, useEffect, useRef, useState } from 'react';

export type UseDebouncedMemoState = 'idle' | 'debouncing';

export function useDebouncedMemo<TOut>(
  fn: () => TOut,
  opts?: { debounceInterval?: number }
): { value: TOut; forceUpdate: () => void; state: UseDebouncedMemoState } {
  const debounceInterval = opts?.debounceInterval ?? 100;
  const [value, setValue] = useState<TOut>(fn);
  const [state, setState] = useState<UseDebouncedMemoState>('idle');
  const firstRef = useRef(true);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      return;
    }
    timeoutIdRef.current = setTimeout(() => {
      timeoutIdRef.current = null;
      setValue(fn());
      setState('idle');
    }, (debounceInterval));
    setState('debouncing');
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [debounceInterval, fn]);

  const forceUpdate = () => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    setValue(() => fn());
    setState('idle');
  };

  return { value, forceUpdate, state };
}

export type UseDebouncedSearchResult = {
  query: string;
  setQuery(newValue: string): void;
  debouncedNormalizedQuery: string;
};

export function useDebouncedSearch(
  initialQuery?: string,
  debounceInterval = 250
): UseDebouncedSearchResult {
  const [query, setQuery] = useState(initialQuery ?? '');
  const { value: debouncedNormalizedQuery } = useDebouncedMemo(
    useCallback(() => query.trim().toLowerCase(), [query]),
    {
      debounceInterval,
    }
  );
  return { query, setQuery, debouncedNormalizedQuery };
}
