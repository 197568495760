import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export type AsyncButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClickAsync(
    e: Parameters<NonNullable<ButtonProps['onClick']>>[0]
  ): Promise<void> | void;
  loadingChildren?: ReactNode;
  children?: ReactNode;
};

export function AsyncButton({
  onClickAsync,
  children,
  loadingChildren: propsLoadingChildren,
  ...props
}: AsyncButtonProps): JSX.Element {
  const loadingChildren = useMemo(() => propsLoadingChildren ?? <CircularProgress size="1.2rem" />, [propsLoadingChildren]);
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback<
  NonNullable<ButtonProps['onClick']>
  >(async (e) => {
    setLoading(true);
    try {
      await onClickAsync?.(e);
    } catch (err) {
      console.warn('[AsyncButton] onClickAsync failed', err);
    } finally {
      setLoading(false);
    }
  }, [onClickAsync]);
  return (
    <Button onClick={handleClick} disabled={loading} {...props}>
      {loading ? loadingChildren : children}
    </Button>
  );
}
