export function priceToString(
  currency: string,
  value: number | string | undefined | null,
  zeroFallback?: string
): string {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return '--';
  }
  const number = Number(value);
  if (typeof zeroFallback === 'string' && number === 0) {
    return zeroFallback;
  }
  return `${number.toLocaleString(undefined, {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}${currency}`;
}
