import React from 'react';
import { Theme, Grid, Typography, Container, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { ChevronRightRounded } from '@material-ui/icons';
import { AppContext, IAppContext } from '../../containers/App';
import { Catalog } from '../../globals/constants';
import { getTheme } from '../../containers/home/HomePage';
import { catalogDisplayName } from '../catalogs/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: '2rem 0',
  },
  image: {
    width: '100%'
  },
  button: {
    border: `3px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.3rem 0rem 0rem',
    marginTop: '2rem',
    cursor: 'pointer'
  },
  logo: {
    height: '1.5rem',
    marginLeft: '.5rem'
  }
}));

export interface ICatalogBannerProps {
  catalog: Catalog;
}

export default function CatalogBanner(props: ICatalogBannerProps, context: IAppContext) {
  const classes = useStyles();
  const { lang, dispatch, push } = context;
  const { catalog } = props;

  return (
    <ThemeProvider theme={getTheme(catalog)}>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container>
          <Grid item xs={12} lg={9} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2rem' }}>
            <Link to={`/catalogs/${catalog}`} style={{ width: '90%' }}>
              <img className={classes.image} src={require(`../../images/${catalog}-banner.jpg`)} alt={catalogDisplayName[catalog](lang)} />
            </Link>
          </Grid>
          <Grid item xs={12} lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '1rem' }}>
            <Typography color="primary" variant="h5" style={{ marginTop: '3rem', marginBottom: '1rem', textAlign: 'left', whiteSpace: 'pre', cursor: 'pointer' }} onClick={() => dispatch(push(`/catalogs/${catalog}`))}>
              {`${lang('home.CatalogBanner.catalog')}\n${catalogDisplayName[catalog](lang)}`}
            </Typography>
            <Typography style={{ fontSize: '.9rem', opacity: 0.8, letterSpacing: '-.2px' }}>
              {lang('home.CatalogBanner.description')}
            </Typography>
            <Box className={classes.button} style={{ borderColor: getTheme(catalog).palette.primary.main }} onClick={() => dispatch(push('/contacts'))}>
              <Box display="flex" flexDirection="column">
                <img className={classes.logo} src={require(`../../images/logo-${catalog}.svg`)} alt={catalogDisplayName[catalog](lang)} />
                <Typography variant="overline" style={{ paddingLeft: '.5rem', textTransform: 'none', opacity: 0.8, marginTop: '.1rem', marginBottom: 0, letterSpacing: '-.2px' }}>
                  {lang('home.CatalogBanner.goToForm')}
                </Typography>
              </Box>
              <ChevronRightRounded color="primary" style={{ marginBottom: '.3rem', fontSize: '2.5rem' }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

CatalogBanner.contextTypes = { ...AppContext };
