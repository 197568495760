import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Hidden, CircularProgress, Box, Theme, Button, Typography, Divider, TablePagination, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { AppContext, IAppContext } from '../../containers/App';
import { ICart, ICartArticle, ICustomer, IDeliveryPoint, IQuote, ISession } from '../../state/data-types';
import { AddOrderToCartDialog } from './AddOrderToCartDialog';
import { priceToString } from '../../globals/localization';
import { partitionOutAndInCatalog } from '../../utils/orders/partition';
import { downloadPdf } from '../../utils/order-pdf';
import { sleep } from '../../utils/time';
import { throwIfErrorResponse } from '../../utils/api';
import { debugValue } from '../../utils/debug';
import { AsyncButton } from '../AsyncButton';
import { Controller } from '../Controller';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '2rem'
  },
  productHeader: {
    width: '100%',
    display: 'flex',
    padding: '1.5rem 2rem 1rem',
    color: '#666',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    backgroundColor: '#f9f9f9',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
    }
  },
  tableHeader: {
    padding: '0rem 2rem',
    position: 'relative',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      }
    }
  },
  productFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 2rem',
  },
  productContainer: {
    borderRadius: '3px',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

export interface IQuotesProps {
  cart: ICart;
  session: ISession;
  loading: boolean;
  user?: ICustomer;
  pageCount: number;
  showPrices: boolean;
  quotes: IQuote[];
}

export default function Quotes(props: IQuotesProps, context: IAppContext) {
  const { quotes, session, loading, pageCount, cart, showPrices } = props;
  const { lang } = context;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    context.actions.api.getSavedQuotes(page * rowsPerPage, rowsPerPage);
  }, [context.actions.api, page, rowsPerPage]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.Quotes.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.Quotes.title')}
        </h1>
      </div>
      <QuotesSection refetch={() => context.actions.api.getSavedQuotes(page * rowsPerPage, rowsPerPage)} showPrices={showPrices} cart={cart} user={props.user} loading={loading} quotes={quotes} session={session} />
      <Hidden xsUp={loading || quotes.length === 0}>
        <Container style={{ marginTop: '-5rem', marginBottom: '3rem' }}>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TablePagination
                component="div"
                count={pageCount * rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    </section>
  );
}

Quotes.contextTypes = { ...AppContext };

export interface IQuotesSectionProps {
  cart: ICart;
  session: ISession;
  loading: boolean;
  limit?: number;
  user?: ICustomer;
  showPrices: boolean;
  quotes: IQuote[];
  refetch(): Promise<void>;
}

export function QuotesSection(props: IQuotesSectionProps, context: IAppContext) {
  const { loading, quotes, limit, showPrices, session } = props;
  const { lang } = context;

  return (
    <Container className="content">
      <Hidden xsUp={!loading}>
        <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress size={30} />
        </div>
      </Hidden>
      <Hidden xsUp={loading || quotes.length === 0}>
        <Grid container>
          {
            quotes.slice(0, limit || quotes.length).map((quote, index) => <Quote refetch={() => props.refetch()} key={`${quote.id}_${index}`} quote={quote} session={session} showPrices={showPrices} />)
          }
        </Grid>
      </Hidden>
      <Hidden xsUp={loading || quotes.length > 0}>
        <Typography variant="h5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {lang('user.Quotes.noOrder')}
        </Typography>
        <Typography variant="body1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {lang('user.Quotes.hint')}
        </Typography>
      </Hidden>
    </Container>
  );
}

QuotesSection.contextTypes = { ...AppContext };

const itemStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: '.8rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem'
    }
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& span': {
      fontWeight: 'bold',
      fontSize: '.8rem',
      color: '#636363',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
      marginTop: '1rem',
    },
    '& a': {
      textDecoration: 'none !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '.9rem !important',
      '&:hover': {
        textDecoration: 'underline !important',
      }
    },
    '&.inner': {
      color: '#636363',
      '& span': {
        color: '#636363',
      },
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
    '&.end': {
      alignItems: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      }
    }
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '.5rem',
  },
}));

export interface IQuoteRowProps {
  item: ICartArticle;
  index: number;
  showPrices: boolean;
}

function QuoteRow(props: IQuoteRowProps, context: IAppContext) {
  const { item, index, showPrices } = props;
  const { preview, code, description, quantity, totalPrice, price } = item;
  const classes = itemStyles();
  const { lang } = context;

  return (
    <Grid container className={`${classes.container} ${index % 2 !== 0 ? classes.even : ''}`}>
      <Grid item xs={12} md={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        { preview ? <img src={preview} alt={description} style={{ maxWidth: '2.5rem', maxHeight: '2.5rem' }} /> : null }
      </Grid>
      <Grid item xs={12} md={2} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.Quotes.code')}</span>
        </Hidden>
        <NavLink to={item.url || '#'} style={{ fontWeight: 600 }}>
          {code}
        </NavLink>
      </Grid>
      <Grid item xs={12} md={showPrices ? 5 : 7} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.Quotes.product')}</span>
        </Hidden>
        <NavLink to={item.url || '#'}>
          {description}
        </NavLink>
      </Grid>
      <Grid item xs={12} md={1} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.Quotes.quantity')}</span>
        </Hidden>
        {quantity}
      </Grid>
      {showPrices
      && (
      <Grid item xs={12} md={2} className={`${classes.label} end`} style={{ fontWeight: 600 }}>
        <Hidden mdUp>
          <span>{lang('user.Quotes.price')}</span>
        </Hidden>
        {priceToString('€', (totalPrice ?? (price ? quantity * price : undefined)))}
      </Grid>
      )}
    </Grid>
  );
}

QuoteRow.contextTypes = { ...AppContext };

interface IQuoteProps {
  session: ISession;
  showPrices: boolean;
  quote: IQuote;
  refetch(): Promise<void>;
}

function Quote(props: IQuoteProps, context: IAppContext) {
  const { quote, session, showPrices } = props;
  const { lang } = context;
  const { articlesInCatalog, articlesOutOfCatalog } = partitionOutAndInCatalog(quote.cartJson.items);

  const [viewOrderDialog, setViewOrderDialog] = useState(false);

  const [visibility, setVisibility] = useState(quote.showClient);
  const [togglingVisibility, setTogglingVisibility] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleToggleVisibility = useCallback(async () => {
    setTogglingVisibility(true);
    try {
      throwIfErrorResponse(await context.actions.api.editQuote(quote.id, { ...quote, showClient: !visibility }));
      setVisibility(!visibility);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(lang('global.anErrorOccurred'), {
        variant: 'error',
      });
    } finally {
      setTogglingVisibility(false);
    }
  }, [context.actions.api, enqueueSnackbar, lang, quote, visibility]);

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Box boxShadow={1} className={classes.productContainer} style={{ width: '100%' }}>
        <Grid container className={classes.productHeader}>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              DATA:
            </span>
            <span>
              {moment(quote.createdAt).format('D MMMM YYYY')}
            </span>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ textTransform: 'uppercase' }}>
              {lang('user.Quotes.quote')}
            </span>
            <span>
              &nbsp;
            </span>
          </Grid>
          <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: '1rem' }}>
            <Controller value={false}>
              {({ value: confirmed, onChange: setConfirmed }) => (
                <>
                  {!confirmed
                    ? (
                      <Button
                        variant="outlined"
                        disableElevation
                        color="primary"
                        size="small"
                        style={{ fontWeight: 600, padding: '4px 12px' }}
                        onClick={() => { setConfirmed(true); }}
                      >
                        {lang('user.Quotes.delete')}
                      </Button>
                    )
                    : (
                      <AsyncButton
                        variant="contained"
                        disableElevation
                        color="primary"
                        size="small"
                        style={{ fontWeight: 600, padding: '4px 12px' }}
                        onClickAsync={async () => {
                          try {
                            throwIfErrorResponse(await context.actions.api.deleteQuote(quote.id));
                            await props.refetch();
                            setConfirmed(false);
                            enqueueSnackbar(lang('user.Quotes.successfullyDeleted'), {
                              variant: 'success',
                            });
                          } catch (err) {
                            console.warn(err);
                            enqueueSnackbar(lang('global.anErrorOccurred'), {
                              variant: 'error',
                            });
                          }
                        }}
                      >
                        {lang('user.Quotes.confirmDeletion')}
                      </AsyncButton>
                    )}
                </>
              )}
            </Controller>
            <Button
              variant="outlined"
              disableElevation
              color="primary"
              size="small"
              style={{ fontWeight: 600, padding: '4px 12px' }}
              onClick={() => { setViewOrderDialog(true); }}
            >
              {lang('user.Quotes.order')}
            </Button>
            <Button
              variant="outlined"
              disableElevation
              color="primary"
              size="small"
              style={{ fontWeight: 600, padding: '4px 12px' }}
              onClick={() => {
                let selectedOffice: IDeliveryPoint | undefined;
                if (session.user && session.user.offices.length > 0) {
                  selectedOffice = _.find(session.user.offices, (office: IDeliveryPoint) => office.isMainAddress);
                }
                downloadPdf({
                  lang,
                  selectedOffice,
                  agent: session.agent,
                  showPrices,
                  total: quote.cartJson.totaleDocumento,
                  totaleImponibile: quote.cartJson.totaleImponibile,
                  totaleImposta: quote.cartJson.totaleImposta,
                  type: 'quote',
                  note: quote.cartJson.note,
                  orderNumber: moment(quote.createdAt).format('D MMMM YYYY'),
                  items: debugValue(quote.cartJson).items,
                });
              }}
            >
              {lang('user.Quotes.PDF')}
            </Button>
          </Grid>
          {session.agent && (
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', paddingTop: '1rem', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ position: 'relative', zIndex: 0 }}>
              <div style={{ position: 'relative', zIndex: 0 }}>
                <IconButton onClick={handleToggleVisibility} disabled={togglingVisibility}>
                  {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
              {togglingVisibility
              && (
              <div style={{ position: 'absolute', zIndex: 1, inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size="1.2rem" />
              </div>
              )}
            </div>
            <div>
              {lang('user.Quotes.visibleToClient')}
            </div>
          </Grid>
          )}
        </Grid>
        <Divider style={{ width: '100%', marginBottom: '1.5rem', opacity: 0.8 }} />
        <Hidden smDown>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={2} className={classes.label} />
            <Grid item xs={2} className={classes.label}>
              {lang('user.Quotes.code')}
            </Grid>
            <Grid item xs={showPrices ? 5 : 7} className={classes.label}>
              {lang('user.Quotes.product')}
            </Grid>
            <Grid item xs={1} className={classes.label}>
              {lang('user.Quotes.quantity')}
            </Grid>
            {showPrices
            && (
            <Grid item xs={2} className={`${classes.label} end`}>
              {lang('user.Quotes.price')}
            </Grid>
            )}
          </Grid>
        </Hidden>
        <div style={{ width: '100%' }}>
          {
            articlesInCatalog.map((item, i: number) => (
              <div key={i}>
                <QuoteRow showPrices={showPrices} item={item} index={i} />
              </div>
            ))
          }
          {articlesOutOfCatalog.length > 0 && (
          <>
            <div style={{ padding: '1rem 2rem 0.5rem', fontWeight: 'bolder' }}>
              {lang('user.BackOrders.outOfCatalogItems')}
            </div>
            {
            articlesOutOfCatalog.map((item, i) => (
              <div key={i}>
                <QuoteRow showPrices={showPrices} item={item} index={i} />
              </div>
            ))
          }
          </>
          )}
        </div>
        {showPrices
        && (
        <>
          <Divider />
          <div className={classes.productFooter}>
            <span style={{ fontWeight: 600, textTransform: 'uppercase', color: '#666' }}>
              {lang('user.Quotes.total_var', { total: priceToString('€', quote.cartJson.totaleDocumento) })}
            </span>
          </div>
        </>
        )}
      </Box>
      { viewOrderDialog
        && (
        <AddOrderToCartDialog
          title={lang('user.Quotes.addToCart')}
          showPrices={showPrices}
          articles={quote.cartJson.items}
          onClose={() => { setViewOrderDialog(false); }}
          onConfirm={(articles) => {
            context.actions.session.addArticlesToCart(session.cart, articles, false, quote.cartJson.note);
            context.dispatch(context.push('/checkout'));
          }}
        />
        ) }
    </Grid>
  );
}
Quote.contextTypes = { ...AppContext };
