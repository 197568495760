import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Redux, { bindActionCreators, Dispatch } from 'redux';
import Polyglot from 'node-polyglot';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import isNil from 'lodash.isnil';
import moment from 'moment';
import { withRouter } from 'react-router';
import { setDefaultLocale } from 'react-datepicker';
import { ThemeProvider } from '@material-ui/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import theme from '../styles/theme.style';
import actionCreators, { IActions } from '../state/actions';
import { IApp, IState } from '../state/data-types';
import Notifier from '../components/common/Notifier';

function getLangData(lang: string) {
  return require(`../i18n/${lang}.i18n.json`);
}

export interface IAppContext {
  dispatch: Dispatch<Redux.AnyAction, Redux.Store>;
  push: typeof push;
  replace: typeof replace;
  lang: (phrase: string, options?: number | Polyglot.InterpolationOptions) => string;
  actions: IActions<typeof actionCreators>;
}

export const AppContext = {
  dispatch: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  lang: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

export const BaseAppContext = React.createContext<IAppContext>({
  dispatch: () => new Promise<any>(() => undefined),
  push,
  replace,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lang: (phrase: string, _options?: number | Polyglot.InterpolationOptions) => phrase,
  actions: ({} as IAppContext['actions']),
});

interface IProps {
  dispatch: any;
  lang: any;
  // children: ElementType<any>;
  children: React.ReactElement<any>;
  push: any;
  replace: any;
  router: any;
  actions: any;
  app: IApp;
}

interface IAppState {}

class App extends Component<IProps, IAppState> {
  public static defaultProps = {};

  // assigned in setLang, called in the constructor.
  public polyglot!: Polyglot;

  public static childContextTypes = {
    dispatch: PropTypes.func,
    lang: PropTypes.func,
    push: PropTypes.func,
    router: PropTypes.object,
    replace: PropTypes.func,
    actions: PropTypes.object
  };

  constructor(props: IProps) {
    super(props);

    this.setLang(this.props.app.lang);
  }

  setLang(lang: string) {
    setDefaultLocale(lang);
    moment.locale(lang);

    this.polyglot = new Polyglot({
      phrases: getLangData(lang),
      locale: lang,
    });

    this.props.actions.app.setLang(lang);
  }

  public getChildContext() {
    const pol = this.polyglot;
    return {
      dispatch: this.props.dispatch,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      lang: (phrase: string, options?: number | Polyglot.InterpolationOptions) => pol.t(phrase, options),
      push: this.props.push,
      router: this.props.router,
      replace: this.props.replace,
      actions: this.props.actions
    };
  }

  public componentDidMount() {
    setTimeout(() => {
      const loading = document.getElementById('loading');
      const app = document.getElementById('app');
      if (!isNil(loading)) loading.style.display = 'none';
      if (!isNil(app)) app.style.display = '';
    }, 1000);
  }

  componentDidUpdate(prevProps: IProps) {
    const { router } = this.props;
    if (router.location.pathname !== prevProps.router.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (this.props.app.lang !== prevProps.app.lang) {
      this.setLang(this.props.app.lang);
    }
  }

  public render() {
    const { children } = this.props;
    return (
      <BaseAppContext.Provider value={this.getChildContext()}>
        <div className="app-container" key={this.props.app.lang}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            {React.cloneElement(children)}
          </ThemeProvider>
          <Notifier />
        </div>
      </BaseAppContext.Provider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    router: state.router,
    app: state.app,
  };
}

function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.AnyAction, any>) {
  const actions: { [key: string]: any } = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in actionCreators) {
    // eslint-disable-next-line no-prototype-builtins
    if (actionCreators.hasOwnProperty(key)) {
      const ac = (actionCreators[(key as keyof typeof actionCreators)] as any) as Redux.ActionCreatorsMapObject<any>;
      actions[key] = bindActionCreators(ac, dispatch);
    }
  }
  return { dispatch, push, replace, actions };
}

const conn = connect(mapStateToProps, mapDispatchToProps);
const c = conn(App) as React.ComponentClass<any, any>;
export default withRouter(c);
