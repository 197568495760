import keyMirror from 'keymirror';

export default keyMirror({
  GET_BRANDS: null,
  GET_BRANDS_SUCCESS: null,
  GET_BRANDS_FAILURE: null,

  GET_CATALOGS: null,
  GET_CATALOGS_SUCCESS: null,
  GET_CATALOGS_FAILURE: null,

  GET_CATALOGS_ITEMS: null,
  GET_CATALOGS_ITEMS_SUCCESS: null,
  GET_CATALOGS_ITEMS_FAILURE: null,

  SEARCH_PRODUCTS: null,
  SEARCH_PRODUCTS_SUCCESS: null,
  SEARCH_PRODUCTS_FAILURE: null,

  GET_NEWS: null,
  GET_NEWS_SUCCESS: null,
  GET_NEWS_FAILURE: null,

  GET_FEATURED: null,
  GET_FEATURED_SUCCESS: null,
  GET_FEATURED_FAILURE: null,

  GET_RECENTLY_SEEN: null,
  GET_RECENTLY_SEEN_SUCCESS: null,
  GET_RECENTLY_SEEN_FAILURE: null,

  GET_PARTNERS: null,
  GET_PARTNERS_SUCCESS: null,
  GET_PARTNERS_FAILURE: null,

  GET_BANNERS: null,
  GET_BANNERS_SUCCESS: null,
  GET_BANNERS_FAILURE: null,

  GET_REVIEWS: null,
  GET_REVIEWS_SUCCESS: null,
  GET_REVIEWS_FAILURE: null,

  SEND_FORM: null,
  SEND_FORM_SUCCESS: null,
  SEND_FORM_FAILURE: null,

  REQUEST_CREDENTIALS: null,
  REQUEST_CREDENTIALS_SUCCESS: null,
  REQUEST_CREDENTIALS_FAILURE: null,

  API_LOGIN: null,
  API_LOGIN_SUCCESS: null,
  API_LOGIN_FAILURE: null,

  API_PASW_RECOVERY: null,
  API_PASW_RECOVERY_SUCCESS: null,
  API_PASW_RECOVERY_FAILURE: null,

  API_CHANGE_PASW: null,
  API_CHANGE_PASW_SUCCESS: null,
  API_CHANGE_PASW_FAILURE: null,

  API_META_INFO: null,
  API_META_INFO_SUCCESS: null,
  API_META_INFO_FAILURE: null,

  API_GET_USER: null,
  API_GET_USER_SUCCESS: null,
  API_GET_USER_FAILURE: null,

  SELECT_CLIENT: null,

  API_GET_CLIENTS: null,
  API_GET_CLIENTS_SUCCESS: null,
  API_GET_CLIENTS_FAILURE: null,

  GET_ARTICLE_PRICE: null,
  GET_ARTICLE_PRICE_SUCCESS: null,
  GET_ARTICLE_PRICE_FAILURE: null,

  API_GET_ORDERS: null,
  API_GET_ORDERS_SUCCESS: null,
  API_GET_ORDERS_FAILURE: null,

  API_GET_FAVOURITE_PRODUCTS: null,
  API_GET_FAVOURITE_PRODUCTS_SUCCESS: null,
  API_GET_FAVOURITE_PRODUCTS_FAILURE: null,

  API_SET_FAVOURITE_PRODUCT_ITEM: null,
  API_SET_FAVOURITE_PRODUCT_ITEM_SUCCESS: null,
  API_SET_FAVOURITE_PRODUCT_ITEM_FAILURE: null,

  API_GET_BACK_ORDERS: null,
  API_GET_BACK_ORDERS_SUCCESS: null,
  API_GET_BACK_ORDERS_FAILURE: null,

  API_REFRESH_ORDERS: null,
  API_REFRESH_ORDERS_SUCCESS: null,
  API_REFRESH_ORDERS_FAILURE: null,

  GET_DOCUMENT_CATEGORIES: null,
  GET_DOCUMENT_CATEGORIES_SUCCESS: null,
  GET_DOCUMENT_CATEGORIES_FAILURE: null,

  GET_DOCUMENT_CATEGORY_FILES: null,
  GET_DOCUMENT_CATEGORY_FILES_SUCCESS: null,
  GET_DOCUMENT_CATEGORY_FILES_FAILURE: null,

  API_REQUEST_ORDER_AVAILABILITY: null,
  API_REQUEST_ORDER_AVAILABILITY_SUCCESS: null,
  API_REQUEST_ORDER_AVAILABILITY_FAILURE: null,

  API_SEND_ORDER: null,
  API_SEND_ORDER_SUCCESS: null,
  API_SEND_ORDER_FAILURE: null,

  API_GET_CART: null,
  API_GET_CART_SUCCESS: null,
  API_GET_CART_FAILURE: null,

  API_SAVE_CART: null,
  API_SAVE_CART_SUCCESS: null,
  API_SAVE_CART_FAILURE: null,

  API_SAVE_QUOTE: null,
  API_SAVE_QUOTE_SUCCESS: null,
  API_SAVE_QUOTE_FAILURE: null,

  API_DELETE_QUOTE: null,
  API_DELETE_QUOTE_SUCCESS: null,
  API_DELETE_QUOTE_FAILURE: null,

  API_LOGOUT_SUCCESS: null,

  API_FAST_CHECKOUT_CHECK: null,
  API_FAST_CHECKOUT_CHECK_SUCCESS: null,
  API_FAST_CHECKOUT_CHECK_FAILURE: null,

  API_GET_QUOTES: null,
  API_GET_QUOTES_SUCCESS: null,
  API_GET_QUOTES_FAILURE: null,
});
