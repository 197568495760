import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import { Typography, Container, Card, CardActionArea, CardMedia, CardContent, Box, Hidden, CircularProgress, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LocalOfferRounded } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { IAppContext, AppContext } from '../../containers/App';
import { IProductSection } from '../../state/data-types';
import { Catalog } from '../../globals/constants';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { cutOffAtLine } from '../../state/utils';

export interface ISearchProps {
  data: Array<IProductSection>;
  loading: boolean;
  search: string;
  brand?: string;
  catalog: Catalog;
}

const useStyles = makeStyles(() => ({
  emptyListMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '1.1rem',
  },
}));

export default function Search(props: ISearchProps, context: IAppContext) {
  const { data, loading, brand, search, catalog } = props;
  const { lang, dispatch, push } = context;
  const classes = useStyles();

  useEffect(() => {
    if (search !== undefined) {
      context.actions.api.searchProducts(catalog, brand, search)
        .then(({ payload }) => {
          if (payload.products && payload.products.length === 1) {
            const item = payload.products[0];
            dispatch(push(`/catalogs/${item.catalog}/${item.family}/${item.category}/${item.subcategory}/${item.slug}?search=${encodeURIComponent(search)}`));
          }
        });
    }
  }, [search]);

  return (
    <section className="static-page">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero" style={{ backgroundColor: getPrimaryColor(catalog).main }}>
        <h1>
          {lang('catalogs.Search.title')}
        </h1>
        <h4>
          <span>{search}</span>
        </h4>
      </div>
      <Container className="content">
        <Hidden xsUp={!loading}>
          <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loading}>
          <Grid container spacing={3}>
            {
              data && data.length > 0 ? data.map((element, index) => {
                return (
                  <Grid item xs={12} md={4} lg={3} key={element.slug}>
                    <SearchItem item={element} index={index} />
                  </Grid>
                );
              }) : (
                <Grid item xs={12}>
                  <span className={classes.emptyListMessage}>
                    { search.length >= 2 ? lang('catalogs.Search.noProductFound') : lang('catalogs.Search.searchWithMoreCharacters') }
                  </span>
                </Grid>
              )
            }
          </Grid>
        </Hidden>
      </Container>
    </section>
  );
}

Search.contextTypes = { ...AppContext };

const itemStyles = makeStyles(() => ({
  cardLink: {
    textDecoration: 'none !important'
  },
  media: {
    height: '200px',
  },
  placeholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    fontSize: '1.2rem',
    padding: '.5rem 0.2rem',
    lineHeight: '1.4rem',
  },
  extraLabel: {
    textAlign: 'left',
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    padding: '.5rem 0.2rem 0.2rem',
    fontWeight: 'bold'
  }
}));

export interface ISearchItemProps {
  item: IProductSection;
  index: number;
}

function SearchItem(props: ISearchItemProps) {
  const { item, index } = props;
  const classes = itemStyles();

  return (
    <Grow in timeout={1000 + (index * 200)}>
      <NavLink to={`/catalogs/${item.catalog}/${item.family}/${item.category}/${item.subcategory}/${item.slug}`} className={classes.cardLink}>
        <Box boxShadow={1} style={{ borderRadius: '3px' }}>
          <Card>
            <CardActionArea>
              { item.preview ? (
                <CardMedia
                  className={classes.media}
                  component="img"
                  image={item.preview}
                  title={item.label}
                />
              ) : (
                <div className={classes.placeholder}>
                  <LocalOfferRounded style={{ fontSize: '4rem', opacity: '0.5' }} />
                </div>
              )}
              <CardContent>
                <Hidden xsUp={!(item.showCode ?? true)}>
                  <Typography variant="h6" component="h2" className={classes.extraLabel}>
                    {cutOffAtLine(item.code)}
                  </Typography>
                </Hidden>
                <Typography variant="h6" component="h2" className={classes.label}>
                  {cutOffAtLine(item.label)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </NavLink>
    </Grow>
  );
}

SearchItem.contextTypes = { ...AppContext };
