import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// eslint-disable-next-line no-unused-vars
import { History } from 'history';

import apiReducer from './api/reducer';
import sessionReducer from './session/reducer';
import appReducer from './app/reducer';

export default (history: History) => combineReducers({
  router: connectRouter(history),
  api: apiReducer,
  session: sessionReducer,
  app: appReducer,
});
