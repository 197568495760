import { useEffect, useRef } from 'react';

export function useUpdatedRef<T>(observedProps: T): React.MutableRefObject<T> {
  const ref = useRef(observedProps);
  useEffect(() => {
    ref.current = observedProps;
  }, [observedProps]);

  return ref;
}
