import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Container, Card, CardActionArea, CardMedia, CardContent, Hidden, CircularProgress, Grow, Theme, Box, Button, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CloseRounded, LocalOfferRounded } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { ICatalog, IBreadcrumb, IBrand } from '../../state/data-types';
import Navigation from '../common/Navigation';
import { AppContext, IAppContext } from '../../containers/App';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { Catalog } from '../../globals/constants';
import { cutOffAtLine, isBlank } from '../../state/utils';

export interface ICategoriesProps {
  data: Array<ICatalog>;
  breadcrumbs: Array<IBreadcrumb>;
  brands: IBrand[];
  brand?: string;
  loading: boolean;
  catalog: string;
  family: string;
  group?: string;
  category?: string;
}

export default function Categories(props: ICategoriesProps, context: IAppContext) {
  const { dispatch, push, lang } = context;
  const { data, loading, catalog, brands, brand, family, group, category, breadcrumbs } = props;

  const chooseBrand = (b: string) => {
    if (b !== brand) {
      dispatch(push(`/catalogs/${catalog}?brand=${b}`));
    } else {
      dispatch(push(`/catalogs/${catalog}`));
    }
  };

  return (
    <section className="static-page">
      <div className="hero" style={{ backgroundColor: getPrimaryColor(catalog as Catalog).main }}>
        <h1>
          {lang('catalogs.Categories.ourProducts')}
        </h1>
        <h4>
          <Navigation breadcrumbs={breadcrumbs} catalog={catalog} brand={brand} />
        </h4>
      </div>
      <Container className="content" maxWidth="xl">
        <Collapse in={brands && brands.length > 0 && !isBlank(brand)}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginBottom="1rem">
            <Typography variant="body1">
              {lang('catalogs.Categories.activeSearchByBrand')}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" justifyContent="center">
              {
                brands?.filter(b => b.slug === brand).map(b => (
                  <Button variant="text" color="primary" endIcon={<CloseRounded />} style={{ marginLeft: '.2rem', fontSize: '1rem', fontWeight: 'bold', textTransform: 'none' }} onClick={() => chooseBrand(b.slug)} key={b.slug}>{b.label}</Button>
                ))
              }
            </Box>
          </Box>
        </Collapse>
        <Hidden xsUp={!loading}>
          <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loading}>
          <Grid container>
            {
              data ? data.map((element, index) => {
                let cat = category;
                if (!cat && breadcrumbs[2] != null) {
                  cat = breadcrumbs[2].slug;
                }

                let inferredGroup = group;
                if (!inferredGroup && breadcrumbs[1] != null) {
                  inferredGroup = breadcrumbs[1].slug;
                }

                const isHead = index % 5 === 0;
                const isTail = index % 5 === 4;
                return (
                  <Grid container item xs={12} md={4} lg={isHead || isTail ? 3 : 2} key={`${element.slug}_${index}`}>
                    <Grid item lg={4} implementation="css" mdDown lgUp={!isHead} component={Hidden} />
                    <Grid item xs={12} lg={isHead || isTail ? 8 : 12}>
                      <CategoryItem item={element} catalog={catalog} brand={brand} family={family} group={inferredGroup} category={cat} index={index} />
                    </Grid>
                  </Grid>
                );
              }) : null
            }
          </Grid>
        </Hidden>
      </Container>
    </section>
  );
}

Categories.contextTypes = { ...AppContext };

const itemStyles = makeStyles((theme: Theme) => ({
  cardLink: {
    textDecoration: 'none !important',
  },
  item: {
    border: '1px solid white',
    borderBottom: '2px solid white',
    borderRadius: '0px',
    margin: '.8rem',

    '&:hover': {
      border: '1px solid #E3E3E3',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },

    '& button:hover > span': {
      display: 'none'
    }
  },
  media: {
    height: '200px',
    objectFit: 'contain',
  },
  placeholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    padding: '0 0.2rem .5rem',
  },
  extraLabel: {
    textAlign: 'left',
    fontSize: '1.6rem',
    lineHeight: '1.8rem',
    padding: '.5rem 0.2rem 0.2rem',
    fontWeight: 'bold'
  }
}));

export interface ICategoryItemProps {
  item: ICatalog;
  index: number;
  catalog: string;
  brand?: string;
  family: string;
  group?: string;
  category?: string;
}

export function CategoryItem(props: ICategoryItemProps) {
  const { item, index, catalog, brand, family, group, category } = props;
  const classes = itemStyles();

  let baseUrl = `/catalogs/${catalog}/${family}`;
  if (category) {
    baseUrl = `/catalogs/${catalog}/${family}/${group}/${category}`;
  } else if (group) {
    baseUrl = `/catalogs/${catalog}/${family}/${group}`;
  }

  baseUrl += `/${item.slug}`;

  if (!isBlank(brand)) {
    baseUrl += `?brand=${brand}`;
  }

  return (
    <Grow in timeout={1000 + (index * 200)}>
      <NavLink to={baseUrl} className={classes.cardLink}>
        <Card className={classes.item}>
          <CardActionArea>
            { item.icon ? (
              <CardMedia
                className={classes.media}
                component="img"
                image={item.icon}
                title={item.label}
                alt={item.image_alt_tag}
              />
            ) : (
              <div className={classes.placeholder}>
                <LocalOfferRounded style={{ fontSize: '4rem', opacity: '0.5' }} />
              </div>
            )}
            <CardContent style={{ paddingTop: 0 }}>
              <Typography variant="h6" component="h2" className={classes.extraLabel}>
                {cutOffAtLine(item.extra_label)}
              </Typography>
              <Hidden xsUp={item.extra_label === item.label}>
                <Typography variant="h6" component="h2" className={classes.label}>
                  {cutOffAtLine(item.label)}
                </Typography>
              </Hidden>
            </CardContent>
          </CardActionArea>
        </Card>
      </NavLink>
    </Grow>
  );
}

CategoryItem.contextTypes = { ...AppContext };
