import * as XLSX from 'xlsx';
import { Button, CircularProgress, Container, FormLabel, Grid, Hidden, IconButton, Input } from '@material-ui/core';
import { Check, CloseRounded, DeleteForever, Edit } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import { IAppContext, AppContext } from '../../containers/App';
import { ISession } from '../../state/data-types';
import { FilePicker } from '../common/FilePicker';
import { throwIfErrorResponse } from '../../utils/api';

export interface IFastCheckoutProps {
  session: ISession;
  loadingUser: boolean;
}

type ArticleError = 'not-found'| 'quantity-error'

type VerifyResponseRow = {
  id?: number | null;
  articleCode: string;
  quantity: number;
  pieces: string;
  error: ArticleError;
};

const checkIfQuantityIsMultipleOfMinQuantity = (newQuantity: string, newPieces?: number | null) => {
  if (!newPieces) {
    return false;
  }
  if (!newQuantity) {
    return false;
  }
  return Math.floor(Number(newQuantity) / Number(newPieces)) * Number(newPieces) === Number(newQuantity);
};

export default function FastCheckout(props: IFastCheckoutProps, context: IAppContext) {
  const { lang } = context;
  const {
    session, loadingUser,
  } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const refs = useRef({ enqueueSnackbar, closeSnackbar, lang });
  useEffect(() => {
    refs.current.enqueueSnackbar = enqueueSnackbar;
    refs.current.closeSnackbar = closeSnackbar;
    refs.current.lang = lang;
  }, [closeSnackbar, enqueueSnackbar, lang]);

  React.useEffect(() => {
    context.actions.session.getUser();
  }, []);

  const [addingArticles, setAddingArticles] = useState(false);
  const [verifyResponse, setVerifyResponse] = useState<null|Array<VerifyResponseRow>>(null);
  const [verifying, setVerifying] = useState(false);
  const verifyResponseByArticleCode = useMemo<Partial<Record<string, VerifyResponseRow>>>(() => {
    if (!verifyResponse) { return {}; }
    return verifyResponse.reduce((acc: Partial<Record<string, VerifyResponseRow>>, cur) => {
      acc[cur.articleCode] = cur;
      return acc;
    }, {});
  }, [verifyResponse]);

  const [orderRows, _setOrderRows] = useState<Array<{
    id?: number | null;
    code: string;
    quantity?: number;
    minQuantity?: number;
  }>>([]);

  useEffect(() => {
    _setOrderRows((cur) => cur.map((row) => ({
      ...row,
      id: verifyResponseByArticleCode[row.code]?.id,
      minQuantity: (verifyResponseByArticleCode[row.code]?.pieces && Number(verifyResponseByArticleCode[row.code]?.pieces)) || row.minQuantity,
    })));
  }, [verifyResponseByArticleCode]);

  const anyError = useMemo(() => orderRows.some(({ code }) => Boolean(verifyResponseByArticleCode[code]?.error)), [verifyResponseByArticleCode, orderRows]);

  const setOrderRows = useCallback((newValue: typeof orderRows) => {
    setVerifyResponse(null);
    _setOrderRows(newValue);
  }, []);

  const [editableItemRow, setEditableItemRow] = useState<EditableItemRow>({
    code: '',
    pieces: null,
    quantity: '',
  });

  const handlePrependRow = useCallback((productItemCode: string, quantity: number, minQuantity: number | undefined) => {
    const candidateIndex = orderRows.findIndex((row) => row.code.toLowerCase() === productItemCode.toLowerCase());
    if (candidateIndex !== -1) {
      const newOrderRows = [...orderRows];
      newOrderRows.splice(candidateIndex, 1);
      newOrderRows.unshift({
        code: productItemCode,
        quantity: quantity + (orderRows[candidateIndex].quantity ?? 0),
        minQuantity,
      });
      setOrderRows(newOrderRows);
    } else {
      setOrderRows([{
        code: productItemCode,
        quantity,
        minQuantity,
      }, ...orderRows]);
    }
    setEditableItemRow({
      code: '',
      pieces: null,
      quantity: '',
    });
  }, [orderRows, setOrderRows]);

  const handleRemove = useCallback((productItemCode: string) => {
    const candidateIndex = orderRows.findIndex((row) => row.code.toLowerCase() === productItemCode.toLowerCase());
    if (candidateIndex !== -1) {
      const newOrderRows = [...orderRows];
      newOrderRows.splice(candidateIndex, 1);
      setOrderRows(newOrderRows);
    }
  }, [orderRows, setOrderRows]);

  const [editingCode, setEditingCode] = useState<string | null>(null);
  const handleEditRowStart = useCallback((productItemCode: string) => {
    setEditingCode(productItemCode);
  }, []);
  const handleEditRowEnd = useCallback((quantity: number) => {
    if (editingCode === null) {
      return;
    }

    const candidateIndex = orderRows.findIndex((row) => row.code.toLowerCase() === editingCode.toLowerCase());
    if (candidateIndex !== -1) {
      const newOrderRows = [...orderRows];
      newOrderRows[candidateIndex] = {
        ...newOrderRows[candidateIndex],
        quantity,
      };
      setOrderRows(newOrderRows);
      setEditingCode(null);
    }
  }, [editingCode, orderRows, setOrderRows]);

  const handleVerify = useCallback(async () => {
    setVerifying(true);
    setVerifyResponse(null);
    try {
      const response = throwIfErrorResponse(await context.actions.session.checkCheckout(orderRows.map((item) => ({
        articleCode: item.code,
        quantity: item.quantity || 0,
      }))));
      setVerifyResponse(response.payload.articles);
    } catch (err) {
      console.error(err);
      const key = refs.current.enqueueSnackbar(
        refs.current.lang('user.FastCheckout.unableToVerify'),
        {
          variant: 'error',
          action: (
            <Button
              style={{ color: 'white' }}
              onClick={() => refs.current.closeSnackbar(key)}
            >
              <CloseRounded />
            </Button>
          ),
        }
      );
    } finally {
      setVerifying(false);
    }
  }, [context.actions.session, orderRows]);

  const handleCheckout = useCallback(async () => {
    setAddingArticles(true);
    try {
      throwIfErrorResponse(await context.actions.session.addArticlesToCart(session.cart, orderRows.map((item) => ({
        id: item.id,
        code: item.code,
        quantity: item.quantity,
      }))));
      context.dispatch(context.push('/checkout'));
    } catch (err) {
      console.error(err);
      const key = refs.current.enqueueSnackbar(
        refs.current.lang('user.FastCheckout.unableToCheckout'),
        {
          variant: 'error',
          action: (
            <Button
              style={{ color: 'white' }}
              onClick={() => refs.current.closeSnackbar(key)}
            >
              <CloseRounded />
            </Button>
          ),
        }
      );
    } finally {
      setAddingArticles(false);
    }
  }, [context, session.cart, orderRows]);

  const [parsingXls, setParsingXls] = useState(false);
  const handleParseXls = useCallback(async (file: File) => {
    setParsingXls(true);
    try {
      const fileContent = await new Promise<ArrayBuffer>((res, rej) => {
        const fileReader = new FileReader();
        fileReader.onload = () => res(fileReader.result as ArrayBuffer);
        fileReader.onerror = () => rej(new Error('unable to read file'));
        fileReader.readAsArrayBuffer(file);
      });
      const parsedXls = XLSX.read(fileContent, {
        WTF: true,
        type: 'array',
      });
      if (parsedXls.SheetNames.length === 0) {
        throw new Error('unable to read file');
      }

      const sheet = parsedXls.Sheets[parsedXls.SheetNames[0]];

      const range = XLSX.utils.decode_range(sheet['!ref'] ?? 'A1:A300');

      const startRow = range.s.r + 1;
      const endRow = range.e.r + 1;

      const newOrderRows: typeof orderRows = [];
      for (let row = startRow; row <= endRow; row++) {
        if (row === startRow && typeof sheet[`B${row}`]?.v !== 'number') {
          continue; // skip header if present
        }
        if (sheet[`A${row}`]?.w) {
          const code = sheet[`A${row}`].w.trim();
          const quantity = sheet[`B${row}`]?.v || undefined;
          const existingIndex = newOrderRows.findIndex((existing) => existing.code === code);
          if (existingIndex === -1) {
            newOrderRows.push({
              code: sheet[`A${row}`].w,
              quantity: sheet[`B${row}`]?.v || undefined,
            });
          } else {
            newOrderRows[existingIndex].quantity = newOrderRows[existingIndex].quantity !== undefined && quantity !== undefined
              ? newOrderRows[existingIndex].quantity + quantity : (newOrderRows[existingIndex].quantity ?? quantity ?? undefined);
          }
        }
      }

      setOrderRows(newOrderRows);
    } catch (err) {
      console.error('unable to read file', err);
      const key = refs.current.enqueueSnackbar(
        refs.current.lang('user.FastCheckout.unableToParseXLS'),
        {
          variant: 'error',
          action: (
            <Button
              style={{ color: 'white' }}
              onClick={() => refs.current.closeSnackbar(key)}
            >
              <CloseRounded />
            </Button>
          ),
        }
      );
    } finally {
      setParsingXls(false);
    }
  }, [setOrderRows]);

  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const handleDownloadTemplate = useCallback(() => {
    setDownloadingTemplate(true);
    try {
      const worksheet = XLSX.utils.json_to_sheet([{
        [lang('user.FastCheckout.itemCode')]: '',
        [lang('user.FastCheckout.quantity')]: '',
      }]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio1');
      XLSX.writeFile(workbook, 'template.xlsx');
    } catch (err) {
      console.error(err);
      const key = refs.current.enqueueSnackbar(
        refs.current.lang('user.FastCheckout.unableToDownloadTemplate'),
        {
          variant: 'error',
          action: (
            <Button
              style={{ color: 'white' }}
              onClick={() => refs.current.closeSnackbar(key)}
            >
              <CloseRounded />
            </Button>
          ),
        }
      );
    } finally {
      setDownloadingTemplate(false);
    }
  }, [lang]);

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.FastCheckout.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.FastCheckout.title')}
        </h1>
      </div>
      <Container className="content" maxWidth={false} style={{ maxWidth: '95%' }}>
        <Hidden xsUp={!loadingUser}>
          <div style={{
            display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%',
          }}
          >
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loadingUser}>
          <Grid container spacing={0}>
            {orderRows.length === 0 && (
            <>
              <Grid item xs={12} style={{ marginBottom: 24 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FilePicker onPicked={handleParseXls} mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                      {
                      ({ show }) => <Button disabled={parsingXls} color="primary" variant="contained" onClick={show}>{lang('user.FastCheckout.parseXls')}{parsingXls && <>&nbsp;<CircularProgress size="1.2rem" /></>}</Button>
                    }
                    </FilePicker>
                  </Grid>
                  <Grid item>
                    <Button color="secondary" variant="contained" disabled={downloadingTemplate} onClick={handleDownloadTemplate}>{lang('user.FastCheckout.downloadTemplate')}{downloadingTemplate && <>&nbsp;<CircularProgress size="1.2rem" /></>}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
            )}
            <Grid item xs={12} style={{ marginBottom: 48 }}>
              <AddItemRow onAdd={handlePrependRow} onChange={setEditableItemRow} itemRow={editableItemRow} />
            </Grid>
            <Grid item xs={12} md={6}>
              {orderRows.map(({ code, quantity, minQuantity }, i) => (
                <div key={code} style={{ backgroundColor: i % 2 === 0 ? '#e6e6e6' : '' }}>
                  <ItemRow minQuantity={minQuantity} editing={editingCode === code} onEditStart={handleEditRowStart} onRemove={handleRemove} onEditEnd={handleEditRowEnd} code={code} quantity={quantity} error={verifyResponseByArticleCode[code]?.error} />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end', marginTop: 48 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <div style={{ marginBottom: 24 }}>
                    <Button style={{ width: '100%' }} disabled={editingCode !== null || orderRows.length === 0 || anyError || verifying} color="primary" variant="contained" onClick={handleVerify}>{lang('user.FastCheckout.verify')}{verifying && <>&nbsp;<CircularProgress size="1.2rem" /></>}</Button>
                  </div>
                  <div>
                    <Button style={{ width: '100%' }} disabled={editingCode !== null || orderRows.length === 0 || anyError || !verifyResponse || verifying || addingArticles} color="primary" variant="contained" onClick={handleCheckout}>{lang('user.FastCheckout.checkout')}{addingArticles && <>&nbsp;<CircularProgress size="1.2rem" /></>}</Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </section>
  );
}

export interface ItemRowProps {
  code: string;
  quantity?: number;
  minQuantity?: number;
  error?: ArticleError;
  onRemove: (code: string) => void;
  onEditStart: (code: string) => void;
  onEditEnd: (quantity: number) => void;
  editing: boolean;
}

const errorToString: Record<ArticleError, (lang: (key: string) => string) => string> = {
  'not-found': (lang) => lang('user.FastCheckout.notFound'),
  'quantity-error': (lang) => lang('user.FastCheckout.quantityError'),
};

export function ItemRow({ code, quantity: initialQuantity, error, onRemove, onEditStart, editing, minQuantity, onEditEnd }: ItemRowProps, context: IAppContext) {
  const { lang } = context;
  const quantityInputRef = useRef<HTMLInputElement | null>(null);
  const [quantity, setQuantity] = useState(String(initialQuantity || 0));
  useEffect(() => {
    setQuantity(String(initialQuantity || 0));
  }, [initialQuantity]);

  // We can be quite lax here, as the "verify" step will prevent wrong quantities anyway,
  // therefore the following is more a UX improvement rather than a complete validation, i.e
  // the user will not be able to confirm a wrong quantity if the datum is available.
  const pieces = minQuantity ?? 1;
  const isQuantityMultipleOfMinQuantity = useMemo(() => checkIfQuantityIsMultipleOfMinQuantity(quantity, pieces), [quantity, pieces]);

  return (
    <Grid container>
      <Grid item xs={6} sm={4}>
        <Input style={{ color: error ? '#fe0000' : 'inherit', paddingLeft: '0.2rem', paddingRight: '0.2rem', height: '1rem' }} disableUnderline type="text" value={code} disabled />
      </Grid>
      <Grid item xs={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          inputRef={quantityInputRef}
          disableUnderline
          style={{ color: 'inherit', paddingLeft: '0.2rem', paddingRight: '0.2rem', height: '1rem', borderBottom: !editing ? 'none' : '1px solid black', borderTop: !editing ? 'none' : '1px solid transparent' }}
          type="number"
          value={quantity}
          disabled={!editing}
          inputProps={{
            step: pieces,
          }}
          onChange={() => quantityInputRef.current && setQuantity(quantityInputRef.current.value)}
          onBlur={() => quantityInputRef.current && setQuantity(quantityInputRef.current.value.trim())}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && editing && quantityInputRef.current) {
              e.preventDefault();
              e.stopPropagation();
              const newQuantity = quantityInputRef.current.value.trim();
              if (checkIfQuantityIsMultipleOfMinQuantity(newQuantity, pieces)) {
                onEditEnd(Number(newQuantity || 0));
              }
            }
          }}
        />
        {minQuantity && minQuantity > 1 && <div>{lang('user.FastCheckout.min', { minQuantity })}</div>}
      </Grid>
      <Grid xs={12} sm={2} item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <IconButton
            style={{ height: '1rem', transform: 'scale(0.80)', marginTop: -2 }}
            size="small"
            edge="start"
            disabled={editing && !isQuantityMultipleOfMinQuantity}
            onClick={() => {
              if (editing) {
                onEditEnd(Number(quantity || 0));
              } else {
                onEditStart(code);
                setTimeout(() => {
                  if (quantityInputRef.current) {
                    quantityInputRef.current.focus();
                  }
                });
              }
            }}
          >
            {!editing ? <Edit /> : <Check />}
          </IconButton>
        </div>
        <div style={{ marginLeft: 12 }}>
          <IconButton
            style={{ height: '1rem', transform: 'scale(0.80)', marginTop: -2 }}
            size="small"
            edge="start"
            onClick={() => onRemove(code)}
          >
            <DeleteForever />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        {error && <div style={{ color: '#fe0000', paddingLeft: '0.2rem', paddingRight: '0.2rem' }}>{errorToString[error](lang)}</div>}
      </Grid>
    </Grid>

  );
}

export type EditableItemRow = {code: string; quantity: string; pieces: number|null; error?: ArticleError}

export interface AddItemRowProps {
  itemRow: EditableItemRow;
  onAdd: (code: string, quantity: number, minQuantity: number | undefined) => void;
  onChange: (itemRow: EditableItemRow) => void;
}

export function AddItemRow({ onAdd, itemRow, onChange }: AddItemRowProps, context: IAppContext) {
  const { lang } = context;

  const abortControllerRef = useRef<AbortController|null>(null);
  const [loadingPieces, setLoadingPieces] = useState(false);
  const itemRowRef = useRef(itemRow);
  useEffect(() => {
    itemRowRef.current = itemRow;
  }, [itemRow]);
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const { code, quantity, pieces, error } = itemRow;
  const [cachedCode, setCachedCode] = useState(code);
  useEffect(() => {
    setCachedCode(code);
  }, [code]);

  useEffect(() => {
    (async () => {
      setLoadingPieces(true);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      onChangeRef.current({
        ...itemRowRef.current,
        pieces: null,
        error: undefined,
      });
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      try {
        if (code) {
          const checkResponse = throwIfErrorResponse(await context.actions.session.checkCheckout([{
            articleCode: code,
            quantity: 0,
          }]));
          const articleData = checkResponse.payload.articles[0];
          const fetchedQuantity = Number(articleData.pieces ?? 1);
          if (!abortController.signal.aborted) {
            onChangeRef.current({
              ...itemRowRef.current,
              pieces: fetchedQuantity,
              error: articleData.error,
            });
          }
        }
      } finally {
        if (!abortController.signal.aborted) {
          setLoadingPieces(false);
        }
      }
    })().catch(console.error);
  }, [code]);

  const handleAdd = useCallback((newQuantity: string) => {
    onAdd(code, Number(newQuantity), pieces ?? undefined);
  }, [code, onAdd, pieces]);

  const isQuantityMultipleOfMinQuantity = useMemo(() => checkIfQuantityIsMultipleOfMinQuantity(quantity, pieces), [quantity, pieces]);

  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  const disableAddRow = !pieces || !quantity || loadingPieces || !isQuantityMultipleOfMinQuantity || Boolean(error);

  return (
    <Grid container spacing={2}>
      <Grid item style={{ width: '50ch' }}>
        <FormLabel>{lang('user.FastCheckout.itemCode')}</FormLabel><br />
        <Input disableUnderline style={{ backgroundColor: '#e6e6e6', paddingLeft: '0.2rem', paddingRight: '0.2rem', width: '100%' }} type="text" value={cachedCode} onChange={(e) => setCachedCode(e.target.value)} onBlur={(e) => onChange({ ...itemRow, code: e.target.value.trim() })} />
      </Grid>
      <Grid item style={{ width: '25ch' }}>
        <FormLabel>{lang('user.FastCheckout.quantity')}</FormLabel><br />
        <Input
          inputRef={quantityInputRef}
          disableUnderline
          style={{ backgroundColor: '#e6e6e6', paddingLeft: '0.2rem', paddingRight: '0.2rem', width: '100%' }}
          type="number"
          value={quantity}
          onChange={() => quantityInputRef.current && onChange({ ...itemRow, quantity: quantityInputRef.current.value })}
          onBlur={() => quantityInputRef.current && onChange({ ...itemRow, quantity: quantityInputRef.current.value.trim() })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && quantityInputRef.current) {
              e.preventDefault();
              e.stopPropagation();
              const newQuantity = quantityInputRef.current.value.trim();
              if (checkIfQuantityIsMultipleOfMinQuantity(newQuantity, pieces)) {
                handleAdd(newQuantity);
              }
            }
          }}
        />
      </Grid>
      <Grid item style={{ width: '25ch' }}>
        <FormLabel style={{ color: !pieces || isQuantityMultipleOfMinQuantity ? '' : '#fe0000' }}>{lang('user.FastCheckout.minQuantity')}</FormLabel><br />
        <div style={{ position: 'relative', zIndex: 0 }}>
          <div style={{ position: 'relative', zIndex: 0 }}>
            <Input disableUnderline style={{ color: !pieces || isQuantityMultipleOfMinQuantity ? 'inherit' : '#fe0000', backgroundColor: '#e6e6e6', paddingLeft: '0.2rem', paddingRight: '0.2rem', width: '100%' }} type="number" disabled value={pieces ?? ''} />
          </div>
          {loadingPieces
              && (
              <div style={{ position: 'absolute', zIndex: 1, inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                <CircularProgress size="1.2rem" />
              </div>
              )}
        </div>
      </Grid>
      <Grid item style={{ width: '25ch' }}>
        <FormLabel>&nbsp;</FormLabel><br />
        <Button style={{ marginTop: -2.5, width: '100%' }} disabled={disableAddRow} color="primary" variant="contained" onClick={() => handleAdd(quantity)}>{lang('user.FastCheckout.addItem')}</Button>
        {error && <div style={{ color: '#fe0000' }}>{errorToString[error](lang)}</div>}
      </Grid>
    </Grid>
  );
}

ItemRow.contextTypes = { ...AppContext };
AddItemRow.contextTypes = { ...AppContext };
FastCheckout.contextTypes = { ...AppContext };
