import React from 'react';

import { Button, Container, Hidden, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { AppContext, IAppContext } from '../../containers/App';
import { ISession, Role } from '../../state/data-types';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { Catalog } from '../../globals/constants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: '0.1rem 1rem 0',
    position: 'relative',

    backgroundColor: getPrimaryColor(Catalog.AIREX).main,
    borderBottom: '10px solid white',
  },
}));

export interface IAgentNavBar {
  match: any;
  session: ISession;
  catalog: Catalog;
}

export default function AgentNavBar(props: IAgentNavBar, context: IAppContext) {
  const { dispatch, push, lang } = context;
  const { match, session } = props;
  const { path } = match;
  const classes = useStyles();

  return (
    <Hidden xsUp={!session.authenticated || session.role !== Role.Agent || path === '/clients' || !session?.agent?.selectedClient}>
      <div className={classes.container}>
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body2" style={{ color: 'white' }}>
            <span>{lang('common.AgentNavBar.operatingAs')}</span>
            <span style={{ fontWeight: 'bold' }}>{` ${session?.agent?.selectedClient?.name}.`}</span>
          </Typography>
          <Button variant="text" style={{ marginLeft: '.5rem', opacity: 0.7, fontWeight: 'bold', color: 'white', textTransform: 'none' }} color="primary" onClick={() => dispatch(push('/clients'))}>
            {lang('common.AgentNavBar.edit')}
          </Button>
        </Container>
      </div>
    </Hidden>
  );
}

AgentNavBar.contextTypes = { ...AppContext };
