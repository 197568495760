import React, { useContext } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AppContext, BaseAppContext } from '../../containers/App';
import { IApp, IState } from '../../state/data-types';

export interface INotifierProps {
  app: IApp;
}

let displayed: string[] = [];

function Notifier(props: INotifierProps) {
  const dispatch = useDispatch();
  const { actions } = useContext(BaseAppContext);
  const { notifications } = props.app;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key || '')) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_event, myKey) => {
          // removen this snackbar from redux store
          actions.app.removeSnackbar(myKey.toString());
          removeDisplayed(myKey.toString());
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key || '');
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}

function mapStateToProps(state: IState) {
  return {
    app: state.app,
  };
}

export default connect(mapStateToProps)(Notifier);

Notifier.contextTypes = { ...AppContext };
