import React from 'react';
import { Container, Divider, Grid, makeStyles } from '@material-ui/core';
import { AppContext, IAppContext } from '../../containers/App';
import News from './News';
import { IApi } from '../../state/data-types';
import Banners from './Banners';
import Reviews from './Reviews';
import CatalogBanner from './CatalogBanner';
import { Catalog } from '../../globals/constants';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    height: '10px',
    backgroundColor: 'rgba(0,0,0,0.64)',
    border: 0,
  },
  image: {
    width: '80%',
    maxWidth: '15rem',
    margin: '2rem auto'
  }
}));

export interface IHomeProps {
  api: IApi;
}

export default function Home(props: IHomeProps, context: IAppContext) {
  const classes = useStyles();
  const { lang } = context;
  React.useEffect(() => {
    context.actions.api.getMetaData('homepage');
  }, []);

  return (
    <div>
      <Banners api={props.api} />
      <Divider className={classes.divider} />
      <Container maxWidth="xl" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
        <CatalogBanner catalog={Catalog.AIREX} />
        <CatalogBanner catalog={Catalog.PROXIMA} />
      </Container>
      <Divider className={classes.divider} />
      <Container maxWidth="lg" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
        <Grid container>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/customers.png')} alt={lang('home.Home.customers')} />
          </Grid>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/orders.png')} alt={lang('home.Home.orders')} />
          </Grid>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/agents.png')} alt={lang('home.Home.agents')} />
          </Grid>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/precisione.png')} alt={lang('home.Home.precision')} />
          </Grid>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/organizzazione.png')} alt={lang('home.Home.organization')} />
          </Grid>
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <img className={classes.image} src={require('../../images/esclusivita.png')} alt={lang('home.Home.exclusivity')} />
          </Grid>
        </Grid>
      </Container>
      <Reviews api={props.api} />
      <Divider className={classes.divider} />
      <News news={props.api.news.items} />
    </div>
  );
}

Home.contextTypes = { ...AppContext };
