import { OptionsObject } from 'notistack';

export interface INotification {
  dismissed?: boolean;
  key?: string;
  message: string;
  options?: OptionsObject;
}

export interface ICatalog {
  extra_label: string;
  label: string;
  icon?: string;
  slug: string;
  image_alt_tag?: string;
}

export interface IProductSection {
  preview?: string;
  code: string;
  label: string;
  description_html?: string;
  catalog: string;
  family: string;
  brand: string;
  brand_name?: string;
  brand_image?: string;
  gallery: string[];
  pictograms: string[];
  group?: number;
  category: string;
  subcategory: string;
  document?: string | null;
  document2?: string | null;
  items: Array<IProductItem>;
  slug: string;
  image_alt_tag?: string;
  showCode?: boolean;
}

export interface IProductItem {
  id: number;
  code: string;
  label: string;
  description?: string;
  description_more?: string;
  pieces: string;
  attributes: Array<any>;
  document?: string;
  isFavorite?: boolean;
  price?: number;
}

export interface IMetaInfo {
  meta_title: string;
  meta_description: string;
}

export interface INews {
  label: string;
  description: string;
  link: string;
  icon: string;
}

export interface IPartner {
  label: string;
  icon: string;
  link: string;
  image_alt_tag?: string;
}

export interface IBreadcrumb {
  slug: string;
  label: string;
}

export interface IContactFormValues {
  name: string;
  surname: string;
  partitaIva: string;
  company: string;
  kind: string;
  email: string;
  phone: string;
  object: string;
  notes: string;
  city: string;
}

export interface ICustomer {
  id: number;
  name: string;
  code: string;
  piva: string;
  cf: string;
  description: string;
  offices: IDeliveryPoint[];
}

export interface IDeliveryPoint {
  id: number;
  code: string;
  name: string;
  address: string;
  streetNumber: string;
  addressExtra: string;
  city: string;
  cap: string;
  province: string;
  state: string;
  isMainAddress: boolean;

  pagamento: string | null;
  modalita: string | null;
  porto: string | null;
  vettore: string | null;

  agentCode: string | null;
  agentName: string | null;
}

export interface IOrderArticle {
  id?: number | null;
  code: string;
  description: string;
  quantity: number;
  quantityUnfilled?: number;
  price: number;
  preview?: string;
  url?: string;
  descriSup: string;
  totalPrice: number;
  deliveryAt: string;
}

export interface ICartArticle {
  id?: number | null;
  code: string;
  description?: string;
  quantity: number;
  price?: number;
  totalPrice?: number;
  preview?: string;
  url?: string;
  available?: number;
  status: boolean;
  pieces: string;
  leadTime?: string;

  discountOne?: number;
  discountTwo?: number;
  discountThree?: number;
  discountFour?: number;
  promo?: string;
}

export interface IOrder {
  numeroDocumento: string;
  orderExecuted: boolean;
  dataDocumento: string;
  statoDocumento: string;
  articles: IOrderArticle[];
  note?: string;
  totaleImponibile: number;
  totaleImposta: number;
  dataRegistrazione: string;
  indirizzoFat: string;
  localitaFat: string;
  provinciaFat: string;
  capFat: string;
  nazioneFat: string;
  indirizzoCon: string;
  localitaCon: string;
  provinciaCon: string;
  capCon: string;
  nazioneCon: string;
  protocollo: string;
  codiceCausale: string;
  descriCausale: string;
  codicePagamento: string;
  descriPagamento: string;
  totaleIva: number;
  scontoAssoluto: number;
  scontoPercentuale: number;
}

export interface IOrderRequest {
  clientCode: string;
  officeCode: string;
  clientId: number;
  officeId: number;
  articles: Array<{
    articleCode: string;
    quantity: number;
  }>;
  note?: string;
  progressivoOrdine?: string;
  dataEvasione?: string;
  operation: string;
  coupon?: string;
}

export enum Role {
  Client = 'client',
  Agent = 'agent',
}

export interface IAgent {
  clients: ICustomer[];
  selectedClient?: ICustomer;
  totalCount: number;
}

export interface ICart {
  items: ICartArticle[];
  orderChecked: boolean;
  totaleDocumento?: number;
  totaleImponibile?: number;
  totaleImposta?: number;
  totaleMerce?: number;
  totaleSconto?: number;
  coupon?: string;
  esitoCoupon?: string;
  note?: string;

  synced: boolean;
}

export interface IQuote {
  id: number;
  cartJson: ICart;
  createdAt: string;
  showClient: boolean;
  title: null;
}

export interface ISession {
  hasQuotes: boolean;
  authenticated: boolean;
  token: string | undefined;
  loading: boolean;
  loadingSignUp: boolean;
  role: Role;
  loadingOrders: boolean;
  user?: ICustomer;
  loadingUser: boolean;
  error: string | undefined;
  cart: ICart;
  agent?: IAgent;
  loadingClients: boolean;
  showPrices?: boolean;
}

export interface IDocumentItem {
  slug: number;
  label: string;
  icon?: string;
  document?: string;
}

export interface IDocumentCategory {
  slug: string;
  label: string;
  icon?: string;
}

export interface IBrand {
  slug: string;
  label: string;
}

export interface IBanner {
  id: number;
  image: string;
  image_alt_tag: string | null;
  link: string;
  name: string;
  title?: string;
  description?: string;
  cta?: string;
}

export interface IReview {
  description: string;
  id: number;
  image: string | null;
  image_alt_tag: string;
  job: string;
  name: string;
  star: number;
}

export interface IApi {
  catalogs: {
    brands: IBrand[];
    product?: IProductSection;
    items: ICatalog[];
    breadcrumbs: IBreadcrumb[];
    loading: boolean;
    error: string | null;
  };
  news: {
    items: INews[];
    loading: boolean;
    error: string | null;
  };
  featured: {
    items: IProductSection[];
    loading: boolean;
    error: string | null;
  };
  recentlySeen: {
    items: IProductSection[];
    loading: boolean;
    error: string | null;
  };
  favouriteProducts: {
    products: IProductSection[];
    loading: boolean;
    error: string | null;
    pageCount: number;
  };
  partners: {
    items: IPartner[];
    loading: boolean;
    error: string | null;
  };
  banners: {
    items: IBanner[];
    loading: boolean;
    error: string | null;
  };
  reviews: {
    items: IReview[];
    loading: boolean;
    error: string | null;
  };
  search: {
    products: IProductSection[];
    loading: boolean;
    error: string | null;
  };
  contacts: {
    loading: boolean;
    error: string | null;
  };
  meta: {
    meta_title: string;
    meta_description: string;
  };
  documents: {
    loading: boolean;
    error: string | null;
    categories: IDocumentCategory[];
    items: IDocumentItem[];
    breadcrumbs: Array<{ slug: string; label: string }>;
  };
  orders: {
    loading: boolean;
    refreshing: boolean;
    error: string | null;
    items: IOrder[];
    pageCount: number;
  };
  quotes: {
    loading: boolean;
    error: string | null;
    items: IQuote[];
    pageCount: number;
  };
  backOrders: {
    loading: boolean;
    refreshing: boolean;
    error: string | null;
    items: IOrder[];
    pageCount: number;
  };
}

export interface IApp {
  notifications: INotification[];
  lang: string;
}

export interface IState {
  router: any;
  session: ISession;
  api: IApi;
  app: IApp;
}
