import React from 'react';
import _ from 'lodash';
import { Breadcrumbs, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { NavigateNextRounded } from '@material-ui/icons';
import { IBreadcrumb } from '../../state/data-types';
import { isBlank } from '../../state/utils';
import { AppContext, IAppContext } from '../../containers/App';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    color: 'white',
    '& ol > li': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
        '&:last-of-type': {
          display: 'block',
        },
      },
    },
  },
  item: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'white !important',
    '& a, a:visited, a:focus': {
      color: 'white',
    },
  },
}));

export interface INavigationProps {
  breadcrumbs: Array<IBreadcrumb>;
  catalog: string;
  brand?: string;
}

export default function Navigation(
  props: INavigationProps,
  context: IAppContext
) {
  const classes = useStyles();
  const { catalog, breadcrumbs, brand } = props;
  const { lang } = context;

  const filteredBreadcrumbs = _.uniqBy(breadcrumbs, 'slug');

  return (
    <Breadcrumbs
      separator={<NavigateNextRounded fontSize="small" />}
      className={classes.container}
    >
      <NavLink
        to={`/catalogs/${catalog}${!isBlank(brand) ? `?brand=${brand}` : ''}`}
        className={classes.item}
      >
        {lang('common.Navigation.catalogs')}
      </NavLink>
      {filteredBreadcrumbs.map((b, index) => {
        let url = `/catalogs/${catalog}`;
        for (let i = 0; i <= index; i += 1) {
          url += `/${filteredBreadcrumbs[i].slug}`;
        }
        if (!isBlank(brand)) {
          url += `?brand=${brand}`;
        }
        return (
          <NavLink
            to={url}
            className={classes.item}
            key={`${b.slug}_${index}`}
            style={
              index === filteredBreadcrumbs.length - 1
                ? { cursor: 'default', pointerEvents: 'none' }
                : {}
            }
          >
            {b.label}
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
}

Navigation.contextTypes = { ...AppContext };
