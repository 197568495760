import React, { useEffect } from 'react';
import { Typography, Theme, useMediaQuery } from '@material-ui/core';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { makeStyles } from '@material-ui/styles';
import { IAppContext, AppContext } from '../../containers/App';
import { IPartner } from '../../state/data-types';
import { Catalog } from '../../globals/constants';
import themeStyle from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  title: {
    marginTop: '3rem',
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '1.3rem',
  },
}));

export interface IPartnersProps {
  partners: Array<IPartner>;
  loading: boolean;
  catalog: Catalog;
}

export default function Partners(props: IPartnersProps, context: IAppContext) {
  const { lang } = context;
  const classes = useStyles();
  const { partners, catalog } = props;

  useEffect(() => {
    context.actions.api.getPartners(catalog);
  }, [catalog]);

  const lgUp = useMediaQuery(themeStyle.breakpoints.up('lg'));
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: lgUp ? 4 : 1,
    slidesToScroll: 1,
    centerMode: !lgUp,
    autoplay: true,
    autoplaySpeed: 8000
  } as Settings;

  return (
    <section style={{ position: 'relative', overflow: 'hidden', maxWidth: '100%' }}>
      <Typography variant="body1" className={classes.title}>
        {lang('home.Partners.title')}
      </Typography>
      {
        partners.length > 0
          ? (
            <Slider {...settings}>
              {
                partners.map((partner, index) => {
                  return (
                    <a key={`partner_${index}`} target="_blank" rel="noopener noreferrer" href={partner.link}><img src={partner.icon} alt={partner.image_alt_tag} style={{ maxWidth: '16rem', maxHeight: '8rem', margin: '3rem auto 5rem' }} /></a>
                  );
                })
              }
            </Slider>
          ) : null
      }
    </section>
  );
}

Partners.contextTypes = { ...AppContext };
