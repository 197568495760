import React, { useRef, useState } from 'react';
import { flushSync } from 'react-dom';

export interface FilePickerProps {
  children: ({ show }: { show: () => void }) => React.ReactNode;
  mimeType: string;
  onPicked: (file: File) => void;
}

export function FilePicker({ children, mimeType, onPicked }: FilePickerProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [requestId, setRequestId] = useState(1);

  return (
    <>
      <input
        required={false}
        key={requestId}
        multiple={false}
        ref={inputRef}
        accept={mimeType}
        type="file"
        onChange={() => {
          const file = inputRef.current?.files?.[0];
          if (file) {
            onPicked(file);
          }
        }}
        style={{
          opacity: 0,
          position: 'absolute',
          left: 0,
          top: 0,
          width: 1,
          height: 1,
        }}
        tabIndex={-1}
      />
      {children({
        show: () => {
          flushSync(() => setRequestId(n => n + 1));
          if (inputRef.current) {
            inputRef.current.click();
          }
        },
      })}
    </>
  );
}
