import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { CloseRounded, ListAltRounded } from '@material-ui/icons';
import { Typography, Container, CircularProgress, Hidden, Collapse, Fade, alpha, Theme, Box, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { IAppContext, AppContext } from '../../containers/App';
import { IApi, IBrand, ICatalog } from '../../state/data-types';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { Catalog, DEFAULT_CATALOG } from '../../globals/constants';
import Featured from '../home/Featured';
import Partners from '../home/Partners';
import { palette } from '../../styles/theme.style';
import Banners from '../home/Banners';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '0rem'
  },
  grid: {
    margin: '3rem 0 4rem'
  },
  title: {
    marginTop: '1rem',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: `${theme.palette.text.primary} !important`,
    marginBottom: '4rem',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '1.3rem',
  },
  divider: {
    width: '100%',
    height: '10px',
    backgroundColor: palette.accent.main,
    border: 0,
  }
}));

export interface ICatalogsProps {
  brands: IBrand[];
  brand: string | undefined;
  api: IApi;
  catalogs: Array<ICatalog>;
  loading: boolean;
  catalog?: Catalog;
  authenticated?: boolean;
}

const Catalogs = (props: ICatalogsProps, context: IAppContext) => {
  const { lang, dispatch, push } = context;
  const { brands, brand, catalogs, catalog = DEFAULT_CATALOG, loading } = props;
  const classes = useStyles();

  useEffect(() => {
    context.actions.api.getCatalogs(props.authenticated ?? false, catalog, brand);
    context.actions.api.getMetaData('catalogs');
  }, [catalog, brand]);

  const chooseBrand = (b: string) => {
    if (b !== brand) {
      dispatch(push(`/catalogs/${catalog}?brand=${b}`));
    } else {
      dispatch(push(`/catalogs/${catalog}`));
    }
  };

  return (
    <section className="static-page" style={{ backgroundColor: 'white' }}>
      <Banners api={props.api} />
      <Container className={classes.container} maxWidth="xl">
        <div className="content">
          {/* <Typography variant="h5" className={classes.title}>
            {`${lang('catalogs.Catalogs.title')} ${catalogDisplayName[catalog](lang)}`}
          </Typography> */}
          {/* <Typography variant="body1" className={classes.subtitle}>
            {lang('catalogs.Catalogs.subtitle')}
          </Typography> */}
          <Collapse in={brands && brands.length > 0}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginBottom="1rem">
              <Typography variant="overline" style={{ paddingBottom: '.25rem' }}>
                {lang('catalogs.Catalogs.searchByBrand')}
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" justifyContent="center">
                {
                  brands?.map(b => (
                    <Button variant="text" color={brand === b.slug ? 'primary' : 'default'} endIcon={brand === b.slug ? <CloseRounded /> : null} style={{ marginLeft: '.5rem', marginRight: '.5rem', fontSize: '1rem', fontWeight: 'bold', textTransform: 'none' }} onClick={() => chooseBrand(b.slug)} key={b.slug}>{b.label}</Button>
                  ))
                }
              </Box>
            </Box>
          </Collapse>
          <Hidden xsUp={!loading}>
            <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CircularProgress size={30} />
            </div>
          </Hidden>
          <Collapse in={!loading} timeout={1000}>
            <Grid container>
              {
                catalogs ? catalogs.map((element, index) => {
                  const isHead = index % 5 === 0;
                  const isTail = index % 5 === 4;
                  return (
                    <Grid container item xs={12} md={4} lg={isHead || isTail ? 3 : 2} key={`${element.slug}_${index}`}>
                      <Grid item lg={4} implementation="css" mdDown lgUp={!isHead} component={Hidden} />
                      <Grid item xs={12} lg={isHead || isTail ? 8 : 12}>
                        <CatalogItem
                          type="catalogs"
                          brand={brand}
                          catalog={catalog}
                          slug={element.slug}
                          icon={element.icon}
                          label={element.label}
                          index={index}
                        />
                      </Grid>
                    </Grid>
                  );
                }) : null
              }
            </Grid>
          </Collapse>
        </div>
      </Container>
      <Divider className={classes.divider} />
      <Container className={classes.container} maxWidth="xl">
        <Featured featured={props.api.featured.items} loading={props.api.featured.loading} catalog={catalog} />
      </Container>
      <Divider className={classes.divider} />
      <Container className={classes.container} maxWidth="xl">
        <Partners partners={props.api.partners.items} loading={props.api.partners.loading} catalog={catalog} />
      </Container>
    </section>
  );
};

Catalogs.defaultProps = {
  catalog: DEFAULT_CATALOG,
};

Catalogs.contextTypes = { ...AppContext };

export default Catalogs;

const itemStyles = makeStyles((theme: Theme) => ({
  cardLink: {
    textDecoration: 'none !important',
  },
  catalogContainer: {
    height: '280px',
    position: 'relative',
    borderRadius: '8px',
    margin: '.5rem',
  },
  media: {
    height: '280px',
  },
  placeholder: {
    width: '100%',
    height: '280px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  labelContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  label: {
    fontSize: '1.2rem',
    padding: '1.5rem 0.2rem',
    color: 'white',
  },
  flatContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2rem',
    marginTop: '2rem',
    textDecoration: 'none !important',
  },
  flatIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    backgroundColor: '#cce0f5',
    width: '200px',
    height: '200px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  flatIcon: {
    fontSize: '2.4rem',
    color: theme.palette.primary.main
  },
  flatLabel: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '1.1rem',
  }
}));

export interface ICatalogItemProps {
  type: string;
  catalog: string;
  slug: string;
  brand?: string;
  icon?: string;
  label: string;
  index: number;
}

export function CatalogItem(props: ICatalogItemProps) {
  const { type, catalog, slug, brand, icon, label, index } = props;
  const classes = itemStyles();

  return (
    <Fade in timeout={1000 + (index * 200)}>
      <NavLink to={`/${type}/${catalog}/${slug}${brand ? `?brand=${brand}` : ''}`} className={classes.flatContainer}>
        <span className={classes.flatIconContainer} style={{ backgroundColor: icon ? 'white' : alpha(getPrimaryColor(catalog as Catalog).main, 0.3), backgroundImage: icon ? `url(${icon})` : '' }}>
          {
            !icon ? (
              <ListAltRounded className={classes.flatIcon} />
            ) : null
          }
        </span>
        <Typography variant="h6" className={classes.flatLabel}>
          {label}
        </Typography>
      </NavLink>
    </Fade>
  );
}

CatalogItem.contextTypes = { ...AppContext };
