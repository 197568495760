import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from './containers/common/NotFoundPage';
import HomePage from './containers/home/HomePage';

export default (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/company" component={HomePage} />
    <Route
      exact
      path={[
        '/catalogs/:catalog',
        '/catalogs/:catalog/search',
        '/catalogs/:catalog/:family',
        '/catalogs/:catalog/:family/:group',
        '/catalogs/:catalog/:family/:group/:category',
        '/catalogs/:catalog/:family/:group/:category/:subcategory',
      ]}
      component={HomePage}
    />
    <Route exact path="/login" component={HomePage} />
    <Route exact path="/user" component={HomePage} />
    <Route exact path="/clients" component={HomePage} />
    <Route exact path="/orders" component={HomePage} />
    <Route exact path="/favourite" component={HomePage} />
    <Route exact path="/back-orders" component={HomePage} />
    <Route exact path="/documents/:documentId" component={HomePage} />
    <Route exact path="/documents" component={HomePage} />
    <Route exact path="/public-documents/:documentId" component={HomePage} />
    <Route exact path="/public-documents" component={HomePage} />
    <Route exact path="/checkout" component={HomePage} />
    <Route exact path="/fast-checkout" component={HomePage} />
    <Route exact path="/my-quotes" component={HomePage} />
    <Route exact path="/thank-you-for-signing-up" component={HomePage} />
    <Route exact path="/contacts" component={HomePage} />
    <Route exact path="/privacy" component={HomePage} />

    <Route component={NotFoundPage} />
  </Switch>
);
