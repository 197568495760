import qs from 'querystring';
import { RSAA } from 'redux-api-middleware';

const queryMiddleware = () => (next: any) => (action: any) => {
  if (action[RSAA] !== undefined && action[RSAA].query !== undefined) {
    const request = action[RSAA];
    request.endpoint = [
      request.endpoint.replace(/\?*/, ''),
      qs.stringify(request.query),
    ].join('?');
    delete request.query;

    return next({ [RSAA]: request });
  }

  return next(action);
};

export default queryMiddleware;
