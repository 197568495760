export function chunk<T>(arr: ArrayLike<T>, chunkLength: number): T[][] {
  const chunked = new Array<T[]>(Math.ceil(arr.length / chunkLength));
  let remainingItems = arr.length;
  for (let i = 0; i < chunked.length; i++) {
    chunked[i] = new Array(Math.min(remainingItems, chunkLength));
    for (let j = 0; j < chunked[i].length; j++) {
      chunked[i][j] = arr[i * chunkLength + j];
    }
    remainingItems -= chunkLength;
  }

  return chunked;
}
