import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import {
  Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Container, Grid, Hidden, Theme, Typography
} from '@material-ui/core';
import { LocalOfferRounded, NavigateNextRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { AppContext, IAppContext } from '../../containers/App';
import { IDocumentCategory, IDocumentItem } from '../../state/data-types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '0rem'
  },
  grid: {
    margin: '3rem 0 4rem'
  },
  title: {
    marginTop: '5rem'
  },
  subtitle: {
    textAlign: 'center',
    margin: '1rem auto',
    padding: '0 3rem',
    maxWidth: '880px',
  },
  navigationContainer: {
    width: '100%',
    color: 'white',
    '& ol > li': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
        '&:last-of-type': {
          display: 'block',
        }
      }
    }
  },
  navigationItem: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'white !important',
    '& a, a:visited, a:focus': {
      color: 'white',
    },
  }
}));

export interface IDocumentsProps {
  params: any;
  visibility: 'private' | 'public';
  documents: {
    loading: boolean;
    error: string | null;
    categories: IDocumentCategory[];
    items: IDocumentItem[];
    breadcrumbs: Array<{ slug: string; label: string }>;
  };
  authenticated: boolean;
}

export default function DocumentDetail(props: IDocumentsProps, context: IAppContext) {
  const { documents, params, visibility, authenticated } = props;
  const { documentId } = params;
  const { categories, loading, items } = documents;
  const { lang } = context;
  const classes = useStyles();

  useEffect(() => {
    context.actions.api.getDocuments(visibility);
  }, [visibility, authenticated]);

  const category = categories?.find(c => c.slug === documentId);

  useEffect(() => {
    if (category) context.actions.api.getDocument(documentId, visibility);
  }, [category]);

  const title = documents.breadcrumbs && documents.breadcrumbs.length > 0 ? documents.breadcrumbs[0].label : lang('user.DocumentDetail.downloadSection');

  return (
    <section className="static-page" style={{ backgroundColor: 'white' }}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {title}
        </h1>
        <h4>
          <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />} className={classes.navigationContainer}>
            <NavLink to={visibility === 'private' ? '/documents' : '/public-documents'} className={classes.navigationItem}>{lang('user.DocumentDetail.downloadSection')}</NavLink>
            {
              documents.breadcrumbs?.map(b => (<span key={b.slug} className={classes.navigationItem}>{b.label}</span>))
            }
          </Breadcrumbs>
        </h4>
      </div>
      <Container className={classes.container} maxWidth="lg">
        <div className="content">
          <Hidden xsUp={!loading}>
            <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CircularProgress size={30} />
            </div>
          </Hidden>
          <Hidden xsUp={loading}>
            {items && items.length > 0
              ? (
                <Grid container>
                  {items.map((element, index) => {
                    return (
                      <Grid item xs={12} md={4} lg={3}>
                        <DocumentItem
                          key={index}
                          url={element.document}
                          slug={element.slug}
                          icon={element.icon}
                          label={element.label}
                          index={index}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Typography variant="body1" style={{ margin: '0 auto' }}>
                  {lang('user.DocumentDetail.noDocumentAvailable')}
                </Typography>
              )}
          </Hidden>
        </div>
      </Container>
    </section>
  );
}

DocumentDetail.contextTypes = { ...AppContext };

const itemStyles = makeStyles(() => ({
  cardLink: {
    textDecoration: 'none !important',
    width: '100%',
  },
  item: {
    border: '1px solid white',
    borderBottom: '2px solid white',
    borderRadius: '0px',
    margin: '.8rem',
    display: 'flex',

    '&:hover': {
      border: '1px solid #E3E3E3',
      borderBottom: '2px solid rgba(0,0,0,0.64)',
    },

    '& button:hover > span': {
      display: 'none'
    }
  },
  media: {
    height: '100%',
    objectFit: 'contain',
  },
  placeholder: {
    width: '100%',
    height: '150px',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    textAlign: 'center',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    padding: '0 0.2rem .5rem',
  },
  extraLabel: {
    textAlign: 'left',
    fontSize: '1.6rem',
    lineHeight: '1.8rem',
    padding: '.5rem 0.2rem 0.2rem',
    fontWeight: 'bold'
  }
}));

export interface IDocumentItemProps {
  slug: string | number;
  icon?: string;
  label: string;
  index: number;
  url?: string;
}

export function DocumentItem(props: IDocumentItemProps) {
  const { url, label, icon } = props;
  const classes = itemStyles();

  return (
    <a href={url || '#'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Card className={classes.item}>
        <CardActionArea>
          { icon ? (
            <div className={classes.placeholder}><CardMedia
              className={classes.media}
              component="img"
              image={icon}
              title={label}
            />
            </div>
          ) : (
            <div className={classes.placeholder}>
              <LocalOfferRounded style={{ fontSize: '4rem', opacity: '0.5' }} />
            </div>
          )}
          <CardContent>
            <Typography variant="h6" component="h2" className={classes.label}>
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
}

DocumentItem.contextTypes = { ...AppContext };
