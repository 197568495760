import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type ValueOrUpdater<T> = ((previous: T) => T) | T;

const listenersByKey: Partial<Record<string, Array<(newValue: any) => void>>> = {};

function addListener(key: string, listener: (newValue: any) => void) {
  const listeners = listenersByKey[key] ?? [];
  const indexOf = listeners.indexOf(listener);
  if (indexOf === -1) {
    listeners.push(listener);
  }
  listenersByKey[key] = listeners;
}
function removeListener(key: string, listener: (newValue: any) => void) {
  const listeners = listenersByKey[key];
  if (listeners) {
    const indexOf = listeners.indexOf(listener);
    if (indexOf !== -1) {
      listeners.splice(indexOf, 1);
    }
  }
}

function dispatchChangeEvent<T>(key: string, newValue: T) {
  const listeners = [...listenersByKey[key] ?? []];
  for (const l of listeners) {
    l(newValue);
  }
}

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): [T, (ValueOrUpdater: ValueOrUpdater<T>) => void] {
  const [state, _setState] = useState(() => {
    const serialized = localStorage.getItem(key);
    if (serialized === null) {
      return defaultValue;
    }
    try {
      return JSON.parse(serialized);
    } catch (err) {
      console.warn(`unable to deserialize local storage value for key "${key}", using defaultValue as fallback`, err);
      return defaultValue;
    }
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const setState = useCallback((valueOrUpdater: ValueOrUpdater<T>) => {
    const next = typeof valueOrUpdater === 'function'
      ? (valueOrUpdater as (previous: T) => T)(stateRef.current)
      : valueOrUpdater;
    _setState(next);
    localStorage.setItem(key, JSON.stringify(next));
    dispatchChangeEvent(key, next);
  }, [key]);

  useEffect(() => {
    addListener(key, _setState);
    return () => removeListener(key, _setState);
  }, [key]);

  return [state, setState];
}
