import React, { ReactNode, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Hidden, Theme, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { AppContext, IAppContext } from '../../containers/App';
import { ICartArticle, IOrderArticle } from '../../state/data-types';
import { priceToString } from '../../globals/localization';
import { sortByOutOfCatalogLast } from '../../utils/orders/sort';
import { partitionOutAndInCatalog } from '../../utils/orders/partition';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    cursor: 'pointer'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      }
    }
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
}));

export interface IOrderProps<T extends ICartArticle | IOrderArticle> {
  title?: ReactNode;
  showPrices: boolean;
  articles: T[];
  onClose: () => void;
  onConfirm: (orders: T[]) => void;
}

export function AddOrderToCartDialog<T extends ICartArticle | IOrderArticle>(props: IOrderProps<T>, context: IAppContext) {
  const { articles, onClose, onConfirm, showPrices, title } = props;
  const classes = useStyles();

  const items = useMemo(() => sortByOutOfCatalogLast(articles), [articles]);
  const { articlesInCatalog, articlesOutOfCatalog } = useMemo(() => partitionOutAndInCatalog(items), [items]);

  const [selectedArticles, chooseArticles] = React.useState(items.map((a) => a.code));

  const toggleArticle = (code: string) => {
    const newSelection = selectedArticles.filter((a) => a !== code);
    if (!selectedArticles.includes(code)) {
      chooseArticles([...newSelection, code]);
    } else {
      chooseArticles(newSelection);
    }
  };

  const handleChange = (code: string) => () => toggleArticle(code);

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      chooseArticles(items.map((a) => a.code));
    } else {
      chooseArticles([]);
    }
  };

  const { lang } = context;

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title ?? lang('user.AddOrderToCartDialog.orderAgain')}</DialogTitle>
      <DialogContent>
        <Hidden smDown>
          <Grid container>
            <Grid item xs={2} className={classes.label}>
              <Checkbox
                checked={selectedArticles.length > 0}
                indeterminate={selectedArticles.length > 0 && selectedArticles.length !== items.length}
                onChange={selectAll}
              />
            </Grid>
            <Grid item xs={2} className={classes.label}>
              {lang('user.AddOrderToCartDialog.quantity')}
            </Grid>
            <Grid item xs={showPrices ? 6 : 8} className={classes.label}>
              {lang('user.AddOrderToCartDialog.product')}
            </Grid>
            {showPrices
            && (
            <Grid item xs={2} className={classes.label}>
              {lang('user.AddOrderToCartDialog.price')}
            </Grid>
            )}
          </Grid>
        </Hidden>
        {
          articlesInCatalog.map((item, index) => (
            <ItemRow
              key={index + item.code}
              item={item}
              index={index}
              selected={selectedArticles.includes(item.code)}
              onToggle={handleChange(item.code)}
              showPrices={showPrices}
            />
          ))
        }
        {articlesOutOfCatalog.length > 0 && (
        <>
          <div style={{ padding: '1rem 0 0.5rem', fontWeight: 'bolder' }}>
            {lang('user.AddOrderToCartDialog.outOfCatalogItems')}
          </div>
          {
          articlesOutOfCatalog.map((item, index) => (
            <ItemRow
              key={index + item.code}
              item={item}
              index={index}
              selected={selectedArticles.includes(item.code)}
              onToggle={handleChange(item.code)}
              showPrices={showPrices}
            />
          ))
        }
        </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {lang('user.AddOrderToCartDialog.cancel')}
        </Button>
        <Button variant="contained" onClick={() => onConfirm(items.filter((ar) => selectedArticles.includes(ar.code)))} color="primary">
          {lang('user.AddOrderToCartDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type ItemRowProps<T extends IOrderArticle | ICartArticle> = {
  item: T;
  selected: boolean;
  onToggle(): void;
  index: number;
  showPrices: boolean;
}

function ItemRow<T extends IOrderArticle | ICartArticle>({ item, selected, onToggle, index, showPrices }: ItemRowProps<T>, context: IAppContext) {
  const classes = useStyles();
  const { lang } = context;
  return (
    <Grid container className={`${classes.container} ${index % 2 !== 0 ? classes.even : ''}`} key={index} onClick={() => { if (item.code !== null) onToggle(); }}>
      <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Checkbox
          checked={selected}
          onChange={onToggle}
          disabled={item.code === null}
        />
      </Grid>
      <Grid item xs={2} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.AddOrderToCartDialog.quantity')}</span>
        </Hidden>
        {item.quantity}
      </Grid>
      <Grid item xs={showPrices ? 6 : 8} className={classes.label} style={{ fontWeight: 600, textAlign: 'left' }}>
        <Hidden mdUp>
          <span>{lang('user.AddOrderToCartDialog.product')}</span>
        </Hidden>
        {`${item.code !== null && item.code !== item.description ? `[${item.code}] ` : ''}${item.description}`}
      </Grid>
      {showPrices
    && (
    <Grid item xs={2} className={classes.label}>
      <Hidden mdUp>
        <span>{lang('user.AddOrderToCartDialog.price')}</span>
      </Hidden>
      {priceToString('€', (item.totalPrice ?? (item.price ? item.quantity * item.price : undefined)))}
    </Grid>
    )}
    </Grid>
  );
}

ItemRow.contextTypes = { ...AppContext };

AddOrderToCartDialog.contextTypes = { ...AppContext };
