import JsPDF, { TextOptionsLight } from 'jspdf';
import { IAgent, ICartArticle, IDeliveryPoint } from '../state/data-types';
import { priceToString } from '../globals/localization';
import { chunk } from './collections';

export type DownloadPdfParams = {
  selectedOffice?: IDeliveryPoint;
  lang: (str: string, params?: Record<string, string | number>) => string;
  agent?: IAgent;
  type: 'cart'|'quote';
  orderNumber?: string;
  note?: string;
  showPrices: boolean;
  totaleImponibile?: number;
  totaleImposta?: number;
  total?: number;
  coupon?: string;
  esitoCoupon?: string;
  totaleMerce?: number;
  totaleSconto?: number;
  items: Array<ICartArticle>;
}

export function downloadPdf({
  lang, selectedOffice, agent,
  type, orderNumber, note = '', showPrices,
  total, totaleImponibile, totaleImposta, coupon, esitoCoupon, totaleMerce, totaleSconto,
  items
}: DownloadPdfParams) {
  const doc = new JsPDF({
    orientation: 'portrait',
    format: 'a4',
    unit: 'pt',
  });
  const a4Width = 595;
  const a4Height = 842;
  const horizontalMargin = 16;
  const verticalMargin = 16;
  // eslint-disable-next-line import/no-webpack-loader-syntax
  const robotoBase64 = require('!url-loader!../fonts/Roboto-Regular.ttf');
  // eslint-disable-next-line import/no-webpack-loader-syntax
  const robotoBoldBase64 = require('!url-loader!../fonts/Roboto-Bold.ttf');
  doc.addFileToVFS('Roboto.ttf', robotoBase64.substring(robotoBase64.indexOf(',') + 1));
  doc.addFileToVFS('Roboto-Bold.ttf', robotoBoldBase64.substring(robotoBoldBase64.indexOf(',') + 1));
  doc.addFont('Roboto.ttf', 'Roboto', 'normal', 'regular');
  doc.addFont('Roboto-Bold.ttf', 'Roboto', 'normal', 'bold');
  const fontSize = 13;
  const logoAspectRatio = 826 / 150;
  let x = horizontalMargin;
  let y = verticalMargin;

  const marginBetweenCards = 20;
  const cardWidth = (a4Width - horizontalMargin * 2 - marginBetweenCards / 2) / 2 - marginBetweenCards / 2;
  const cardHorizontalPadding = 15;

  function putTextReturnHeight(text: string, textX: number, textY: number, options?: TextOptionsLight): number {
    doc.text(text, textX, textY, options);
    const { h } = doc.getTextDimensions(text, options);
    return h;
  }

  function drawHeader() {
    x = horizontalMargin;
    y = verticalMargin;
    doc.addImage(require('../images/logo.png'), x, y, logoAspectRatio * fontSize * 2, fontSize * 2);
    x += 15 + logoAspectRatio * fontSize * 2;
    doc.setFontSize(fontSize).setFont('Roboto', 'normal', 'bold');
    y += fontSize * (3 / 2);

    x = horizontalMargin;
    y += fontSize * 2;

    const cardsStartY = y;

    x += cardHorizontalPadding;
    y += cardHorizontalPadding + fontSize / 2;

    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#a3a3a3');
    doc.text(lang('user.Checkout.agentNumber'), x, y);
    y += fontSize * 1.2;
    doc.setFont('Roboto', 'normal', 'bold').setTextColor('#000000');
    y += putTextReturnHeight(`${selectedOffice?.agentCode ?? '--'} - ${selectedOffice?.agentName ?? '--'}`, x, y, {
      maxWidth: cardWidth - cardHorizontalPadding * 2,
    });

    y += fontSize;
    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#a3a3a3');
    doc.text(lang('user.Checkout.orderOnBehalfOf'), x, y);
    y += fontSize * 1.2;
    doc.setFont('Roboto', 'normal', 'bold').setTextColor('#000000');
    y += putTextReturnHeight(agent?.selectedClient?.name ?? '-', x, y, {
      maxWidth: cardWidth - cardHorizontalPadding * 2,
    });
    y += fontSize;
    doc.setFont('Roboto', 'normal', 'bold').setTextColor('#000000');
    doc.text(lang('user.Checkout.shipping'), x, y);
    y += fontSize * 1.2;
    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#000000').setFontSize(fontSize * 0.8);
    y += putTextReturnHeight(
      !selectedOffice
        ? '--'
        : [
          `${selectedOffice.name} ${selectedOffice.isMainAddress ? ' (principale)' : ''}`,
          `${selectedOffice.address}${selectedOffice.streetNumber ? `, ${selectedOffice.streetNumber}` : ''}`,
          `${selectedOffice.addressExtra}`,
          `${selectedOffice.cap} ${selectedOffice.city} (${selectedOffice.province}), ${selectedOffice.state}`,
          `${lang('user.Checkout.agentRow', { agent: selectedOffice.agentName || '', code: selectedOffice.agentCode || '' })}`,
          `${lang('user.Checkout.mode', { mode: selectedOffice.modalita || '--' })}`,
          `${lang('user.Checkout.carrier', { carrier: selectedOffice.vettore || '--' })}`,
          `${lang('user.Checkout.port', { port: selectedOffice.porto || '--' })}`,
          `${lang('user.Checkout.payment', { payment: selectedOffice.pagamento || '--' })}`,
        ].filter(Boolean).join('\n'),
      x,
      y,
      { maxWidth: cardWidth - cardHorizontalPadding * 2 },
    );
    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#000000').setFontSize(fontSize);

    y += fontSize * 1.2;
    doc.setFillColor('#dedede');
    doc.roundedRect(x, y, cardWidth - cardHorizontalPadding * 2, 50, 8, 8, 'F');
    y += 50 + fontSize * 1.2;

    doc.setDrawColor('#a3a3a3');
    doc.roundedRect(horizontalMargin, cardsStartY, cardWidth, y - cardsStartY, 8, 8, 'S');

    const leftBlockEndY = y + 8;

    y = cardsStartY;
    x = a4Width - cardWidth - horizontalMargin;
    x += cardHorizontalPadding;
    y += cardHorizontalPadding + fontSize / 2;

    doc.setFont('Roboto', 'normal', 'bold').setTextColor('#000000');
    switch (type) {
      case 'quote':
        doc.text(lang('user.Checkout.quote'), x, y);
        break;
      case 'cart':
      default:
        doc.text(lang('user.Checkout.orderNumber'), x, y);
        break;
    }
    y += fontSize * 1.2;
    doc.setFillColor('#dedede');
    doc.roundedRect(x, y, cardWidth - cardHorizontalPadding * 2, fontSize * 1.2 * 2, 8, 8, 'F');
    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#000000');
    doc.text(orderNumber ?? '', x + 8, y + fontSize * 1.2, {
      baseline: 'middle',
    });
    y += fontSize * 1.2 * 2;
    y += fontSize * 1.2;
    y += fontSize * 1.2;
    doc.setFont('Roboto', 'normal', 'bold').setTextColor('#000000');
    doc.text(lang('user.Checkout.notes'), x, y);
    y += fontSize * 1.2;
    const { h: notesHeight } = doc.getTextDimensions(note, {
      maxWidth: cardWidth - cardHorizontalPadding * 2 - 8,
    });
    const notesBoxHeight = Math.max(50, notesHeight + fontSize * 1.2);
    doc.setFillColor('#dedede');
    doc.roundedRect(x, y, cardWidth - cardHorizontalPadding * 2, notesBoxHeight, 8, 8, 'F');
    doc.setFont('Roboto', 'normal', 'regular').setTextColor('#000000');
    doc.text(note, x + 8, y + fontSize * 1.2, {
      maxWidth: cardWidth - cardHorizontalPadding * 2 - 8,
    });
    y += notesBoxHeight + fontSize * 1.2;
    doc.setDrawColor('#a3a3a3');
    doc.roundedRect(x - cardHorizontalPadding, cardsStartY, cardWidth, y - cardsStartY, 8, 8, 'S');

    y += 8 + fontSize * 2;

    if (showPrices) {
      x = a4Width - horizontalMargin;
      doc.setFont('Roboto', 'normal', 'bold').setFontSize(fontSize).setTextColor('#000000');
      const totalTexts = [
        totaleImponibile && [lang('user.Checkout.totalTaxable'),
          priceToString('€', totaleImponibile)],
        totaleImposta && [lang('user.Checkout.totalTaxes'),
          priceToString('€', totaleImposta)],
        [lang('user.Checkout.totalOrder'),
          priceToString('€', total || null)],
      ].filter(Boolean) as [string, string][];
      if (coupon && totaleSconto && totaleMerce && esitoCoupon) {
        totalTexts.unshift(
          [lang('user.Checkout.totalGoods'),
            priceToString('€', totaleMerce || null)],
          [lang('user.Checkout.coupon'),
            coupon],
          [esitoCoupon,
            priceToString('€', totaleSconto || null)],
        );
      }
      const maxTotalValuesWidth = Math.max(...totalTexts.map(([, text]) => doc.getTextWidth(text)));
      putTextReturnHeight(totalTexts.map(([text]) => text).join('\n'), x - maxTotalValuesWidth - 10, y, { align: 'right', maxWidth: 300 });
      y += fontSize + putTextReturnHeight(totalTexts.map(([, text]) => text).join('\n'), x, y, { align: 'right', maxWidth: 300 });
      doc.setFont('Roboto', 'normal', 'bold').setFontSize(fontSize).setTextColor('#f7b000');
      if (type === 'cart') {
        y += putTextReturnHeight(lang('user.Checkout.disclaimer'), x, y, { align: 'right', maxWidth: 230 });
      }
    }

    const rightBlockEndY = y;

    x = horizontalMargin;
    const productsStartY = Math.max(leftBlockEndY, rightBlockEndY) + fontSize * 2;
    y = productsStartY;
  }

  drawHeader();

  const productsStartY = y;

  const columns: Array<{
    getValue(item: typeof items[number]): string;
    secondaryValue?(item: typeof items[number]): string;
    width: number;
    hpadding: number;
    align: 'left' | 'right';
    title: string;
    fontSize?: number;
  }> = [{
    title: lang('user.Checkout.quantity'),
    width: 40,
    hpadding: 0,
    align: 'left',
    getValue({ quantity }): string {
      return `${quantity}`;
    },
  }, {
    title: lang('user.Checkout.product'),
    width: 180,
    hpadding: 10,
    fontSize: fontSize * 0.6,
    align: 'left',
    getValue({ code }): string {
      return code;
    },
    secondaryValue({ id }) {
      if (!id) {
        return lang('user.Checkout.outOfCatalogCode');
      }
      return '';
    },
  }, {
    title: lang('user.Checkout.description'),
    width: 140,
    hpadding: 5,
    fontSize: fontSize * 0.6,
    align: 'left',
    getValue({ description }): string {
      if (!description) {
        return '--';
      }
      const maxLength = 60;
      if (description.length > maxLength) {
        return `${description.substring(0, maxLength)}...`;
      }
      return description;
    },
  }, {
    title: lang('user.Checkout.price'),
    width: 60,
    fontSize: fontSize * 0.6,
    hpadding: 5,
    align: 'right',
    getValue({ price }): string {
      return priceToString('€', showPrices ? price : null, lang('global.priceUponRequest'));
    },
  }, {
    title: lang('user.Checkout.discount'),
    width: 50,
    hpadding: 5,
    align: 'right',
    fontSize: (fontSize / 3) * 2,
    getValue({ discountOne, discountTwo, discountThree, discountFour }): string {
      return !showPrices ? '' : chunk([discountOne, discountTwo, discountThree, discountFour].filter((d) => d !== null && d !== undefined && d !== 0), 2).map(chnk => chnk.map((d) => `${d}%`).join(' ')).join('\n');
    }
  }, {
    title: lang('user.Checkout.total'),
    width: 100,
    hpadding: 5,
    align: 'right',
    getValue({ totalPrice }): string {
      if (totalPrice && showPrices) {
        return priceToString('€', totalPrice || null);
      }
      return '--';
    },
    secondaryValue({ available, quantity }) {
      if (available !== undefined && quantity !== undefined && quantity > available) {
        if (available === 1) {
          return lang('user.Checkout.onlyOneAvailable', { quantity });
        } if (available > 0) {
          return lang('user.Checkout.partiallyAvailable', { available, quantity });
        }
        return lang('user.Checkout.notAvailable');
      }
      return '';
    },
  }];

  function drawTableHeader() {
    doc.setFont('Roboto', 'normal', 'bold').setFontSize(fontSize * 0.8).setTextColor('#a3a3a3');
    // eslint-disable-next-line no-restricted-syntax
    for (const column of columns) {
      doc.text(column.title, column.align === 'left' ? x + column.hpadding / 2 : x + column.width - column.hpadding / 2, productsStartY, {
        maxWidth: column.width - column.hpadding,
        align: column.align,
      });
      x += column.width;
    }

    y += fontSize * 2;
  }

  drawTableHeader();

  doc.setFont('Roboto', 'normal', 'regular').setFontSize(fontSize).setTextColor('#000000');
  for (const item of items) {
    let hasSecondaryValues = false;
    x = horizontalMargin;
    for (const column of columns) {
      doc.setFont('Roboto', 'normal', 'regular').setFontSize(column.fontSize ?? fontSize).setTextColor('#000000');
      doc.text(column.getValue(item), column.align === 'left' ? x + column.hpadding / 2 : x + column.width - column.hpadding / 2, y - fontSize + (column.fontSize ?? fontSize), {
        maxWidth: column.width - column.hpadding,
        align: column.align,
      });
      if (column.secondaryValue) {
        const secondaryValue = column.secondaryValue(item);
        if (secondaryValue) {
          hasSecondaryValues = true;
          doc.setFont('Roboto', 'normal', 'regular').setFontSize(fontSize / 2).setTextColor('#000000');
          doc.text(secondaryValue, column.align === 'left' ? x + column.hpadding / 2 : x + column.width - column.hpadding / 2, y + (column.fontSize ?? fontSize) * 1.2, {
            maxWidth: column.width - column.hpadding,
            align: column.align,
          });
        }
      }
      x += column.width;
    }
    y += fontSize * 2.1 + (hasSecondaryValues ? fontSize : 0);
    if (y > a4Height - verticalMargin) {
      doc.addPage();
      y = verticalMargin;
      y += fontSize * 1.8;
      drawHeader();
      drawTableHeader();
    }
  }

  doc.save(`Carrello_${new Date().toLocaleString()}.pdf`);
}
