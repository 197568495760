import React, { CSSProperties, ReactNode } from 'react';

export type AspectRatioProps = {
  aspectRatio: number;
  className?: string;
  style?: CSSProperties;
  overflow?: 'hidden' | 'visible';
  children?: ReactNode;
}

export function AspectRatio({
  aspectRatio,
  className,
  style,
  overflow = 'hidden',
  children
}: AspectRatioProps): JSX.Element {
  return (
    <div
      className={className}
      style={{
        width: '100%', display: 'block', ...style }}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 0,
          width: '100%',
          display: 'block',
          overflow,
          paddingBottom: `${100 / aspectRatio}%`,
        }}
      >
        <div style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
