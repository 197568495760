import _ from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import * as XLSX from 'xlsx';

import {
  Box, Button, Card, CircularProgress, Collapse, Container, Dialog, DialogActions, DialogContent,
  DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Hidden, IconButton,
  Input,
  InputAdornment, InputBase, OutlinedInput, Radio, RadioGroup, TextField, Theme, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  AddCircleOutlineRounded, CloseRounded, RemoveCircleOutlineRounded, SearchRounded,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';
import { AppContext, IAppContext } from '../../containers/App';
import {
  ICart, ICartArticle, IDeliveryPoint, ISession, Role,
} from '../../state/data-types';
import OfficeCard from './OfficeCard';
import { isBlank } from '../../state/utils';
import themeStyle, { palette } from '../../styles/theme.style';
import initialState from '../../state/initial-state';
import { priceToString } from '../../globals/localization';
import { useDebouncedSearch } from '../../utils/use-debounced';
import { sortByOutOfCatalogLast } from '../../utils/orders/sort';
import { partitionOutAndInCatalog } from '../../utils/orders/partition';
import { downloadPdf } from '../../utils/order-pdf';
import { throwIfErrorResponse } from '../../utils/api';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '2rem',
  },
  danger: {
    fontSize: '.95rem !important',
    lineHeight: '1rem !important',
    fontWeight: 600,
    color: `${theme.palette.error.main} !important`,
  },
  productHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem 2rem 0',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0',
    },
  },
  tableHeader: {
    padding: '1rem 2rem 0rem',
    position: 'relative',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
  },
  productContainer: {
    borderRadius: '3px',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 1.5rem',

    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  header: {
    fontWeight: 'bold',
    fontSize: '.9rem !important',
    color: '#444444',
    textTransform: 'uppercase',
  },
  total: {
    fontWeight: 'bold',
    fontSize: '1.8rem !important',
    marginTop: '.2rem',
  },
  warning: {
    width: '100%',
    marginTop: '.2rem',
    marginBottom: '0rem',
    fontSize: '1rem !important',
    lineHeight: '1rem !important',
    fontWeight: 600,
    color: `${theme.palette.warning.dark} !important`,
    // whiteSpace: 'pre',
  },
  searchStickyBlock: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  searchContainer: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 0 0 1rem',
    backgroundColor: '#ececec',
    borderRadius: '4px',
    minWidth: '100%',
    minHeight: '3rem',
  },
  shippingList: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
  }
}));

export interface ICheckoutProps {
  session: ISession;
  items: Array<ICartArticle>;
  loadingUser: boolean;
  loadingOrders: boolean;
  orderChecked: boolean;
  showPrices: boolean;
}

export interface ICheckoutState {
  officeId?: number;
  officeCode?: string;
}

export default function Checkout(props: ICheckoutProps, context: IAppContext) {
  const { dispatch, push, lang } = context;
  const {
    session, loadingUser, loadingOrders, items: unorderedItems, showPrices, orderChecked
  } = props;
  const { user } = session;
  const classes = useStyles();
  const items = useMemo(() => sortByOutOfCatalogLast(unorderedItems), [unorderedItems]);
  const { articlesInCatalog, articlesOutOfCatalog } = useMemo(() => partitionOutAndInCatalog(items), [items]);

  React.useEffect(() => {
    context.actions.session.getUser();
  }, []);

  const [state, setState] = React.useState<ICheckoutState>({});
  const [note, setNote] = React.useState<string>('');
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };
  const [coupon, setCoupon] = useState('');
  const [couponBeforeBlur, setCouponBeforeBlur] = useState('');
  useEffect(() => {
    setCoupon(session.cart.coupon ?? '');
  }, [session.cart.coupon, setCoupon]);
  useEffect(() => {
    setNote(cur => cur || session.cart.note || '');
  }, [session.cart.note, setNote]);

  function markOrderAsUnchecked() {
    context.actions.session.orderUpdated(session.cart);
  }

  const [quantityOption, setQuantityOption] = React.useState<string>('');

  const [orderNumber, setOrderNumber] = React.useState('');
  const handleOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderNumber(event.target.value);
  };

  const [dataEvasione, setDataEvasione] = React.useState('');
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataEvasione(event.target.value);
  };

  React.useEffect(() => {
    if (user !== undefined) {
      let mainOffice = _.find(user.offices, (office: IDeliveryPoint) => office.isMainAddress);
      if (!mainOffice) {
        const [of] = user.offices;
        mainOffice = of;
      }
      if (mainOffice) {
        setState({
          ...state,
          officeId: mainOffice.id,
          officeCode: mainOffice.code,
        });
      }
    }
  }, [user]);

  let total: number | undefined;
  if (session.cart.totaleDocumento) {
    total = session.cart.totaleDocumento;
  } else if (items.length > 0 && items.every((item: ICartArticle) => item.price !== undefined && item.price > 0)) {
    total = items.map((item: ICartArticle) => ((item.price || 0) * (item.quantity || 0))).reduce((t: number, current: number) => (t + current), 0);
  }

  const [isOfficeSeletVisible, showOfficeSelect] = React.useState(false);

  let selectedOffice: IDeliveryPoint | undefined;
  if (user && user.offices.length > 0) {
    selectedOffice = _.find(user.offices, (office: IDeliveryPoint) => (state.officeCode === undefined && office.isMainAddress) || (state.officeCode === office.code));
  }

  const [confirmingOrder, requestOrderQuantityConfirmation] = React.useState(false);

  const chooseQuantityOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantityOption((event.target as HTMLInputElement).value);
  };

  const sendOrder = () => {
    requestOrderQuantityConfirmation(false);
    context.actions.session.sendOrder({
      officeId: state.officeId,
      articles: items.filter((a: ICartArticle) => a.quantity > 0).map((a: ICartArticle) => ({
        articleCode: a.code,
        quantity: quantityOption === 'available' && a.available !== undefined && a.available !== null ? a.available : a.quantity,
      })),
      note,
      progressivoOrdine: orderNumber,
      dataEvasione,
      operation: '1',
      coupon,
    }).then((response: any) => {
      if (!response.error) {
        context.actions.app.enqueueSnackbar({ message: lang('user.Checkout.orderSuccessfullySubmitted'), options: { variant: 'success' } });
        context.dispatch(context.push('/user'));
        context.actions.session.saveCart(initialState.session.cart);
      }
    });
  };

  const requestOrderConfirm = () => {
    if (orderChecked) {
      sendOrder();
    } else {
      context.actions.session.requestOrderAvailability(session.cart, {
        officeId: state.officeId,
        articles: items.filter((a: ICartArticle) => a.quantity > 0).map((a: ICartArticle) => ({
          articleCode: a.code,
          quantity: a.quantity,
        })),
        note,
        progressivoOrdine: orderNumber,
        dataEvasione,
        operation: '0',
        coupon,
      });
    }
  };

  const isAgent = session.role === Role.Agent && selectedOffice !== undefined;

  const { enqueueSnackbar } = useSnackbar();

  const [savingCart, setSavingCart] = useState(false);
  const handleSaveCart = useCallback(async () => {
    setSavingCart(true);
    try {
      throwIfErrorResponse(await context.actions.api.saveQuote({ items, orderChecked: false, totaleDocumento: session.cart.totaleDocumento ?? total, totaleImponibile: session.cart.totaleImponibile, totaleImposta: session.cart.totaleImposta, synced: true, note }));
      enqueueSnackbar(lang('user.Checkout.quoteSaved'), {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(lang('global.anErrorOccurred'), {
        variant: 'error',
      });
    } finally {
      setSavingCart(false);
    }
  }, [context.actions.api, enqueueSnackbar, items, lang, note, session.cart.totaleDocumento, session.cart.totaleImponibile, session.cart.totaleImposta, total]);

  const downloadExcel = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(items.map((a, idx) => ({
      [lang('user.Checkout.client')]: idx === 0 ? (user?.name ?? '') : '',
      [lang('user.Checkout.vatNumber')]: idx === 0 ? (user?.piva ?? '') : '',
      [lang('user.Checkout.agentName')]: session.role === Role.Agent && selectedOffice !== undefined && idx === 0 ? (selectedOffice?.agentName ?? '') : '',
      [lang('user.Checkout.agentCode')]: session.role === Role.Agent && selectedOffice !== undefined && idx === 0 ? (selectedOffice?.agentCode ?? '') : '',
      ' ': '',
      [lang('user.Checkout.code')]: a.code,
      [lang('user.Checkout.description')]: a.description,
      [lang('user.Checkout.price')]: showPrices
        ? Number(a.price ?? 0) === 0
          ? lang('global.priceUponRequest')
          : `€${a.price ?? 0}`
        : '--',
      [lang('user.Checkout.discount')]: (showPrices && [a.discountOne, a.discountTwo, a.discountThree, a.discountFour].filter((d) => d !== null && d !== undefined && d !== 0).map((d) => `${d}%`).join(' ')) || '',
      ...isAgent ? {
        [lang('user.Checkout.promo')]: a.promo ?? ''
      } : {},
      [lang('user.Checkout.quantity')]: a.quantity,
      [lang('user.Checkout.total')]: showPrices && a.price ? `€${a.totalPrice ?? a.price * a.quantity}` : '--',
      [lang('user.Checkout.outOfCatalogCode')]: a.id ? lang('user.Checkout.no') : lang('user.Checkout.yes'),
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio1');
    XLSX.writeFile(workbook, `Carrello_${(new Date()).toLocaleString()}.xlsx`);
  }, [isAgent, items, lang, selectedOffice, session.role, showPrices, user?.name, user?.piva]);

  useEffect(() => {
    requestOrderQuantityConfirmation(orderChecked && !items.every((item: ICartArticle) => item.status && (item.available || -1) >= item.quantity));
  }, [items]);

  // Agent
  const client = session.agent?.selectedClient;

  const { debouncedNormalizedQuery: normalizedSearchShippingAddress, query: searchShippingAddress, setQuery: setSearchShippingAddress } = useDebouncedSearch('');
  const handleSearchShippingAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchShippingAddress(e.target.value);
  }, [setSearchShippingAddress]);
  const searchShippingAddressRef = useRef<HTMLInputElement | null>(null);
  const filteredShippingAddresses = useMemo(() => _.uniqBy(user?.offices, 'id').filter((office) => (
    `${office.name} ${office.address}, ${office.streetNumber} ${office.addressExtra} ${office.cap} ${office.city} (${office.province}), ${office.state} ${office.agentName || ''} (${office.agentCode}) ${office.vettore ?? ''} ${office.porto ?? ''} ${office.pagamento ?? ''}`
      .toLowerCase().includes(normalizedSearchShippingAddress)
  )), [normalizedSearchShippingAddress, user?.offices]);

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.Checkout.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.Checkout.title')}
        </h1>
      </div>
      <Container className="content" maxWidth={false} style={{ maxWidth: '95%' }}>
        <Hidden xsUp={!loadingUser || items.length !== 0}>
          <div style={{
            display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%',
          }}
          >
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loadingUser}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <Box boxShadow={1} className={classes.productContainer}>
                <Hidden xsUp={items.length === 0}>
                  <Hidden smDown>
                    <Grid container className={classes.tableHeader}>
                      <Grid item xs={2} className={classes.label} />
                      <Grid item xs={showPrices ? 3 : 7} className={classes.label}>
                        {lang('user.Checkout.product')}
                      </Grid>
                      {showPrices && (
                        <>
                          <Grid item xs={2} className={classes.label}>
                            {lang('user.Checkout.price')}
                          </Grid>
                          <Grid item xs={2} className={classes.label}>
                            {lang('user.Checkout.total')}
                          </Grid>
                        </>
                      )}
                      <Grid item xs={3} className={`${classes.label} end`} />
                    </Grid>
                  </Hidden>
                </Hidden>
                {
                  articlesInCatalog.map((item: ICartArticle, index: number) => <CheckoutItem orderChecked={orderChecked} isAgent={isAgent} cart={session.cart} item={item} index={index} key={`checkout_${index}`} loadingOrders={loadingOrders} showPrices={showPrices} />)
                }
                <div style={{ padding: '2rem 0', width: '100%' }}>
                  <OutOfCatalogAddCheckoutItem cart={session.cart} />
                </div>
                {
                  articlesOutOfCatalog.map((item: ICartArticle, index: number) => <CheckoutItem orderChecked={orderChecked} isAgent={isAgent} cart={session.cart} item={item} index={index} key={`checkout_${index}`} loadingOrders={loadingOrders} showPrices={showPrices} />)
                }
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                boxShadow={1}
                style={{
                  padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                }}
              >
                <Hidden xsUp={session.role !== Role.Client}>
                  <Typography variant="h4">
                    {user?.name}
                  </Typography>
                  <Typography variant="overline">
                    {lang('user.Checkout.vatNumber_var', { vatNumber: user?.piva ?? '--' })}
                  </Typography>
                </Hidden>
                <Hidden xsUp={session.role !== Role.Agent}>
                  <Hidden xsUp={selectedOffice === undefined}>
                    <Typography variant="body2">
                      {lang('user.Checkout.agent', { agent: selectedOffice?.agentCode || '' })}
                    </Typography>
                    <Typography variant="h4" style={{ fontSize: '1.6rem', marginBottom: '1.6rem' }}>
                      {selectedOffice?.agentName || ''}
                    </Typography>
                  </Hidden>
                  <Typography variant="body1" style={{ fontSize: '1rem', opacity: 0.7, textTransform: 'none' }}>
                    {lang('user.Checkout.orderWillBeSubmittedOnBehalfOf')}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '1.2rem', fontWeight: 600, textTransform: 'none', marginBottom: '1.5rem',
                    }}
                  >
                    {` ${client?.name}`}
                  </Typography>
                </Hidden>
                <Typography className={classes.header} style={{ marginTop: '1rem' }}>
                  {lang('user.Checkout.shippingAddress')}
                </Typography>
                {
                  user && user.offices.length > 0 ? (
                    <div style={{
                      display: 'flex', flexDirection: 'column', width: '100%', marginTop: '.5rem', alignItems: 'flex-start',
                    }}
                    >
                      <div style={{ width: '100%' }}>
                        {
                          selectedOffice ? (
                            <OfficeCard
                              office={selectedOffice}
                              dense
                            />
                          ) : (
                            <Typography variant="body1" style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                              {lang('user.Checkout.noOfficeSelected')}
                            </Typography>
                          )
                        }
                      </div>
                      <Button
                        variant="text"
                        color="primary"
                        style={{
                          width: 'auto',
                          fontWeight: 600,
                          textTransform: 'uppercase',
                          marginTop: '0.2rem',
                          color: palette.text.dark,
                        }}
                        disableRipple
                        disableElevation
                        fullWidth
                        onClick={() => { showOfficeSelect(true); }}
                        disabled={loadingOrders}
                      >
                        {lang('user.Checkout.changeAddress')}
                      </Button>
                      <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={isOfficeSeletVisible}
                        onClose={() => { showOfficeSelect(false); }}
                      >
                        <DialogTitle id="confirmation-dialog-title">{lang('user.Checkout.shippingAddress')}</DialogTitle>
                        <DialogContent dividers style={{ padding: 0 }}>
                          <Box boxShadow={1} className={classes.searchStickyBlock}>
                            <Card className={classes.searchContainer}>
                              <InputBase
                                placeholder={lang('user.Checkout.searchDotDotDot')}
                                onChange={handleSearchShippingAddressChange}
                                value={searchShippingAddress}
                                style={{ flex: 1 }}
                                autoFocus
                                inputRef={searchShippingAddressRef}
                              />
                              {searchShippingAddress.length === 0
                                ? (
                                  <IconButton onClick={() => searchShippingAddressRef.current?.focus()}>
                                    <SearchRounded color="disabled" />
                                  </IconButton>
                                )
                                : (
                                  <IconButton onClick={() => setSearchShippingAddress('')}>
                                    <CloseRounded color="disabled" />
                                  </IconButton>
                                )}
                            </Card>
                          </Box>
                          <div className={classes.shippingList}>
                            {
                              filteredShippingAddresses.length === 0
                                ? (
                                  <div style={{
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    margin: '1rem 0'
                                  }}
                                  >{lang('user.Checkout.noShippingAddressFound')}
                                  </div>
                                )
                                : filteredShippingAddresses.map((office: IDeliveryPoint) => (
                                  <div style={{ marginBottom: '1rem', width: '100%' }} key={office.code}>
                                    <OfficeCard
                                      office={office}
                                      selected={selectedOffice !== undefined && office.code === selectedOffice.code}
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          officeCode: office.code,
                                          officeId: office.id,
                                        });
                                        context.actions.session.orderUpdated(session.cart);
                                      }}
                                    />
                                  </div>
                                ))
                            }
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => { showOfficeSelect(false); }} color="primary" style={{ fontWeight: 600 }}>
                            {lang('user.Checkout.ok')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  ) : (
                    <Typography variant="body1" style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                      {lang('user.Checkout.noOfficeSelectedPleaseContactAirex')}
                    </Typography>
                  )
                }
                <Typography className={classes.header} style={{ marginTop: '1rem' }}>
                  {lang('user.Checkout.orderNumberAlt')}
                </Typography>
                <TextField
                  variant="outlined"
                  margin="dense"
                  disabled={loadingOrders}
                  value={orderNumber}
                  onChange={handleOrderNumberChange}
                  inputProps={{
                    onKeyPress: (/* event */) => {
                      // if (!/[a-zA-Z0-9]/.test(event.key)) {
                      //   event.preventDefault();
                      // }
                    },
                  }}
                />
                <Typography className={classes.header} style={{ marginTop: '1rem' }}>
                  {lang('user.Checkout.requestedDeliveryDate')}
                </Typography>
                <TextField
                  variant="outlined"
                  margin="dense"
                  disabled={loadingOrders}
                  type="date"
                  value={dataEvasione}
                  onChange={handleDateChange}
                />
                <Typography className={classes.header} style={{ marginTop: '1rem' }}>
                  {lang('user.Checkout.notesAlt')}
                </Typography>
                <TextField
                  variant="outlined"
                  multiline
                  margin="dense"
                  rowsMax={6}
                  rows={6}
                  disabled={loadingOrders}
                  value={note}
                  onChange={handleNotesChange}
                />

                <div style={{ paddingTop: '6px', textAlign: 'right', width: '100%', color: palette.accent.main, fontWeight: 600 }}>
                  {lang('user.Checkout.minOrderAmount', { min: priceToString('€', 100) })}
                </div>

                <Collapse in={confirmingOrder}>
                  <>
                    <Divider style={{ marginTop: '2rem', marginBottom: '1rem' }} />
                    <Typography className={classes.header} style={{ marginTop: '2rem', color: themeStyle.palette.error.main }}>
                      {lang('user.Checkout.confirmQuantity')}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: '1rem', lineHeight: '1.2rem', marginBottom: '.3rem', color: themeStyle.palette.error.dark,
                      }}
                    >
                      {lang('user.Checkout.someProductsAreMissing')}
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup value={quantityOption} onChange={chooseQuantityOption}>
                        <FormControlLabel value="requested" control={<Radio />} label={lang('user.Checkout.orderRequestedQuantity')} />
                        <FormControlLabel value="available" control={<Radio />} label={lang('user.Checkout.orderOnlyAvailable')} />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: '.9rem', lineHeight: '1.2rem', opacity: 0.7, marginTop: '.5rem',
                      }}
                    >
                      {lang('user.Checkout.confirmationWithDatesWillFollow')}
                    </Typography>
                  </>
                </Collapse>

                {showPrices && (
                  <>
                    {session.role === Role.Client && (
                      <>
                        <div style={{
                          display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem',
                        }}
                        >
                          <Typography variant="subtitle2">
                            {lang('user.Checkout.totalGoods')}
                          </Typography>
                          <Typography variant="subtitle1">
                            {priceToString('€', session.cart.totaleMerce || null)}
                          </Typography>
                        </div>
                        <Typography className={classes.header} style={{ marginTop: '1rem' }}>
                          {lang('user.Checkout.coupon')}
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          disabled={loadingOrders}
                          value={coupon}
                          onChange={e => setCoupon(e.target.value)}
                          onFocus={() => setCouponBeforeBlur(coupon)}
                          onBlur={() => {
                            if (coupon !== couponBeforeBlur) {
                              markOrderAsUnchecked();
                            }
                          }}
                        />
                        {(coupon && session.cart.esitoCoupon) && (
                          <div style={{
                            display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem',
                          }}
                          >
                            <Typography variant="subtitle2">
                              {session.cart.esitoCoupon}
                            </Typography>
                            <Typography variant="subtitle1">
                              {priceToString('€', session.cart.totaleSconto || null)}
                            </Typography>
                          </div>
                        )}
                      </>
                    )}
                    <div style={{
                      display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem',
                    }}
                    >
                      <Typography variant="subtitle2">
                        {lang('user.Checkout.totalTaxableAlt')}
                      </Typography>
                      <Typography variant="subtitle1">
                        {priceToString('€', session.cart.totaleImponibile || null)}
                      </Typography>
                    </div>
                    <div style={{
                      display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                    }}
                    >
                      <Typography variant="subtitle2">
                        {lang('user.Checkout.totalTaxesAlt')}
                      </Typography>
                      <Typography variant="subtitle1">
                        {priceToString('€', session.cart.totaleImposta || null)}
                      </Typography>
                    </div>
                    <Collapse in={!loadingOrders && !orderChecked} style={{ width: '100%', marginTop: '.2rem' }}>
                      <p className={classes.warning}>{lang('user.Checkout.orderDisclaimerAlt')}</p>
                    </Collapse>
                    <div style={{
                      display: 'flex', width: '100%', flexDirection: 'row', alignItems: session.cart.totaleDocumento !== undefined ? 'center' : 'flex-start', justifyContent: 'space-between', marginTop: '1rem',
                    }}
                    >
                      <Typography className={classes.header}>
                        {lang('user.Checkout.totalOrderAlt')}
                      </Typography>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="body1" className={classes.total}>
                          {priceToString('€', total || null)}
                        </Typography>
                        <Hidden xsUp={session.cart.totaleDocumento !== undefined}>
                          <Typography variant="body2" style={{ fontSize: '1rem' }}>
                            {lang('user.Checkout.vatExcluded')}
                          </Typography>
                        </Hidden>
                      </div>
                    </div>
                    <Collapse in={!loadingOrders && orderChecked && session.cart.items.filter((a) => parseFloat(`${a.totalPrice}`) === 0).length > 0} style={{ width: '100%', marginTop: '.2rem' }}>
                      <p className={classes.warning} style={{ marginBottom: '1rem' }}>{lang('user.Checkout.orderDisclaimer')}</p>
                    </Collapse>
                  </>
                )}
                <div style={{ position: 'relative', margin: '1rem 0 0rem', width: '100%' }}>
                  {
                    orderChecked ? (
                      <Button
                        className={(items.length === 0 || loadingOrders || (confirmingOrder && isBlank(quantityOption))) || loadingOrders ? '' : 'animate-glow green-glow'}
                        style={{ fontWeight: 600 }}
                        variant="contained"
                        color="primary"
                        disabled={items.length === 0 || loadingOrders || (confirmingOrder && isBlank(quantityOption))}
                        disableElevation
                        fullWidth
                        onClick={() => { requestOrderConfirm(); }}
                      >
                        {lang('user.Checkout.submitOrder')}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={items.length === 0 || loadingOrders}
                        className={(items.length === 0 || loadingOrders) || loadingOrders ? '' : 'animate-glow green-glow'}
                        style={{ fontWeight: 600 } as CSSProperties}
                        disableElevation
                        fullWidth
                        onClick={() => { requestOrderConfirm(); }}
                      >
                        {lang('user.Checkout.updatePrices')}
                      </Button>
                    )
                  }
                  {loadingOrders && (
                    <div style={{
                      position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                    >
                      <CircularProgress size={20} thickness={6} />
                    </div>
                  )}
                </div>

                <Button
                  variant="text"
                  style={{
                    margin: '1rem 0 0rem', fontWeight: 600, textTransform: 'none', opacity: 0.8,
                  }}
                  disableRipple
                  disableElevation
                  fullWidth
                  onClick={() => downloadExcel()}
                >
                  {lang('user.Checkout.exportXLS')}
                </Button>
                <Button
                  variant="text"
                  style={{
                    margin: '1rem 0 0rem', fontWeight: 600, textTransform: 'none', opacity: 0.8,
                  }}
                  disableRipple
                  disableElevation
                  fullWidth
                  onClick={() => downloadPdf({
                    lang,
                    selectedOffice,
                    agent: session.agent,
                    showPrices,
                    total,
                    totaleImponibile: session.cart.totaleImponibile,
                    totaleImposta: session.cart.totaleImposta,
                    totaleMerce: session.cart.totaleMerce,
                    totaleSconto: session.cart.totaleSconto,
                    coupon: session.cart.coupon,
                    esitoCoupon: session.cart.esitoCoupon,
                    type: 'cart',
                    note,
                    orderNumber,
                    items,
                  })}
                >
                  {lang('user.Checkout.exportPDF')}
                </Button>
                {isAgent && (
                  <Button
                    variant="text"
                    style={{
                      position: 'relative',
                      margin: '1rem 0 0rem',
                      fontWeight: 600,
                      textTransform: 'none',
                      opacity: 0.8,
                    }}
                    disableRipple
                    disableElevation
                    fullWidth
                    disabled={savingCart}
                    onClick={handleSaveCart}
                  >
                    {savingCart
                      ? (
                        <div style={{ position: 'absolute', zIndex: 1, inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CircularProgress size="1.2rem" />
                        </div>
                      )
                      : lang('user.Checkout.saveQuote')}
                  </Button>
                )}
                <Button variant="text" style={{ margin: '.5rem 0 0rem', fontWeight: 600, opacity: 0.8 }} disableRipple disableElevation fullWidth onClick={() => dispatch(push('/'))}>
                  {lang('user.Checkout.continueShopping')}
                </Button>
                <Button
                  variant="text"
                  style={{
                    margin: '.5rem 0 0rem', fontWeight: 600, textTransform: 'none', opacity: 0.8,
                  }}
                  disableRipple
                  disableElevation
                  fullWidth
                  onClick={() => {
                    context.actions.session.clearCart(session.cart);
                    setCoupon('');
                    setNote('');
                  }}
                >
                  {lang('user.Checkout.emptyCart')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </section>
  );
}

Checkout.contextTypes = { ...AppContext };

const itemStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: '.8rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > span': {
      fontWeight: 'bold',
      fontSize: '.8rem',
      color: '#636363',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
      marginTop: '1rem',
    },
    '& a': {
      textDecoration: 'none !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '.9rem !important',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    '&.inner': {
      color: '#636363',
      '& span': {
        color: '#636363',
      },
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
    '&.end': {
      alignItems: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
  },
  innerContainer: {
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '0',
    margin: '1rem 0rem',
  },
  error: {
    width: '100%',
    marginTop: '.5rem',
    fontSize: '.95rem !important',
    lineHeight: '1rem !important',
    fontWeight: 600,
    color: `${theme.palette.error.main} !important`,
  },
  warning: {
    width: '100%',
    marginTop: '.5rem',
    marginBottom: '0rem',
    fontSize: '.9rem !important',
    lineHeight: '1rem !important',
    fontWeight: 600,
    color: `${theme.palette.warning.dark} !important`,
  },
  success: {
    width: '100%',
    marginTop: '.2rem',
    marginBottom: '0rem',
    fontSize: '1rem !important',
    lineHeight: '1rem !important',
    fontWeight: 600,
    color: `${theme.palette.success.dark} !important`,
    // whiteSpace: 'pre',
  },
}));

export interface IOutOfCatalogAddCheckoutItem {
  cart: ICart;
}

function OutOfCatalogAddCheckoutItem({ cart }: IOutOfCatalogAddCheckoutItem, context: IAppContext): JSX.Element {
  const { lang } = context;

  const [quantity, setQuantity] = useState('');
  const [code, setCode] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const [adding, setAdding] = useState(false);
  const handleAdd = useCallback(async (productItemCode: string, productQuantity: string) => {
    try {
      setAdding(true);
      throwIfErrorResponse(await context.actions.session.addArticlesToCart(cart, [{
        pieces: '',
        code: productItemCode,
        quantity: Number(productQuantity),
      }]));
      setQuantity('');
      setCode('');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(lang('global.anErrorOccurred'), {
        variant: 'error',
      });
    } finally {
      setAdding(false);
    }
  }, [cart, context.actions.session, enqueueSnackbar, lang]);

  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  const canAdd = (productCode: string, productQuantity: string) => productCode.length > 0 && productQuantity.length > 0 && !Number.isNaN(Number(productQuantity));

  return (
    <div style={{ opacity: adding ? 0.75 : 1, paddingRight: '1rem', paddingLeft: '1rem', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <FormLabel>{lang('user.Checkout.outOfCatalogCode')}</FormLabel><br />
          <Input disabled={adding} disableUnderline style={{ backgroundColor: '#e6e6e6', paddingLeft: '0.2rem', paddingRight: '0.2rem', width: '100%' }} type="text" value={code} onChange={(e) => setCode(e.target.value)} onBlur={(e) => setCode(e.target.value.trim())} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormLabel>{lang('user.Checkout.quantity')}</FormLabel><br />
          <Input
            inputRef={quantityInputRef}
            disableUnderline
            style={{ backgroundColor: '#e6e6e6', paddingLeft: '0.2rem', paddingRight: '0.2rem', width: '100%' }}
            type="number"
            disabled={adding}
            value={quantity}
            onChange={() => quantityInputRef.current && setQuantity(quantityInputRef.current.value)}
            onBlur={() => quantityInputRef.current && setQuantity(quantityInputRef.current.value.trim())}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && quantityInputRef.current) {
                const newQuantity = quantityInputRef.current.value.trim();
                if (canAdd(code, quantityInputRef.current.value.trim())) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAdd(code, newQuantity);
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormLabel>&nbsp;</FormLabel><br />
          <Button disabled={adding || !canAdd(code, quantity)} style={{ marginTop: -2.5, width: '100%' }} color="primary" variant="contained" onClick={() => handleAdd(code, quantity)}>{lang('user.Checkout.addItem')}</Button>
        </Grid>
      </Grid>
    </div>
  );
}

OutOfCatalogAddCheckoutItem.contextTypes = { ...AppContext };

export interface ICheckoutItemProps {
  cart: ICart;
  item: ICartArticle;
  index: number;
  loadingOrders: boolean;
  showPrices: boolean;
  isAgent: boolean;
  orderChecked: boolean;
}

function CheckoutItem(props: ICheckoutItemProps, context: IAppContext) {
  const {
    item, index, loadingOrders, cart, showPrices, isAgent, orderChecked
  } = props;
  const { preview, code, description } = item;
  const { lang } = context;
  const classes = itemStyles();

  const quantity = item.quantity || 0;

  const updateQuantity = (q: number) => {
    context.actions.session.updateCartArticleQuantity(cart, item, q);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = parseInt(event.target.value, 10);
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    updateQuantity(newQuantity);
  };

  const onAdd = () => {
    const newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (newQuantity % pieces !== 0) {
      updateQuantity(newQuantity + (pieces - (newQuantity % pieces)));
    } else {
      updateQuantity(newQuantity + pieces);
    }
  };

  const onRemove = () => {
    let newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (quantity % pieces !== 0) {
      newQuantity = quantity - (quantity % pieces);
    } else {
      newQuantity = quantity - pieces;
    }
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    updateQuantity(newQuantity);
  };

  const removeArticle = (i: ICartArticle) => {
    context.actions.session.removeArticleFromCart(cart, i);
  };

  const isItemAvailable = !orderChecked || item.available === undefined || item.available === null || item.available > 0;

  let qMessage;
  if (orderChecked) {
    if (item.available === 1) {
      qMessage = lang('user.Checkout.onlyOneAvailable', { quantity: item.quantity });
    } else if (item.available && item.available > 0) {
      qMessage = lang('user.Checkout.partiallyAvailable', { available: item.available, quantity: item.quantity });
    } else {
      qMessage = lang('user.Checkout.notAvailable');
    }
  }

  return (
    <Grid container className={`${classes.container} ${index % 2 !== 0 ? classes.even : ''}`} key={`checkout_${index}`}>
      <Grid
        item
        xs={12}
        md={2}
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', opacity: isItemAvailable ? 1 : 0.3,
        }}
      >
        {preview ? <img src={preview} alt={description} style={{ maxWidth: '5rem', maxHeight: '5rem' }} /> : null}
      </Grid>
      <Grid item xs={12} md={showPrices ? 3 : 7} className={classes.label}>
        <Hidden mdUp>
          <span>{lang('user.Checkout.product')}</span>
        </Hidden>
        <div style={{ fontWeight: 600, opacity: isItemAvailable ? 1 : 0.3 }}>{code}</div>
        <NavLink to={item.url || ''} style={isItemAvailable ? {} : { opacity: 0.3 }}>
          {description}
        </NavLink>
        <Collapse in={!isItemAvailable}>
          <div className={classes.error}>
            {lang('user.Checkout.productNotAvailable')}
          </div>
        </Collapse>
      </Grid>
      {showPrices && (
        <>
          <Grid item xs={12} md={2} className={classes.label} style={{ fontWeight: 600, opacity: isItemAvailable ? 1 : 0.3 }}>
            <Hidden mdUp>
              <span>{lang('user.Checkout.price')}</span>
            </Hidden>
            {priceToString('€', item.price, lang('global.priceUponRequest'))}
            <Hidden xsUp={!item.discountOne}>
              <Typography variant="subtitle2">
                {[item.discountOne, item.discountTwo, item.discountThree, item.discountFour].filter((d) => d !== null && d !== undefined && d !== 0).map((d) => `${d}%`).join(' ')}
              </Typography>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={2} className={classes.label} style={{ fontWeight: 600, opacity: isItemAvailable ? 1 : 0.3 }}>
            <Hidden mdUp>
              <span>{lang('user.Checkout.totalAlt')}</span>
            </Hidden>
            {priceToString('€', item.totalPrice || null)}
            {item.promo && isAgent && (
              <div title={item.promo} style={{ color: '#e754cb' }}>
                {item.promo}
              </div>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%',
        }}
        >
          <Hidden xsUp={!isItemAvailable}>
            <FormControl variant="standard" margin="dense" style={{ marginTop: 0, marginBottom: 0, marginRight: '.5rem' }}>
              <OutlinedInput
                id={`product-quantity-${item.code}`}
                type="number"
                disabled={loadingOrders}
                value={quantity.toString()}
                onChange={handleChange}
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                startAdornment={(
                  <InputAdornment position="start">
                    <IconButton
                      size="small"
                      edge="start"
                      disabled={loadingOrders}
                      onClick={() => { onRemove(); }}
                    >
                      <RemoveCircleOutlineRounded />
                    </IconButton>
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      edge="end"
                      disabled={loadingOrders}
                      onClick={() => { onAdd(); }}
                    >
                      <AddCircleOutlineRounded />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
          </Hidden>
          <IconButton onClick={() => removeArticle(item)} disabled={loadingOrders} style={{ marginLeft: 'auto' }}>
            <CloseRounded />
          </IconButton>
        </div>
        <Collapse in={orderChecked && (item.quantity !== undefined && item.available !== undefined && item.available < item.quantity && item.available !== 0)}>
          <div className={classes.warning}>
            {qMessage}
          </div>
        </Collapse>
        <Collapse in={orderChecked && (Boolean(item.leadTime) && item.quantity !== undefined && item.available !== undefined && (item.available < item.quantity || item.available === 0))}>
          <div className={classes.success}>
            {item.leadTime}
          </div>
        </Collapse>
        <Collapse in={orderChecked && (item.quantity !== undefined && item.available !== undefined && item.available >= item.quantity && item.available !== 0)}>
          <div className={classes.success}>
            {lang('user.Checkout.available')}
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
}

CheckoutItem.contextTypes = { ...AppContext };
