import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { itIT } from '@material-ui/core/locale';
import { Overrides } from '@material-ui/core/styles/overrides';

export const palette = {
  primary: {
    light: 'rgba(0,0,0,0.54)',
    main: 'rgba(0,0,0,0.64)',
    dark: 'rgba(0,0,0,0.74)',
    contrastText: '#fff',
  },
  secondary: {
    light: 'rgba(0,0,0,0.54)',
    main: 'rgba(0,0,0,0.64)',
    dark: 'rgba(0,0,0,0.74)',
    contrastText: '#fff',
  },
  accent: {
    light: '#1980D2',
    main: '#0072ce',
    dark: '#0066B9',
  },
  text: {
    primary: '#4D5E78',
    dark: '#222222',
  },
};

export const overrides = {
  MuiButton: {
    contained: {
      boxShadow: 'none',
      '&:active, &:hover': {
        boxShadow: 'none',
      },
      fontWeight: 600,
    },
  },
  MuiCard: {
    root: {
      boxShadow: 'none',
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: palette.accent.main,
    }
  },
  MuiTextField: {
    root: {
      width: '100%',
      borderRadius: '3px !important',
      backgroundColor: '#EEEEEE !important',
      '& label.Mui-focused': {
        color: palette.text.primary,
        fontWeight: 500,
      },
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: '4px',
      backgroundColor: '#EEEEEE !important',
    },
    input: {
      borderRadius: '3px',
      backgroundColor: '#EEEEEE !important',
    },
    underline: {
      '&::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
  },
  MuiTypography: {
    body2: {
      fontSize: '1rem',
    },
    h4: {
      fontWeight: 600,
      textAlign: 'center'
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      textAlign: 'center'
    },
    overline: {
      lineHeight: '1.26rem'
    }
  },
  MuiListSubheader: {
    root: {
      color: palette.text.dark,
      lineHeight: '1.8rem',
    },
  },
  MuiListItem: {
    root: {
      margin: '0 1rem',
      width: 'calc(100% - 2rem)',
      borderRadius: '4px',
      lineHeight: '1.8rem',
      marginBottom: '0.2rem',
      paddingTop: 0,
      paddingBottom: 0,
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: alpha(palette.secondary.main, 0.1),
        color: palette.secondary.main,
        fontWeight: 600,
      },
    },
    button: {
      '&:hover': {
        backgroundColor: alpha(palette.secondary.main, 0.1),
        color: palette.secondary.main,
      },
    },
  },
} as Overrides;

const theme = createTheme({
  palette,
  overrides,
  typography: {
    fontFamily: 'Montserrat, sans-serif'
  },
}, itIT);

export default responsiveFontSizes(theme);
