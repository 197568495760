import { createStore, compose, applyMiddleware, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { History } from 'history';

import requiredHeadersMiddleware from '../middlewares/headers';
import createRootReducer from './reducers';
import unauthorizeMiddleware from '../middlewares/unauthorize';
import authMiddleware from '../middlewares/auth';
import agentParamsMiddleware from '../middlewares/agent';
import queryMiddleware from '../middlewares/query';
import i18nMiddleware from '../middlewares/i18n';

export default function configureStore(initialState: any, routerMiddleware: any, history: History<any>): Store {
  const middewares = [
    routerMiddleware,
    agentParamsMiddleware,
    i18nMiddleware,
    queryMiddleware,
    requiredHeadersMiddleware,
    authMiddleware,
    apiMiddleware,
    unauthorizeMiddleware,
    thunkMiddleware,
  ];

  const state = { ...initialState };

  return createStore(
    createRootReducer(history),
    state,
    compose(applyMiddleware(...middewares))
  );
}
