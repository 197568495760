import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, makeStyles, TextField, FormControlLabel, Checkbox, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, Theme, Collapse } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { IAppContext, AppContext } from '../../containers/App';
import { validateEmail, isBlank } from '../../state/utils';
import { IContactFormValues } from '../../state/data-types';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#1D1D1C',
  },
  formDescription: {
    fontWeight: 400,
    fontSize: '18px',
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: 'none !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    '&:hover, &:focus': {
      textDecoration: 'none !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1rem',
    fontSize: '1.1rem',
    color: '#26b12b',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1rem',
    fontSize: '1.1rem',
    color: '#ec281a',
    textAlign: 'center',
  }
}));

interface IState {
  values: IContactFormValues;
  privacy: boolean;
  send: {
    success: boolean;
    timeout: any;
  };
}

export interface IContactsProps {
  loading: boolean;
  error: any;
}

export default function Contacts(props: IContactsProps, context: IAppContext) {
  const { actions, lang } = context;
  const { loading, error } = props;

  React.useEffect(() => {
    context.actions.api.getMetaData('contacts');
  }, []);

  const [state, setState] = React.useState<IState>({
    values: {
      name: '',
      surname: '',
      partitaIva: '',
      company: '',
      kind: '',
      email: '',
      phone: '',
      object: '',
      notes: '',
      city: '',
    },
    privacy: false,
    send: {
      success: false,
      timeout: null,
    }
  });

  const handleChange = (name: keyof IContactFormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      values: {
        ...state.values,
        [name]: event.target.value === '' ? null : event.target.value,
      },
    });
  };

  const handleSelectChange = (name: keyof IContactFormValues) => (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({
      ...state,
      values: {
        ...state.values,
        [name]: event.target.value === '' ? null : event.target.value,
      }
    });
  };

  const checkboxHandleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const isFormValidated = !isBlank(state.values.name) && !isBlank(state.values.surname) && !isBlank(state.values.partitaIva) && state.values.partitaIva.length >= 8 && !isBlank(state.values.email) && validateEmail(state.values.email) && state.privacy && !isBlank(state.values.object) && !isBlank(state.values.notes);

  const onSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    actions.api.sendForm(state.values).then(response => {
      if (!response.error && response.payload.status) {
        setState({
          ...state,
          values: {
            name: '',
            surname: '',
            partitaIva: '',
            company: '',
            kind: '',
            email: '',
            phone: '',
            object: '',
            notes: '',
            city: '',
          },
          privacy: false,
          send: {
            success: true,
            timeout: setTimeout(() => { setState({ ...state, send: { success: false, timeout: null } }); }, 3000),
          }
        });
      }
    });
  };

  const classes = useStyles();
  return (
    <div className="static-page">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('contacts.Contacts.contacts')}
        </h1>
        <h4 style={{ whiteSpace: 'pre-line' }}>
          {lang('contacts.Contacts.subtitle')}
        </h4>
      </div>
      <div className="section content">
        <Grid container item xs={12}>
          <Grid item xs={12} lg={3}>
            <span className={classes.title}>{lang('contacts.Contacts.companyName')}</span>
            <p style={{ whiteSpace: 'pre-line' }}>
              {lang('contacts.Contacts.address')}
            </p>
            <p>
              {lang('contacts.Contacts.phone.title')}{' '}<a href={`tel:${lang('contacts.Contacts.phone.number')}`} className={classes.link}>{lang('contacts.Contacts.phone.humanReadable')}</a><br />
              {lang('contacts.Contacts.fax.title')}{' '}{lang('contacts.Contacts.fax.humanReadable')}
            </p>
            <p>
              <a href="mailto:airex@airex.it" className={classes.link}>airex@airex.it</a>
            </p>
          </Grid>
          <Grid item xs={12} lg={9}>
            <span className={classes.formDescription} style={{ whiteSpace: 'pre-line' }}>
              {lang('contacts.Contacts.formDescription')}
            </span>
            <form style={{ margin: '2rem 0' }}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    id="name"
                    label={lang('contacts.Contacts.firstName')}
                    margin="normal"
                    variant="filled"
                    value={state.values.name || ''}
                    onChange={handleChange('name')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    id="surname"
                    label={lang('contacts.Contacts.lastName')}
                    margin="normal"
                    variant="filled"
                    value={state.values.surname || ''}
                    onChange={handleChange('surname')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    type="email"
                    id="email"
                    label={lang('contacts.Contacts.email')}
                    margin="normal"
                    variant="filled"
                    value={state.values.email || ''}
                    onChange={handleChange('email')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    id="partitaIva"
                    label={lang('contacts.Contacts.vatNumber')}
                    margin="normal"
                    variant="filled"
                    value={state.values.partitaIva || ''}
                    onChange={handleChange('partitaIva')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="company"
                    label={lang('contacts.Contacts.company')}
                    margin="normal"
                    variant="filled"
                    value={state.values.company || ''}
                    onChange={handleChange('company')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl variant="filled" style={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}>
                    <InputLabel id="kind">{lang('contacts.Contacts.sector')}</InputLabel>
                    <Select
                      required
                      labelId="kind"
                      id="kind"
                      value={state.values.kind}
                      onChange={handleSelectChange('kind')}
                    >
                      <MenuItem value="Utilizzatore finale">{lang('contacts.Contacts.formQuestions.sector.endUser')}</MenuItem>
                      <MenuItem value="Costruttore di macchine">{lang('contacts.Contacts.formQuestions.sector.manufacturer')}</MenuItem>
                      <MenuItem value="Rivenditore di ferramenta">{lang('contacts.Contacts.formQuestions.sector.hardwareDealer')}</MenuItem>
                      <MenuItem value="Rivenditore di articoli tecnici">{lang('contacts.Contacts.formQuestions.sector.hardwareDealerAlt')}</MenuItem>
                      <MenuItem value="Rivenditore di utensileria">{lang('contacts.Contacts.formQuestions.sector.toolDealer')}</MenuItem>
                      <MenuItem value="Installatore">{lang('contacts.Contacts.formQuestions.sector.technician')}</MenuItem>
                      <MenuItem value="Importatore">{lang('contacts.Contacts.formQuestions.sector.importer')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    type="city"
                    id="city"
                    label={lang('contacts.Contacts.city')}
                    margin="normal"
                    variant="filled"
                    value={state.values.city || ''}
                    onChange={handleChange('city')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    type="phone"
                    id="phone"
                    label={lang('contacts.Contacts.phoneExtended')}
                    margin="normal"
                    variant="filled"
                    value={state.values.phone || ''}
                    onChange={handleChange('phone')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="object"
                    label={lang('contacts.Contacts.subject')}
                    margin="normal"
                    variant="filled"
                    value={state.values.object || ''}
                    onChange={handleChange('object')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="notes"
                    label={lang('contacts.Contacts.text')}
                    margin="normal"
                    variant="filled"
                    multiline
                    rows="10"
                    inputProps={{ style: { height: '10rem !important' } }}
                    value={state.values.notes || ''}
                    onChange={handleChange('notes')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={state.privacy || false} onChange={checkboxHandleChange('privacy')} value={state.privacy || false} />}
                    label={<div>{lang('contacts.Contacts.readAndAccept')}{' '}<NavLink to="/privacy">{lang('contacts.Contacts.privacyPolicy')}</NavLink></div>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading || !isFormValidated}
                      onClick={onSave}
                    >
                      {lang('contacts.Contacts.submit')}
                    </Button>
                    {loading && <CircularProgress size={20} className={classes.buttonProgress} />}
                  </div>
                  <Collapse in={state.send.success}>
                    <div className={classes.success}>{lang('contacts.Contacts.submitSucceeded')}</div>
                  </Collapse>
                  <Collapse in={error != null}>
                    <div className={classes.error}>{lang('contacts.Contacts.submitFailed')}<br />{error}</div>
                  </Collapse>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2839.4452867622836!2d10.973317715799903!3d44.62881529681343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fe9d1598a0e17%3A0x23f79f879577d7cc!2sAIREX%20Spa!5e0!3m2!1sit!2sit!4v1589983631176!5m2!1sit!2sit" width="100%" height="450" frameBorder="0" style={{ border: '0', marginTop: '2rem' }} allowFullScreen aria-hidden="false" title="google maps" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Contacts.contextTypes = { ...AppContext };
