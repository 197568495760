import { RSAA } from 'redux-api-middleware';
import { MiddlewareAPI } from 'redux';
import { IState } from '../state/data-types';

const i18nMiddleware = (store: MiddlewareAPI<any, IState>) => (next: any) => (action: any) => {
  if (action[RSAA] !== undefined) {
    // eslint-disable-next-line no-param-reassign
    action[RSAA].query = {
      ...action[RSAA].query,
      lang: store.getState().app.lang,
    };
  }
  return next(action);
};

export default i18nMiddleware;
