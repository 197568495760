import React, { useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import {
  Container,
  Hidden,
  CircularProgress,
  Box,
  Theme,
  Typography,
  TablePagination,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RemoveCircleOutlineRounded, AddCircleOutlineRounded } from '@material-ui/icons';
import { AppContext, IAppContext } from '../../containers/App';
import {
  ICart,
  ICustomer,
  IProductItem,
  IProductSection,
} from '../../state/data-types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '2rem',
  },
  productHeader: {
    width: '100%',
    display: 'flex',
    padding: '1.5rem 2rem 1rem',
    color: '#666',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    backgroundColor: '#f9f9f9',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
    },
  },
  tableHeader: {
    padding: '1rem 2rem',
    position: 'relative',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
  },
  productFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 2rem',
  },
  productContainer: {
    borderRadius: '3px',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // padding: '2rem 1.5rem',

    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface IFavouriteProps {
  products: IProductSection[];
  loading: boolean;
  user?: ICustomer;
  pageCount: number;
  cart: ICart;
}

export default function Favourite(
  props: IFavouriteProps,
  context: IAppContext
) {
  const { products, loading, pageCount } = props;
  const { lang } = context;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    context.actions.api.getFavouriteProducts(page * rowsPerPage, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.Favourite.title')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>{lang('user.Favourite.title')}</h1>
      </div>
      <Hidden xsUp={loading || products.length === 0}>
        <FavouriteSection products={products} loading={loading} cart={props.cart} />
        <Container style={{ marginTop: '-3rem', marginBottom: '3rem' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <TablePagination
                component="div"
                count={pageCount * rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      <Hidden xsUp={loading || products.length > 0}>
        <div style={{ padding: '3rem 2rem' }}>
          <Typography
            variant="h5"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {lang('user.Favourite.noProductAvailable')}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {lang('user.Favourite.addAProductToFavourites')}
          </Typography>
        </div>
      </Hidden>
    </section>
  );
}

type OrderSelected = Partial<Record<string, {quantity: number; item: IProductItem; product: IProductSection}>>;

function FavouriteSection(props: {loading: boolean; products: IProductSection[]; limit?: number; cart: ICart}, context: IAppContext) {
  const { loading, products, cart } = props;
  const classes = useStyles();
  const { lang } = context;
  const [orderSelected, setOrderSelected] = useState<OrderSelected>({});
  const canOrderSelected = useMemo(() => Object.entries(orderSelected).length > 0, [orderSelected]);
  const addToCart = () => {
    if (canOrderSelected) {
      context.actions.session.addArticlesToCart(cart, Object.values(orderSelected).flatMap((datum) => (!datum ? [] : [{
        id: datum.item.id,
        code: datum.item.code,
        description: datum.item.label,
        quantity:
          datum.quantity % Number(datum.item.pieces || 0) === 0
            ? datum.quantity
            : datum.quantity + (Number(datum.item.pieces || 0) - (datum.quantity % Number(datum.item.pieces || 0))),
        preview: datum.product.preview,
        url: `/catalogs/${datum.product.catalog}/${datum.product.family}/${datum.product.category}/${datum.product.subcategory}/${datum.product.slug}`,
        pieces: datum.item.pieces,
      }])));
      context.dispatch(context.push('/checkout'));
    }
  };

  return (
    <>
      <Container className="content">
        <Hidden xsUp={!loading}>
          <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Box boxShadow={1}>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '1.5rem 2rem 1rem', backgroundColor: '#f9f9f9' }}>
              <Button
                disabled={!canOrderSelected}
                variant="outlined"
                disableElevation
                color="primary"
                size="small"
                style={{ fontWeight: 600, marginLeft: 'auto', padding: '4px 12px' }}
                onClick={() => { addToCart(); }}
              >
                {lang('user.Favourite.orderSelected')}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <div className={classes.items}>
              <Hidden
                smDown
                xsUp={!products || products.length === 0}
              >
                <Box className={classes.row}>
                  <Grid container className={classes.tableHeader}>
                    <Grid item xs={3} className={classes.label}>
                      {lang('user.Favourite.preview')}
                    </Grid>
                    <Grid item xs={3} className={classes.label}>
                      {lang('user.Favourite.code')}
                    </Grid>
                    <Grid item xs={3} className={classes.label}>
                      {lang('user.Favourite.name')}
                    </Grid>
                    <Grid item xs={3} className={classes.label}>
                      {lang('user.Favourite.quantity')}
                    </Grid>
                  </Grid>
                </Box>
              </Hidden>
              {products
                ? products.map(
                  (product, index) => (
                    <ProductRow
                      orderSelected={orderSelected}
                      setOrderSelected={setOrderSelected}
                      product={product}
                      index={index}
                      key={`product_${index}`}
                    />
                  )
                )
                : null}
            </div>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const itemStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    position: 'relative',
    padding: '0.8rem 2rem',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > span': {
      fontWeight: 'bold',
      fontSize: '.8rem',
      color: '#636363',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      marginTop: '1rem',
    },
    '&.inner': {
      color: '#636363',
      '& > span': {
        color: '#636363',
      },
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  img: {
    width: 'auto',
    height: '2.5rem',
  }
}));

function ProductRow({ index, product, orderSelected, setOrderSelected }: {index: number; product: IProductSection; orderSelected: OrderSelected; setOrderSelected: (v: OrderSelected) => void}/* , context: IAppContext */) {
  return (
    <>{(
      product.items.map((item, i) => {
        const orderSelectedKey = product.code + item.code;
        return (
          <ProductItemRow
            quantity={orderSelected[orderSelectedKey]?.quantity ?? 0}
            onQuantityChange={(q) => setOrderSelected({
              ...orderSelected,
              [orderSelectedKey]: { quantity: q, item, product }
            })}
            key={item.code + index}
            product={product}
            item={item}
            productIndex={index}
            itemIndex={i}
          />
        );
      })
  )}
    </>
  );
}

function ProductItemRow({ product, item, productIndex, itemIndex, quantity, onQuantityChange }: {product: IProductSection; item: IProductItem; productIndex: number; itemIndex: number; quantity: number; onQuantityChange: (n: number) => void}, context: IAppContext) {
  const classes = itemStyles();
  const dispatch = useDispatch();
  const { lang } = context;

  const onRemove = () => {
    let newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (quantity % pieces !== 0) {
      newQuantity = quantity - (quantity % pieces);
    } else {
      newQuantity = quantity - pieces;
    }
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    onQuantityChange(newQuantity);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = parseInt(event.target.value, 10);
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    onQuantityChange(newQuantity);
  };

  const onAdd = () => {
    const newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (newQuantity % pieces !== 0) {
      onQuantityChange(newQuantity + (pieces - (newQuantity % pieces)));
    } else {
      onQuantityChange(newQuantity + pieces);
    }
  };

  return (
    <Box className={`${classes.row} ${(productIndex + itemIndex) % 2 !== 0 ? classes.even : ''}`}>
      <Grid
        container
        className={classes.container}
        key={`product_item_${itemIndex}`}
        onClick={() => {
          const url = `/catalogs/${product.catalog}/${product.family}/${product.category}/${product.subcategory}/${product.slug}`;
          dispatch(push(url));
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          className={classes.label}
        >
          <Hidden mdUp>
            <span>{lang('user.Favourite.preview')}</span>
          </Hidden>
          <img className={classes.img} src={product.preview} alt={product.image_alt_tag} />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          className={classes.label}
        >
          <Hidden mdUp>
            <span>{lang('user.Favourite.code')}</span>
          </Hidden>
          {item.code}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={3}
          className={classes.label}
        >
          <Hidden mdUp>
            <span>{lang('user.Favourite.name')}</span>
          </Hidden>
          {item.label}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          className={classes.label}
        >
          <Hidden mdUp>
            <span>{lang('user.Favourite.brand')}</span>
          </Hidden>
          <FormControl
            variant="standard"
            margin="dense"
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            <OutlinedInput
              id={`product-quantity-${item.code}`}
              type="number"
              value={quantity.toString()}
              onChange={handleChange}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              startAdornment={(
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    edge="start"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove();
                    }}
                  >
                    <RemoveCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
          )}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAdd();
                    }}
                  >
                    <AddCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
          )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

Favourite.contextTypes = { ...AppContext };
FavouriteSection.contextTypes = { ...AppContext };
ProductRow.contextTypes = { ...AppContext };
ProductItemRow.contextTypes = { ...AppContext };
