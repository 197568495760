/* eslint-disable @typescript-eslint/camelcase */
import { IState, Role } from './data-types';

export const supportedLangs = ['en', 'it'];

const initialState: IState = {
  router: undefined,
  app: {
    notifications: [],
    lang: (() => {
      const candidate = localStorage.getItem('lang') ?? (navigator.language.split('-')[0] || 'en');
      if (supportedLangs.includes(candidate)) {
        return candidate;
      }
      return supportedLangs[0];
    })(),
  },
  session: {
    loadingSignUp: false,
    hasQuotes: false,
    authenticated: false,
    token: undefined,
    loading: false,
    role: Role.Client,
    user: undefined,
    loadingUser: true,
    loadingOrders: false,
    error: undefined,
    cart: {
      items: [],
      orderChecked: false,
      totaleDocumento: undefined,
      totaleImponibile: undefined,
      totaleImposta: undefined,
      totaleMerce: undefined,
      totaleSconto: undefined,
      coupon: undefined,
      esitoCoupon: undefined,
      note: undefined,
      synced: true
    },
    agent: undefined,
    loadingClients: true,
  },
  api: {
    catalogs: {
      brands: [],
      product: undefined,
      items: [],
      breadcrumbs: [],
      loading: false,
      error: null,
    },
    news: {
      items: [],
      loading: false,
      error: null,
    },
    featured: {
      items: [],
      loading: false,
      error: null,
    },
    recentlySeen: {
      items: [],
      loading: false,
      error: null,
    },
    favouriteProducts: {
      products: [],
      loading: false,
      error: null,
      pageCount: 0,
    },
    banners: {
      items: [],
      loading: false,
      error: null,
    },
    reviews: {
      items: [],
      loading: false,
      error: null,
    },
    partners: {
      items: [],
      loading: false,
      error: null,
    },
    search: {
      products: [],
      loading: true,
      error: null,
    },
    contacts: {
      loading: false,
      error: null,
    },
    meta: {
      meta_title: '',
      meta_description: '',
    },
    documents: {
      loading: true,
      error: null,
      categories: [],
      items: [],
      breadcrumbs: [],
    },
    orders: {
      loading: false,
      refreshing: false,
      error: null,
      items: [],
      pageCount: 0,
    },
    quotes: {
      loading: false,
      error: null,
      items: [],
      pageCount: 0,
    },
    backOrders: {
      loading: false,
      refreshing: false,
      error: null,
      items: [],
      pageCount: 0,
    }
  }
};

export default initialState;
