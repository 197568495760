import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { AppContext, IAppContext } from '../../containers/App';

const useStyles = makeStyles((theme: Theme) => ({
  upperText: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    marginBottom: '4rem',
    textAlign: 'justify',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      marginBottom: '2rem',
    }
  },
  description: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    paddingLeft: '3rem',
    textAlign: 'justify',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      paddingLeft: '0rem',
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  },
  counterContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '5rem',
    marginBottom: '3rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
    }
  },
  counter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 3rem',
    '& > span': {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#212121',
      marginTop: '2.5rem',
      textTransform: 'uppercase',
      textAlign: 'center',
    }
  },
  numbers: {
    height: '180px',
    width: '180px',
    borderRadius: '50%',
    display: 'flex',
    fontWeight: 600,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0266CC',
    color: 'white',
    lineHeight: '3.8rem',
    fontSize: '3.2rem',
  },
  companyImage: {
    width: '95%',
    marginTop: '1.2rem',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

export interface ICompanyProps {}

export default function Company(_props: ICompanyProps, context: IAppContext) {
  const classes = useStyles();
  const { lang } = context;

  React.useEffect(() => {
    context.actions.api.getMetaData('company');
  }, []);

  return (
    <div className="static-page">
      <div className="hero">
        <h1>
          {lang('company.Company.title')}
        </h1>
        <h4 style={{ whiteSpace: 'pre-line' }}>
          {lang('company.Company.subtitle')}
        </h4>
      </div>
      <div className="section content">
        <Grid container style={{ margin: '0 auto', maxWidth: '1024px' }}>
          <Grid item xs={12} className={classes.upperText}>
            {lang('company.Company.text1')}
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.companyImage} src={require('../../images/31E1250F-8554-420A-B8CD-17D18434594B.jpeg')} alt="" />
          </Grid>
          <Grid item xs={12} md={6} className={classes.description}>
            {lang('company.Company.text2')}
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.companyImage} src={require('../../images/IMG_5496.jpeg')} alt="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.companyImage} style={{ marginLeft: 'auto' }} src={require('../../images/IMG_0295.jpeg')} alt="" />
          </Grid>
        </Grid>
        <div className={classes.counterContainer}>
          <div className={classes.counter}>
            <div className={classes.numbers}>
              {lang('company.Company.clientsCount')}
            </div>
            <span>{lang('company.Company.activeClients')}</span>
          </div>
          <div className={classes.counter}>
            <div className={classes.numbers}>
              {lang('company.Company.elaboratedOrdersCount')}
            </div>
            <span>
              {lang('company.Company.elaboratedOrders')}
            </span>
          </div>
          <div className={classes.counter}>
            <div className={classes.numbers}>
              {lang('company.Company.agentsAndSalesmenCount')}
            </div>
            <span>{lang('company.Company.agentsAndSalesmen')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

Company.contextTypes = { ...AppContext };
