import React from 'react';
import { Helmet } from 'react-helmet';
import { AppContext, IAppContext } from '../../containers/App';

export default function Privacy(_props: any, context: IAppContext) {
  const { lang } = context;
  React.useEffect(() => {
    context.actions.api.getMetaData('privacy');
  }, []);

  return (
    <div className="static-page">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('privacy.Privacy.title')}
        </h1>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="section content" dangerouslySetInnerHTML={{ __html: lang('privacy.Privacy.all') }} />
    </div>
  );
}

Privacy.contextTypes = { ...AppContext };
