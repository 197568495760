import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorOutlineRounded, CheckCircleOutlineRounded, InfoOutlined, WarningRounded } from '@material-ui/icons';
import { store, history } from './state/store';
import routes from './routes';

import App from './containers/App';

import './styles/styles.global.scss';

document.addEventListener('DOMContentLoaded', () => {
  const renderApp = (appRoutes: any) => {
    ReactDOM.render(
      (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              preventDuplicate
              iconVariant={{
                error: <ErrorOutlineRounded style={{ marginRight: '.5rem' }} />,
                success: <CheckCircleOutlineRounded style={{ marginRight: '.5rem' }} />,
                info: <InfoOutlined style={{ marginRight: '.5rem' }} />,
                warning: <WarningRounded style={{ marginRight: '.5rem' }} />,
                default: null,
              }}
            >
              <App>
                {appRoutes}
              </App>
            </SnackbarProvider>
          </ConnectedRouter>
        </Provider>
      ),
      document.getElementById('app')
    );
  };

  renderApp(routes);

  if (module.hot) {
    module.hot.accept('./routes', () => {
      const newRoutes = require('./routes').default;
      renderApp(newRoutes);
    });
  }
});
