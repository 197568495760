import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, CircularProgress, Theme, Card, TextField, Button, Collapse, Grid, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Checkbox, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { AppContext, IAppContext } from '../../containers/App';
import { isBlank, validateEmail } from '../../state/utils';
import { IContactFormValues, ISession, Role } from '../../state/data-types';
import { palette } from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: '15rem',
    marginTop: '2rem',
  },
  formDescription: {
    whiteSpace: 'pre-line',
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'left',
    paddingLeft: '0.5rem',
    color: theme.palette.text.primary,
    marginBottom: '1rem',
  },
  formButtonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formButtonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },

  link: {
    textDecoration: 'none !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    '&:hover, &:focus': {
      textDecoration: 'none !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1rem',
    fontSize: '1.1rem',
    color: '#26b12b',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1rem',
    fontSize: '1.1rem',
    color: '#ec281a',
    textAlign: 'center',
  },
  airyBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 128,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
      flexDirection: 'column',
    },
  },
  airyBannerImgWrapper: {
    flexGrow: 1,
    maxWidth: 132,
    flexBasis: 1,
    marginRight: 48,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 24,
    },
  },
  airyBannerTextWrapper: {
    color: palette.accent.main,
    fontWeight: 'bold',
    fontSize: '18px',
    whiteSpace: 'pre-line',
    flexGrow: 1,
    flexBasis: 4,
    maxWidth: 384,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  staticPageOverrides: {
    paddingTop: 0,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 32,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 64,
    },
  }
}));

interface IProps {
  session: ISession;
  redirectTo?: string;
}

interface ILoginState {
  username: string;
  password: string;
  showResetPaswForm: boolean;
}

interface ISignUpState {
  values: IContactFormValues;
  privacy: boolean;
  send: {
    success: boolean;
    timeout: any;
  };
}

export default function Login(props: IProps, context: IAppContext) {
  const { lang } = context;
  const classes = useStyles();
  return (
    <section className={`static-page ${classes.staticPageOverrides}`} style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.Login.login')}</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <Container className="content">
        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              boxShadow={1}
              style={{
                padding: '1rem 1rem',
              }}
            >
              <LoginSide {...props} />
            </Box>
            <div className={classes.airyBanner}>
              <div className={classes.airyBannerImgWrapper}>
                <img
                  src={require('../../images/airy-thumbup.png')}
                  alt="Airy"
                  width={1457}
                  height={1125}
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <div className={classes.airyBannerTextWrapper}>
                {lang('user.Login.airyBanner')}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              boxShadow={1}
              style={{
                padding: '1rem 1rem',
              }}
            >
              <SignUpSide {...props} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Login.contextTypes = { ...AppContext };

function LoginSide(props: IProps, context: IAppContext) {
  const { session } = props;
  const { actions, push, dispatch, lang } = context;
  const classes = useStyles();

  const [state, setState] = React.useState<ILoginState>({
    // username: 'dev@14spm.com',
    // password: 'test',
    username: '',
    password: '',
    showResetPaswForm: false,
  });

  React.useEffect(() => {
    if (session.authenticated) {
      if (session.role === Role.Agent && !session.agent?.selectedClient) {
        if (props.redirectTo !== undefined && !props.redirectTo.startsWith('/login')) {
          dispatch(push(`/clients?redirectTo=${encodeURIComponent(props.redirectTo)}`));
        } else {
          dispatch(push('/clients'));
        }
      // eslint-disable-next-line no-constant-condition
      } else if (false && props.redirectTo !== undefined && props.redirectTo !== '/login') {
        // actions.session.requestSavedCart();
        // const redirectTo = decodeURIComponent(props.redirectTo);
        // dispatch(push(redirectTo));
      } else {
        actions.session.requestSavedCart();
        if (props.redirectTo !== undefined && !props.redirectTo.startsWith('/login')) {
          dispatch(push(props.redirectTo));
        } else {
          dispatch(push('/user'));
        }
      }
    }
  }, [session.authenticated]);

  const handleChange = (name: keyof ILoginState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.value });
  };

  const onLogin = (event: React.MouseEvent | React.FormEvent) => {
    event.preventDefault();
    actions.session.login(state.username, state.password);
  };

  const toggleResetPaswForm = () => {
    setState({
      ...state,
      showResetPaswForm: !state.showResetPaswForm,
    });
  };

  const onResetPasw = (event: React.MouseEvent | React.FormEvent) => {
    event.preventDefault();
    actions.session.recoveryPasw(state.username).then((response: any) => {
      if (!response.error && response.payload.status) {
        context.actions.app.enqueueSnackbar({ message: lang('user.Login.passwordRecoverySuccess'), options: { variant: 'success' } });
        toggleResetPaswForm();
      }
    });
  };

  const isFormValidated = !isBlank(state.username) && (state.showResetPaswForm || !isBlank(state.password));

  return (
    <div>
      <Collapse in={!state.showResetPaswForm}>
        <div className={classes.formDescription}>
          {lang('user.Login.description')}
        </div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="filled-username-login-required"
                label={lang('user.Login.email')}
                margin="normal"
                variant="filled"
                value={state.username}
                onChange={handleChange('username')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-password-input"
                label={lang('user.Login.password')}
                type="password"
                autoComplete="new-password"
                margin="normal"
                variant="filled"
                value={state.password}
                onChange={handleChange('password')}
              />
            </Grid>
          </Grid>
          <Collapse in={session.error !== undefined}>
            <div className="error-container">
              {session.error}
            </div>
          </Collapse>
          <div className={classes.wrapper} style={{ marginTop: 12 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={session.loading || !isFormValidated}
              onClick={onLogin}
            >
              {lang('user.Login.loginButton')}
            </Button>
            {session.loading && <CircularProgress size={20} thickness={5} className={classes.formButtonProgress} />}
          </div>
          <div className={classes.wrapper} style={{ marginTop: 12 }}>
            <Button onClick={toggleResetPaswForm} variant="text" color="primary" disableRipple disableElevation disableFocusRipple style={{ textTransform: 'none', fontWeight: 600 }}>
              {lang('user.Login.recoverPassword')}
            </Button>
          </div>
        </form>
      </Collapse>
      <Collapse in={state.showResetPaswForm}>
        <div className={classes.formDescription}>
          {lang('user.Login.recoveryDescription')}
        </div>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="filled-username-pasw-recovery-required"
                label={lang('user.Login.email')}
                margin="normal"
                variant="filled"
                value={state.username}
                onChange={handleChange('username')}
              />
            </Grid>
          </Grid>
          <Collapse in={session.error !== undefined}>
            <div className="error-container">
              {session.error}
            </div>
          </Collapse>
          <div className={classes.wrapper} style={{ marginTop: 12 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={session.loading || !isFormValidated}
              onClick={onResetPasw}
            >
              {lang('user.Login.recoverButton')}
            </Button>
            {session.loading && <CircularProgress size={20} thickness={5} className={classes.formButtonProgress} />}
          </div>
          <div className={classes.wrapper} style={{ marginTop: 12 }}>
            <Button onClick={toggleResetPaswForm} variant="text" color="primary" disableRipple disableElevation disableFocusRipple style={{ margin: '0 auto 1rem', textTransform: 'none', fontWeight: 600 }}>
              {lang('user.Login.backToLogin')}
            </Button>
          </div>
        </form>
      </Collapse>
    </div>
  );
}

LoginSide.contextTypes = { ...AppContext };

function SignUpSide(props: IProps, context: IAppContext) {
  const { session } = props;
  const { actions, push, dispatch, lang } = context;

  const [state, setState] = React.useState<ISignUpState>({
    values: {
      name: '',
      surname: '',
      partitaIva: '',
      company: '',
      kind: '',
      email: '',
      phone: '',
      object: '',
      notes: '',
      city: '',
    },
    privacy: false,
    send: {
      success: false,
      timeout: null,
    }
  });

  const handleChange = (name: keyof IContactFormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      values: {
        ...state.values,
        [name]: event.target.value === '' ? null : event.target.value,
      },
    });
  };

  const handleSelectChange = (name: keyof IContactFormValues) => (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({
      ...state,
      values: {
        ...state.values,
        [name]: event.target.value === '' ? null : event.target.value,
      }
    });
  };

  const checkboxHandleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const isFormValidated = !isBlank(state.values.name) && !isBlank(state.values.surname) && !isBlank(state.values.partitaIva) && state.values.partitaIva.length >= 8 && !isBlank(state.values.email) && validateEmail(state.values.email) && state.privacy;

  const { enqueueSnackbar } = useSnackbar();

  const onSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (!isFormValidated) {
      if (state.values.partitaIva.length < 8) {
        enqueueSnackbar(lang('user.Login.vatNumberError'), {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } else if (!validateEmail(state.values.email)) {
        enqueueSnackbar(lang('user.Login.invalidEmail'), {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } else {
        enqueueSnackbar(lang('user.Login.requiredFieldsError'), {
          variant: 'error',
          autoHideDuration: 1000,
        });
      }
      return;
    }

    actions.api.requestCredentials({ ...state.values, object: state.values.object || lang('contacts.Contacts.defaultSubject') }).then(response => {
      if (!response.error && response.payload.status) {
        setState({
          ...state,
          values: {
            name: '',
            surname: '',
            partitaIva: '',
            company: '',
            kind: '',
            email: '',
            phone: '',
            object: '',
            notes: '',
            city: '',
          },
          privacy: false,
          send: {
            success: true,
            timeout: setTimeout(() => { setState({ ...state, send: { success: false, timeout: null } }); }, 3000),
          }
        });

        dispatch(push('/thank-you-for-signing-up'));
      }
    });
  };

  const classes = useStyles();
  return (
    <div>
      <Grid container item xs={12}>
        <div className={classes.formDescription} style={{ whiteSpace: 'pre-line' }}>
          {lang('contacts.Contacts.formDescription')}
        </div>
        <form>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                id="name"
                label={lang('contacts.Contacts.firstName')}
                margin="normal"
                variant="filled"
                value={state.values.name || ''}
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                id="surname"
                label={lang('contacts.Contacts.lastName')}
                margin="normal"
                variant="filled"
                value={state.values.surname || ''}
                onChange={handleChange('surname')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                type="email"
                id="email"
                label={lang('contacts.Contacts.email')}
                margin="normal"
                variant="filled"
                value={state.values.email || ''}
                onChange={handleChange('email')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                id="partitaIva"
                label={lang('contacts.Contacts.vatNumber')}
                margin="normal"
                variant="filled"
                value={state.values.partitaIva || ''}
                onChange={handleChange('partitaIva')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                id="company"
                label={lang('contacts.Contacts.company')}
                margin="normal"
                variant="filled"
                value={state.values.company || ''}
                onChange={handleChange('company')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl variant="filled" style={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}>
                <InputLabel id="kind">{lang('contacts.Contacts.sector')}</InputLabel>
                <Select
                  required
                  labelId="kind"
                  id="kind"
                  value={state.values.kind}
                  onChange={handleSelectChange('kind')}
                >
                  <MenuItem value="Utilizzatore finale">{lang('contacts.Contacts.formQuestions.sector.endUser')}</MenuItem>
                  <MenuItem value="Costruttore di macchine">{lang('contacts.Contacts.formQuestions.sector.manufacturer')}</MenuItem>
                  <MenuItem value="Rivenditore di ferramenta">{lang('contacts.Contacts.formQuestions.sector.hardwareDealer')}</MenuItem>
                  <MenuItem value="Rivenditore di articoli tecnici">{lang('contacts.Contacts.formQuestions.sector.hardwareDealerAlt')}</MenuItem>
                  <MenuItem value="Rivenditore di utensileria">{lang('contacts.Contacts.formQuestions.sector.toolDealer')}</MenuItem>
                  <MenuItem value="Installatore">{lang('contacts.Contacts.formQuestions.sector.technician')}</MenuItem>
                  <MenuItem value="Importatore">{lang('contacts.Contacts.formQuestions.sector.importer')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                type="city"
                id="city"
                label={lang('contacts.Contacts.city')}
                margin="normal"
                variant="filled"
                value={state.values.city || ''}
                onChange={handleChange('city')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                type="phone"
                id="phone"
                label={lang('contacts.Contacts.phoneExtended')}
                margin="normal"
                variant="filled"
                value={state.values.phone || ''}
                onChange={handleChange('phone')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="object"
                label={lang('contacts.Contacts.subject')}
                margin="normal"
                variant="filled"
                value={state.values.object || lang('contacts.Contacts.defaultSubject')}
                onChange={handleChange('object')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="notes"
                label={lang('contacts.Contacts.text')}
                margin="normal"
                variant="filled"
                multiline
                rows="10"
                inputProps={{ style: { height: '10rem !important' } }}
                value={state.values.notes || ''}
                onChange={handleChange('notes')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={state.privacy || false} onChange={checkboxHandleChange('privacy')} value={state.privacy || false} />}
                label={<div>{lang('contacts.Contacts.readAndAccept')}{' '}<NavLink to="/privacy" target="_blank">{lang('contacts.Contacts.privacyPolicy')}</NavLink> *</div>}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={session.loadingSignUp}
                  onClick={onSave}
                >
                  {lang('contacts.Contacts.submit')}
                </Button>
                {session.loadingSignUp && <CircularProgress size={20} className={classes.buttonProgress} />}
              </div>
              <Collapse in={state.send.success}>
                <div className={classes.success}>{lang('contacts.Contacts.submitSucceeded')}</div>
              </Collapse>
              <Collapse in={session.error != null}>
                <div className={classes.error}>{lang('contacts.Contacts.submitFailed')}<br />{session.error}</div>
              </Collapse>
            </Grid>
          </Grid>
        </form>

      </Grid>
    </div>
  );
}

SignUpSide.contextTypes = { ...AppContext };
