import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  CircularProgress, Collapse, Container, Grid, Hidden, IconButton, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';
import { AppContext, IAppContext } from '../../containers/App';
import { IApi, IDeliveryPoint, ISession } from '../../state/data-types';
import OfficeCard from './OfficeCard';
import { DocumentItem } from './Documents';
import RecentlySeen from '../home/RecentlySeen';
import { useLocalStorageState } from '../../globals/local-storage';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressName: {
    fontWeight: 600,
    width: '100%',
  },
  address: {
    fontSize: '.9rem !important',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '2rem',
  }
}));

export interface IUserPageProps {
  session: ISession;
  api: IApi;
}

export default function UserPage(props: IUserPageProps, context: IAppContext) {
  const { session, api } = props;
  const { documents } = api;
  const { actions, lang } = context;
  const classes = useStyles();

  React.useEffect(() => {
    actions.session.getUser();
    context.actions.api.getDocuments('private');
    context.actions.api.getOrders();
    context.actions.api.getBackOrders();
  }, []);

  const [showPrices, setShowPrices] = useLocalStorageState('showPrices', true);
  const [togglingPriceVisibility, setTogglingPriceVisibility] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleTogglePriceVisibility = useCallback(() => {
    setTogglingPriceVisibility(true);
    try {
      // TODO: replace with API call
      // await new Promise<void>(res => setTimeout(res, 1000));
      setShowPrices(x => !x);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(lang('global.anErrorOccurred'), {
        variant: 'error',
      });
    } finally {
      setTogglingPriceVisibility(false);
    }
  }, [enqueueSnackbar, lang, setShowPrices]);

  const invoiceAndTransportDocs = documents.categories.filter(d => d.slug === 'fatture' || d.slug === 'documenti-trasporto');
  const documentsWithoutInvoicesOrTransportDocs = documents.categories.filter(d => !(d.slug === 'fatture' || d.slug === 'documenti-trasporto'));

  const contentView = (
    <div className={classes.container}>
      <Collapse in={session.user !== undefined}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="h3">
              {session.user?.name}
            </Typography>
            <Typography variant="body1">
              {session.user?.description}
            </Typography>
            <Typography variant="body2">
              {lang('user.UserPage.clientCode', { code: session.user?.code ?? '--' })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'flex-end', flexFlow: 'column' }}>
            <div style={{ position: 'relative', zIndex: 0 }}>
              <div style={{ position: 'relative', zIndex: 0 }}>
                <IconButton onClick={handleTogglePriceVisibility} disabled={togglingPriceVisibility}>
                  {showPrices ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
              {togglingPriceVisibility
              && (
              <div style={{ position: 'absolute', zIndex: 1, inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size="1.2rem" />
              </div>
              )}
            </div>
            <div>
              {showPrices ? lang('user.UserPage.visiblePrices') : lang('user.UserPage.hiddenPrices')}
            </div>
          </Grid>
        </Grid>
        <Hidden xsUp={session.user?.offices.length === 0}>
          <div className={classes.row}>
            <Typography variant="h5" style={{ marginTop: '2rem' }}>
              {lang('user.UserPage.offices')}
            </Typography>
          </div>
          <Grid container spacing={4} style={{ marginTop: '.2rem' }}>
            {
              session.user?.offices && session.user?.offices.length > 0 ? _.unionBy(session.user?.offices, 'id').map((office: IDeliveryPoint) => (
                <Grid item xs={12} md={6} lg={4} key={`office_${office.id}`}>
                  <OfficeCard
                    office={office}
                  />
                </Grid>
              )) : (
                <Typography variant="body1">
                  {lang('user.UserPage.noOffice')}
                </Typography>
              )
            }
          </Grid>
        </Hidden>
        <Hidden xsUp={session.user?.offices.length === 0}>
          <Grid container spacing={4} style={{ marginTop: '1rem' }}>
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/"
                slug=""
                label={lang('user.UserPage.newOrder')}
                icon={require('../../images/icons/Nuovo_ordine.png')}
                index={0}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/back-orders"
                slug="back-orders"
                label={lang('user.UserPage.backorders')}
                icon={require('../../images/icons/document-with-checkmark.svg')}
                index={0}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/orders"
                slug="orders"
                label={lang('user.UserPage.orderHistory')}
                icon={require('../../images/icons/cart.svg')}
                index={0}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/fast-checkout"
                slug="fast-checkout"
                label={lang('user.UserPage.fastCheckout')}
                icon={require('../../images/icons/timer.svg')}
                index={0}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/favourite"
                slug="favourite"
                label={lang('user.UserPage.favouriteProducts')}
                icon={require('../../images/icons/star.svg')}
                index={0}
              />
            </Grid>
            {(session.agent || session.hasQuotes)
            && (
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url="/my-quotes"
                slug="my-quotes"
                label={lang('user.UserPage.myQuotes')}
                icon={require('../../images/icons/money-calculator.svg')}
                index={0}
              />
            </Grid>
            )}
            {invoiceAndTransportDocs.map((element) =>
            <Grid item xs={12} md={4} lg={3} key={`${element.slug}_${0}`}>
              <DocumentItem
                url={`/documents/${element.slug}`}
                slug={element.slug}
                icon={element.icon}
                label={element.label}
                index={0}
              />
            </Grid>
            )}
            <Grid item xs={12} md={4} lg={3}>
              <DocumentItem
                url={window.configs.ENDPOINT + '/api/airy_tutorial'}
                slug="airyTutorial"
                label={lang('user.UserPage.airyTutorial')}
                icon={require('../../images/icons/Airy_icona.png')}
                index={0}
              />
            </Grid>
            {
              documentsWithoutInvoicesOrTransportDocs && documentsWithoutInvoicesOrTransportDocs.length > 0 ? documentsWithoutInvoicesOrTransportDocs.slice(0, 4).map((element, index) => {
                return (
                  <Grid item xs={12} md={4} lg={3} key={`${element.slug}_${index}`}>
                    <DocumentItem
                      url={`/documents/${element.slug}`}
                      slug={element.slug}
                      icon={element.icon}
                      label={element.label}
                      index={index}
                    />
                  </Grid>
                );
              }) : (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {lang('user.UserPage.noDocument')}
                  </Typography>
                </Grid>
              )
            }
          </Grid>
        </Hidden>
        <Container className={classes.container} maxWidth="xl">
          <RecentlySeen recentlySeen={props.api.recentlySeen.items} loading={props.api.recentlySeen.loading} />
        </Container>
      </Collapse>
    </div>
  );

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <Helmet>
        <title>{lang('user.UserPage.title')}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="hero">
        <h1>
          {lang('user.UserPage.title')}
        </h1>
      </div>
      <Container className="content">
        <Hidden xsUp={!session.loadingUser}>
          <div style={{ display: 'flex', padding: '3rem', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={session.loadingUser || session.error !== undefined || session.user === undefined || session.user === null}>
          {contentView}
        </Hidden>
        <Hidden xsUp={session.error === undefined}>
          <Typography variant="h5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {session.error}
          </Typography>
        </Hidden>
      </Container>
    </section>
  );
}

UserPage.contextTypes = { ...AppContext };
