import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';
import { AppContext, IAppContext } from '../App';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 5rem)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '5rem',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: 500,
    marginBottom: '2.5rem',
  },
  logo: {
    width: '90%',
    maxWidth: '16rem',
    marginBottom: '2rem',
  },
  borderedDecoration: {
    width: '100%',
    background: theme.palette.primary.main,
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: '1.5rem',
  },
}));

interface IProps {}

export default function NotFoundPage(_props: IProps, context: IAppContext) {
  const classes = useStyles();
  const { lang, dispatch, push } = context;

  return (
    <div className={classes.root}>
      <img src={require('../../images/logo.svg')} alt="Logo" className={classes.logo} />
      <h1 className={classes.title}>
        {lang('global.page-not-found')}
      </h1>
      <Button variant="contained" color="primary" onClick={() => { dispatch(push('/')); }}>
        {lang('global.back-home')}
      </Button>
      <div className={classes.borderedDecoration} />
    </div>
  );
}

NotFoundPage.contextTypes = { ...AppContext };
