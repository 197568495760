import React from 'react';
import { Theme, Hidden, IconButton, Drawer, Link, InputBase, ClickAwayListener, MenuItem, Badge, Menu, Divider, Button } from '@material-ui/core';
import { MenuRounded, SearchRounded, ShoppingCartRounded, AccountBoxRounded, ExitToAppRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { IAppContext, AppContext } from '../../containers/App';
import { ICartArticle, ISession } from '../../state/data-types';
import { Catalog } from '../../globals/constants';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { palette } from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: '0 2rem',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 800,
    backgroundColor: 'white',

    [theme.breakpoints.down('md')]: {
      height: '60px',
      padding: '0 0.5rem',

      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      backgroundColor: 'white',
      zIndex: 2000,
    },

    '& img': {
      height: '50px',
      [theme.breakpoints.down('md')]: {
        height: '30px',
      },
    },
    '& aside': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
      },
    }
  },
  navigationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 'auto',
    transform: 'translate(-50%, -50%)',

    '& > li': {
      backgroundColor: 'transparent !important',
    },

    [theme.breakpoints.down('md')]: {
      left: 0,
      top: 0,
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1rem 0.5rem',
      position: 'relative',
      transform: 'none',
    },
  },
  navigation: {
    padding: '0 2rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',

    [theme.breakpoints.down('lg')]: {
      padding: '0',
    },
  },
  menuItem: {
    fontWeight: 600,
    padding: '0.6rem 0.8rem',
    margin: '.2rem 0.5rem',
  },
  active: {
    color: `${palette.text.primary} !important`
  },
  drawerPaper: {
    width: '100%',
    paddingTop: '60px',
  },
  search: {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: '#e6e6e6',
    '&:hover': {
      backgroundColor: '#d6d6d6',
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: '18px 12px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '24px',
    [theme.breakpoints.up(1440)]: {
      width: '20ch',
      '&:focus': {
        width: '24ch',
      },
    },
    [theme.breakpoints.down(1440)]: {
      width: '13ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export interface IHeaderProps {
  match: any;
  session: ISession;
  checkoutOrders: ICartArticle[];
  catalog: Catalog;
}

interface IState {
  isDrawerOpen: boolean;
  isSearchOpen: boolean;
}

export default function Header(props: IHeaderProps, context: IAppContext) {
  const { lang, dispatch, actions, push } = context;
  const { match, checkoutOrders, session, catalog } = props;
  const { path } = match;

  const classes = useStyles();
  const [state, setState] = React.useState<IState>({
    isDrawerOpen: false,
    isSearchOpen: false,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [catalogAnchorEl, setCatalogAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleCatalogMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setCatalogAnchorEl(event.currentTarget);
  };
  const handleCatalogMenuClose = () => {
    setCatalogAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setState(prevState => ({
      ...prevState,
      isDrawerOpen: !prevState.isDrawerOpen,
    }));
  };

  const handleSearchToggle = (open: boolean) => {
    setState(prevState => ({
      ...prevState,
      isSearchOpen: open,
    }));
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatch(push(`/catalogs/${catalog}/search?q=${escape((e.target as HTMLInputElement).value)}`));
    }
  };

  const navigateTo = (destination: string) => {
    handleDrawerToggle();
    dispatch(push(destination));
  };

  const menuView = (
    <Grid container className={classes.navigationContainer}>
      <MenuItem disableRipple onClick={() => navigateTo('/')} className={`${classes.navigation} ${path === '/' ? classes.active : ''}`}>{lang('common.Header.catalog')}</MenuItem>
      {/* <MenuItem disableRipple onClick={handleCatalogMenuClick} className={`${classes.navigation} ${path === '/catalogs/:catalog' || Boolean(catalogAnchorEl) ? classes.active : ''}`}>{lang('common.Header.catalog')}</MenuItem>
      <Menu
        anchorEl={catalogAnchorEl}
        open={Boolean(catalogAnchorEl)}
        onClose={handleCatalogMenuClose}
        PaperProps={{
          style: {
            marginTop: '3rem'
          }
        }}
      >
        <MenuItem button onClick={() => { handleCatalogMenuClose(); navigateTo('/catalogs/airex'); }} style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', whiteSpace: 'pre' }}>{catalogDisplayName[Catalog.AIREX](lang)}</MenuItem>
        <MenuItem button onClick={() => { handleCatalogMenuClose(); navigateTo('/catalogs/proxima'); }} style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>{catalogDisplayName[Catalog.PROXIMA](lang)}</MenuItem>
      </Menu> */}
      {/* <MenuItem disableRipple onClick={() => navigateTo('/company')} className={`${classes.navigation} ${path === '/company' ? classes.active : ''}`}>{lang('common.Header.company')}</MenuItem> */}
      <MenuItem disableRipple onClick={() => navigateTo('/public-documents')} className={`${classes.navigation} ${path === '/public-documents' ? classes.active : ''}`}>{lang('common.Header.download')}</MenuItem>
      {/* <MenuItem disableRipple onClick={() => navigateTo('/contacts')} className={`${classes.navigation} ${path === '/contacts' ? classes.active : ''}`}>{lang('common.Header.contacts')}</MenuItem> */}
      {/* <MenuItem disableRipple onClick={() => navigateTo('/favourite')} className={`${classes.navigation} ${path === '/favourite' ? classes.active : ''}`}>{lang('common.Header.favouriteProducts')}</MenuItem> */}
      {/* <MenuItem disableRipple onClick={() => navigateTo('/orders')} className={`${classes.navigation} ${path === '/orders' ? classes.active : ''}`}>{lang('common.Header.orders')}</MenuItem> */}
      <Hidden lgUp mdDown={session.authenticated}>
        <MenuItem disableRipple onClick={() => { if ((match.url).includes('/login')) { navigateTo('/login'); } else { navigateTo(`/login?redirectTo=${encodeURIComponent(match.url)}`); } }} className={`${classes.navigation} ${path === '/login' ? classes.active : ''}`}>{lang('common.Header.loginOrSignUp')}</MenuItem>
      </Hidden>
      <Link href="https://airex.it/airy" rel="noopener" target="_blank" style={{ marginLeft: '1rem', marginTop: '1rem' }}>
        <img src={require('../../images/airy.png')} alt="Airy" width={1280} height={672} style={{ width: 100, height: 'auto' }} />
      </Link>
      <Hidden lgUp mdDown={!session.authenticated}>
        <Divider style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%', opacity: 0.6 }} />
        <MenuItem disableRipple onClick={() => navigateTo('/user')} className={`${classes.navigation} ${path === '/user' ? classes.active : ''}`}>{lang('common.Header.user')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/')} className={`${classes.navigation} ${path === '/' ? classes.active : ''}`}>{lang('common.Header.newOrder')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/back-orders')} className={`${classes.navigation} ${path === '/back-orders' ? classes.active : ''}`}>{lang('common.Header.backorders')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/orders')} className={`${classes.navigation} ${path === '/orders' ? classes.active : ''}`}>{lang('common.Header.orders')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/fast-checkout')} className={`${classes.navigation} ${path === '/fast-checkout' ? classes.active : ''}`}>{lang('common.Header.fastCheckout')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/favourite')} className={`${classes.navigation} ${path === '/favourite' ? classes.active : ''}`}>{lang('common.Header.favouriteProducts')}</MenuItem>
        {(session.agent || session.hasQuotes)
          && <MenuItem disableRipple onClick={() => navigateTo('/my-quotes')} className={`${classes.navigation} ${path === '/my-quotes' ? classes.active : ''}`}>{lang('common.Header.myQuotes')}</MenuItem>}
        <MenuItem disableRipple onClick={() => navigateTo('/documents/fatture')} className={`${classes.navigation} ${path === '/documents/fatture' ? classes.active : ''}`}>{lang('common.Header.invoices')}</MenuItem>
        <MenuItem disableRipple onClick={() => navigateTo('/documents/documenti-trasporto')} className={`${classes.navigation} ${path === '/documents/documenti-trasporto' ? classes.active : ''}`}>{lang('common.Header.transportDocs')}</MenuItem>
        <MenuItem disableRipple onClick={() => window.open(window.configs.ENDPOINT + '/api/airy_tutorial', '_blank')} className={classes.navigation}>{lang('common.Header.airyTutorial')}</MenuItem>
        <MenuItem disableRipple onClick={() => { actions.session.logout(); navigateTo('/'); }} className={classes.navigation}>{lang('common.Header.logout')}</MenuItem>
      </Hidden>
    </Grid>
  );

  if (match.url.startsWith('/login')) {
    return (
      <header className={classes.container} style={{ height: 136 }}>
        <Hidden xsDown>
          <Link href="/" style={{ lineHeight: 0 }}>
            <img src={require('../../images/logo.svg')} alt={lang('common.Header.companyShortName')} />
          </Link>
        </Hidden>
        <h1 style={{
          width: '100%',
          textAlign: 'center',
          color: palette.accent.main,
          fontSize: '18px'
        }}
        >
          <img src={require('../../images/airy-logo.png')} alt="Airy" width={768} height={355} style={{ width: 100, height: 'auto', marginBottom: '1rem' }} /><br />
          {lang('user.Login.title')}
        </h1>
        <Hidden xsDown>
          <Link href="/" style={{ lineHeight: 0, visibility: 'hidden' }}>
            <img src={require('../../images/logo.svg')} alt={lang('common.Header.companyShortName')} />
          </Link>
        </Hidden>
      </header>
    );
  }

  return (
    <header className={classes.container}>
      <Hidden mdDown={state.isSearchOpen}>
        <Link href="/" style={{ lineHeight: 0 }}>
          <img src={require('../../images/logo.svg')} alt={lang('common.Header.companyShortName')} />
        </Link>
      </Hidden>
      <Hidden mdDown>
        {menuView}
      </Hidden>
      <aside style={{ display: 'flex', alignItems: 'center' }}>
        <Hidden lgUp mdDown={state.isSearchOpen}>
          <IconButton onClick={handleSearchToggle.bind(null, true)}>
            <SearchRounded style={{ color: 'black' }} />
          </IconButton>
        </Hidden>
        <Hidden mdDown={!state.isSearchOpen}>
          <ClickAwayListener onClickAway={handleSearchToggle.bind(null, false)}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchRounded />
              </div>
              <InputBase
                placeholder={lang('common.Header.searchProduct')}
                onKeyPress={onSearch}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </ClickAwayListener>
        </Hidden>
        <Hidden mdDown={state.isSearchOpen}>
          <Hidden mdDown>
            {
              session.authenticated ? (
                <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleMenuClick} style={{ marginLeft: '1rem' }}>
                  <AccountBoxRounded style={{ color: 'black', fontSize: '1.6rem' }} />
                </IconButton>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => { if ((match.url).includes('/login')) { dispatch(push('/login')); } else { dispatch(push(`/login?redirectTo=${encodeURIComponent(match.url)}`)); } }}
                  style={{ marginLeft: '1rem',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.15rem',
                    borderWidth: '1.5px',
                    minWidth: 'min-content',
                    whiteSpace: 'nowrap', }}
                >
                  <span style={{ fontWeight: 600, marginTop: 2 }}>{lang('common.Header.loginOrSignUp')}</span>
                  <KeyboardArrowRightRounded style={{ fontSize: '1.4rem', marginLeft: '.15rem' }} />
                </Button>
              )
            }
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/user')); }}>{lang('common.Header.user')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/')); }}>{lang('common.Header.newOrder')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/back-orders')); }}>{lang('common.Header.backorders')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/orders')); }}>{lang('common.Header.orders')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/fast-checkout')); }}>{lang('common.Header.fastCheckout')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/favourite')); }}>{lang('common.Header.favouriteProducts')}</MenuItem>
              {(session.agent || session.hasQuotes)
                && <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/my-quotes')); }}>{lang('common.Header.myQuotes')}</MenuItem>}
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/documents/fatture')); }}>{lang('common.Header.invoices')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); dispatch(push('/documents/documenti-trasporto')); }}>{lang('common.Header.transportDocs')}</MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => { handleMenuClose(); window.open(window.configs.ENDPOINT + '/api/airy_tutorial', '_blank'); }}>{lang('common.Header.airyTutorial')}</MenuItem>
            </Menu>
          </Hidden>
          <Hidden xsUp={!session.authenticated}>
            <IconButton onClick={() => { dispatch(push('/checkout')); }} style={{ marginRight: '1rem' }}>
              <Badge
                overlap="rectangular"
                badgeContent={checkoutOrders.length}
                color="primary"
                invisible={checkoutOrders.length === 0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ShoppingCartRounded style={{ color: 'black' }} />
              </Badge>
            </IconButton>
          </Hidden>
          <Hidden mdDown lgUp={!session.authenticated}>
            <IconButton onClick={() => { actions.session.logout(); navigateTo('/'); }}>
              <ExitToAppRounded style={{ color: 'black', fontSize: '1.57rem' }} />
            </IconButton>
          </Hidden>
        </Hidden>
        <Hidden lgUp>
          <IconButton onClick={handleDrawerToggle}>
            <MenuRounded style={{ color: 'black' }} />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="top"
            open={state.isDrawerOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true, }}
          >
            {menuView}
          </Drawer>
        </Hidden>
      </aside>
    </header>
  );
}

Header.contextTypes = { ...AppContext };
