import { RSAA } from 'redux-api-middleware';
import { MiddlewareAPI } from 'redux';
import { IState, Role } from '../state/data-types';

const agentParamsMiddleware = (store: MiddlewareAPI<any, IState>) => (next: any) => (action: any) => {
  if (action[RSAA] !== undefined && store.getState().session.role === Role.Agent) {
    // eslint-disable-next-line no-param-reassign
    action[RSAA].query = {
      ...action[RSAA].query,
      clientId: store.getState().session.agent?.selectedClient?.id,
    };
  }
  return next(action);
};

export default agentParamsMiddleware;
