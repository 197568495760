import React from 'react';
import {
  CircularProgress,
  Container,
  Hidden,
  Link,
  makeStyles,
} from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppContext, IAppContext } from '../../containers/App';
import { IApi } from '../../state/data-types';
import { palette } from '../../styles/theme.style';
import { AspectRatio } from '../AspectRatio';

export interface IBannersProps {
  api: IApi;
}

const useStyles = makeStyles((theme) => ({
  imgBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '2rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  img: {
    objectFit: 'cover',
    objectPosition: 'center center',
    height: '100%',
    width: '100%',
    maxWidth: 'none',
    maxHeight: 'none',
  },
  cta: {
    background: palette.accent.main,
    color: 'white !important',
    fontWeight: 'bold',
    borderRadius: 4,
    padding: '10px 10px',
  },
  ctaBox: {
    color: 'white',
    padding: '1rem 1rem',
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'rgba(0,0,0,0.7)',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      bottom: 'auto',
    }
  },
}));

export default function Banners(props: IBannersProps, context: IAppContext) {
  const classes = useStyles();
  const { banners } = props.api;

  React.useEffect(() => {
    context.actions.api.getBanners(2);
  }, []);

  const isLarge = banners.items.length === 1;

  return (
    <>
      {banners.items.length > 0 && (
        <section
          style={{ backgroundColor: 'rgba(0,0,0,0.05)', padding: '1rem 0' }}
        >
          <Container>
            <div
              className={classes.imgBox}
            >
              {banners.items.map((banner, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexGrow: 1,
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 0,
                        flexGrow: 1,
                        maxWidth: isLarge ? 1200 : 600,
                      }}
                    >
                      <AspectRatio aspectRatio={isLarge ? 4 : 2} style={{ zIndex: 0 }}>
                        <img
                          className={classes.img}
                          src={banner.image}
                          key={`banner_${banner.name}_${i}`}
                          alt={banner.image_alt_tag || banner.name}
                        />
                      </AspectRatio>
                      {(banner.title || banner.description || banner.cta) && (
                      <div
                        className={classes.ctaBox}
                      >
                        {banner.title && (
                          <div style={{ marginBottom: 16 }}>
                            <strong style={{ fontSize: '1.7rem' }}>
                              {banner.title}
                            </strong>
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                            gap: '2rem',
                          }}
                        >
                          {banner.description && (
                            <strong style={{ fontSize: '1.125rem' }}>
                              {banner.description}
                            </strong>
                          )}
                          {banner.cta && (
                            <Link
                              style={{
                                display: 'block',
                                position: 'relative',
                                zIndex: 0,
                                whiteSpace: 'nowrap',
                              }}
                              className={classes.cta}
                              href={banner.link}
                            >
                              {banner.cta}
                            </Link>
                          )}
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <Hidden xsUp={!banners.loading}>
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <CircularProgress size={30} />
                </div>
              </Hidden>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}

Banners.contextTypes = { ...AppContext };
