import actionTypes from '../state/action-types';
import actions from '../state/actions';

const unauthorizeMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
  if (action.type !== actionTypes.API_LOGIN_FAILURE && action.payload && action.payload.status === 401) {
    dispatch(actions.session.logout());
  }

  return next(action);
};

export default unauthorizeMiddleware;
