import React, { useEffect, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  Hidden,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Theme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import {
  AddCircleOutlineRounded,
  AddShoppingCartRounded,
  AttachFileRounded,
  ChevronRightRounded,
  CloseRounded,
  KeyboardArrowRightRounded,
  RemoveCircleOutlineRounded,
  Star,
  StarOutline,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { AppContext, IAppContext } from '../../containers/App';
import {
  IBreadcrumb,
  ICart,
  IProductItem,
  IProductSection,
  ISession,
} from '../../state/data-types';
import { isBlank } from '../../state/utils';
import Navigation from '../common/Navigation';
import { getPrimaryColor } from '../../containers/home/HomePage';
import { Catalog } from '../../globals/constants';
import { priceToString } from '../../globals/localization';
import { palette } from '../../styles/theme.style';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    marginTop: '2rem',
  },
  title: {
    marginTop: '3rem',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: '1rem',
    padding: '0 3rem',
  },
  tableHeader: {
    padding: '0rem 2rem',
    position: 'relative',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    color: '#636363',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  productHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem 2rem 0',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0',
    },
  },
  productBrandLogo: {
    '& > img': {
      maxWidth: '200px',
    },
  },
  productBrandName: {
    fontSize: '1.2rem',
    opacity: 0.6,
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
      lineHeight: '1rem',
    },
  },
  productCode: {
    marginTop: '1rem',
    fontSize: '2rem',
    lineHeight: '2.2rem',
    padding: '0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      lineHeight: '1.8rem',
    },
  },
  productTitle: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    padding: '.5rem 0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
    },
  },
  productDescription: {
    fontSize: '1rem',
    lineHeight: '1.4rem',
    padding: '0rem 0 1rem',
    textAlign: 'justify',

    [theme.breakpoints.down('sm')]: {
      padding: '0 0 1rem',
      fontSize: '1rem',
      lineHeight: '1.2rem',
    },
    '& p': {
      marginTop: '0',
      marginBottom: '.5rem',
    },
    '& ul': {
      marginTop: '0',
    },

    '& img': {
      maxWidth: '100%',
    },
  },
  productPictogram: {
    maxWidth: '60px',
    marginRight: '.5rem',
  },
  productContainer: {
    borderRadius: '3px',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    marginBottom: '2rem',
    height: '350px',
    width: '350px',
    overflow: 'hidden',
    margin: '1rem 3rem 1rem 2rem',
    cursor: 'pointer',
    outline: 'none',

    [theme.breakpoints.down('sm')]: {
      borderRadius: '100px',
      height: '150px',
      width: '150px',
      margin: '2rem auto 0',
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '400px',
      margin: '1rem 0',
    },
  },
  previewThumb: {
    height: '100px',
    width: '100px',
    position: 'relative',
    cursor: 'pointer',
    outline: 'none',
    margin: '0 5px',

    '& > img': {
      maxWidth: '100px',
      maxHeight: '100px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 1.5rem',

    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
}));

export interface IProductsProps {
  cart: ICart;
  product?: IProductSection;
  loading: boolean;
  breadcrumbs: Array<IBreadcrumb>;
  catalog: string;
  brand?: string;
  family?: string;
  group?: string;
  category?: string;
  subcategory?: string;
  session: ISession;
  showPrices: boolean;
}

export interface IState {
  selectedIndex: number;
  isModalOpen: boolean;
}

export default function Products(props: IProductsProps, context: IAppContext) {
  const { cart, catalog, brand, product, loading, breadcrumbs, session, showPrices } = props;
  const { lang } = context;
  const classes = useStyles();

  const [modalState, setModalState] = React.useState<IState>({
    selectedIndex: 0,
    isModalOpen: false,
  });
  const toggleModal = (index: number) => {
    setModalState({
      selectedIndex: index,
      isModalOpen: !modalState.isModalOpen,
    });
  };

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    const searchParam = new URLSearchParams(location.search).get('search')?.trim().toLowerCase();
    if (product && searchParam) {
      const candidateIndex = product.items.findIndex(({ code }) => searchParam === code.trim().toLowerCase());
      if (candidateIndex !== -1) {
        setSelectedIndex(candidateIndex);
      }
    }
  }, [product]);

  /* const showInfoLabel = product !== undefined
    && product.items !== undefined
    && product.items.filter(
      (item: any) => item.attributes.length > 3 || item.document !== null
    ).length > 0; */

  return (
    <section className="static-page" style={{ backgroundColor: '#ffffff' }}>
      <div
        className="hero"
        style={{ backgroundColor: getPrimaryColor(catalog as Catalog).main }}
      >
        <h1>{lang('catalogs.Products.ourProducts')}</h1>
        <h4>
          <Navigation
            breadcrumbs={breadcrumbs}
            catalog={catalog}
            brand={brand}
          />
        </h4>
      </div>
      <Container className="content">
        <Hidden xsUp={!loading}>
          <div
            style={{
              display: 'flex',
              padding: '3rem',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress size={30} />
          </div>
        </Hidden>
        <Hidden xsUp={loading}>
          {product !== undefined ? (
            <Grid container>
              <Grid item xs={12}>
                <Box boxShadow={1} className={classes.productContainer}>
                  <div className={classes.productHeader}>
                    {product.preview
                    || (product.gallery && product.gallery.length > 0) ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          className={classes.preview}
                          onClick={toggleModal.bind(null, 0)}
                          onKeyDown={toggleModal.bind(null, 0)}
                          role="button"
                          tabIndex={0}
                        >
                          {product.preview ? (
                            <img
                              src={product.preview}
                              alt={product.image_alt_tag}
                            />
                          ) : null}
                          {product.gallery && product.gallery.length > 0 ? (
                            <ModalGateway>
                              {modalState.isModalOpen ? (
                                <Modal
                                  onClose={toggleModal.bind(
                                    null,
                                    modalState.selectedIndex
                                  )}
                                >
                                  <Carousel
                                    views={
                                      product.gallery.map((img) => {
                                        return { source: img };
                                      }) || []
                                    }
                                    currentIndex={modalState.selectedIndex}
                                    modalProps={{ allowFullscreen: false }}
                                    components={{
                                      FooterCaption: () => <div />,
                                    }}
                                  />
                                </Modal>
                              ) : null}
                            </ModalGateway>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflowX: 'auto',
                          }}
                        >
                          {product.gallery && product.gallery.length > 1
                            ? product.gallery.slice(1).map((image, index) => {
                              return (
                                <div
                                  className={classes.previewThumb}
                                  key={`thumbs_${image}`}
                                  onClick={toggleModal.bind(null, index + 1)}
                                  onKeyDown={toggleModal.bind(
                                    null,
                                    index + 1
                                  )}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <img
                                    src={image}
                                    alt={product.image_alt_tag}
                                  />
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </div>
                      ) : null}
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '2rem 2rem 2rem 1rem',
                      }}
                    >
                      {product.brand_image ? (
                        <div>
                          <img
                            src={product.brand_image}
                            className={classes.productBrandLogo}
                            alt={product.brand_name}
                            style={{ maxHeight: '45px' }}
                          />
                        </div>
                      ) : (
                        <span className={classes.productBrandName}>
                          {product.brand_name}
                        </span>
                      )}
                      <Hidden xsUp={!(product.showCode ?? true)}>
                        <span className={classes.productCode}>
                          {product.code}
                        </span>
                      </Hidden>
                      <span className={classes.productTitle}>
                        {product.label}
                      </span>
                      <span
                        className={classes.productDescription}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: product.description_html || '',
                        }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '1rem',
                          flexWrap: 'wrap',
                        }}
                      >
                        {product.pictograms
                          ? product.pictograms.map((image) => {
                            return (
                              <img
                                className={classes.productPictogram}
                                src={image}
                                alt=""
                                key={`pictograms_${image}`}
                              />
                            );
                          })
                          : null}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: 'auto',
                          }}
                        >
                          {product.document && !isBlank(product.document) ? (
                            <a
                              href={product.document}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <AttachFileRounded
                                style={{
                                  color: palette.text.primary,
                                  transform: 'rotate(45deg)',
                                  fontSize: '1.2rem',
                                  marginRight: '.5rem',
                                }}
                              />
                              {lang('catalogs.Products.attachmentNo', {
                                number: 1,
                              })}
                            </a>
                          ) : null}
                          {product.document2 && !isBlank(product.document2) ? (
                            <a
                              href={product.document2}
                              style={{
                                marginLeft: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <AttachFileRounded
                                style={{
                                  color: palette.text.primary,
                                  transform: 'rotate(45deg)',
                                  fontSize: '1.2rem',
                                  marginRight: '.5rem',
                                }}
                              />
                              {lang('catalogs.Products.attachmentNo', {
                                number: 2,
                              })}
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.items}>
                    <Hidden
                      smDown
                      xsUp={!product.items || product.items.length === 0}
                    >
                      <Box className={classes.row}>
                        <Grid container className={classes.tableHeader}>
                          <Grid item xs={2} className={classes.label}>
                            {lang('catalogs.Products.code')}
                          </Grid>
                          <Grid item xs={7} className={classes.label}>
                            {product
                            && product.items
                            && product.items.length > 0
                              ? product.items[0].attributes
                                .slice(0, 3)
                                .map((attribute: any, i: any) => {
                                  return (
                                    <Grid
                                      item
                                      xs={4}
                                      className={classes.label}
                                      key={`attr_${i}`}
                                    >
                                      {Object.keys(attribute)[0]}
                                    </Grid>
                                  );
                                })
                              : null}
                          </Grid>
                          <Grid item xs={2} className={classes.label}>
                            {lang('catalogs.Products.lotSize')}
                          </Grid>
                          <Grid item xs={1} className={classes.label}>
                            {/* {lang('catalogs.Products.favourite')} */}
                          </Grid>
                        </Grid>
                        <Hidden xsUp={!session.authenticated}>
                          <Box className={classes.label} flex="0 0 14rem">
                            {lang('catalogs.Products.order')}
                          </Box>
                          {showPrices
                            && (
                            <Box className={classes.label} style={{ justifyContent: 'flex-end' }} flex="0 0 8rem">
                              {lang('catalogs.Products.price')}
                            </Box>
                            )}
                        </Hidden>
                      </Box>
                    </Hidden>
                    {product.items
                      ? product.items.map(
                        (item: IProductItem, index: number) => (
                          <ProductRow
                            selected={index === selectedIndex}
                            onSelect={() => setSelectedIndex(index)}
                            onUnselect={() => setSelectedIndex(null)}
                            cart={cart}
                            product={product}
                            item={item}
                            index={index}
                            session={session}
                            key={`product_item_${index}`}
                            showPrices={showPrices}
                          />
                        )
                      )
                      : null}
                  </div>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Hidden>
      </Container>
      <LoginCTABanner session={session} />
    </section>
  );
}

interface ILoginCTABannerProps {
  session: ISession;
}

function LoginCTABanner(props: ILoginCTABannerProps, context: IAppContext) {
  const { session } = props;
  const { dispatch, push, lang } = context;
  if (session.authenticated) {
    return <></>;
  }
  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      {/* shadow only on the top part */}
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: '50%',
          zIndex: 0,
        }}
        boxShadow={3}
      />
      <Grid
        container
        style={{ position: 'relative',
          padding: '1rem calc(2rem + 12px)',
          background: palette.accent.light,
          borderBottom: '1px solid #dadada',
          zIndex: 1 }}
      >
        <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <div style={{ fontWeight: 600 }}>
            {lang('catalogs.Products.loginCTA')}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '1rem',
          }}
          >
            <Button
              variant="outlined"
              onClick={() => { if ((location.pathname).includes('/login')) { dispatch(push('/login')); } else { dispatch(push(`/login?redirectTo=${encodeURIComponent(location.pathname)}`)); } }}
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.15rem',
                borderWidth: '1.5px',
                minWidth: 'min-content',
                color: 'white',
                borderColor: 'white',
                whiteSpace: 'nowrap', }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>{lang('catalogs.Products.loginOrSignUp')}</span>
              <KeyboardArrowRightRounded style={{ fontSize: '1.4rem', marginLeft: '.15rem' }} />
            </Button>
            {/* <Button
              variant="contained"
              onClick={() => setShow(false)}
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.15rem',
                borderWidth: '1.5px',
                minWidth: 'min-content',
                whiteSpace: 'nowrap', }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>{lang('catalogs.Products.ignore')}</span>
              <CloseRounded style={{ fontSize: '1.4rem', marginLeft: '.15rem' }} />
            </Button> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
LoginCTABanner.contextTypes = { ...AppContext };

Products.contextTypes = { ...AppContext };

const itemStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    position: 'relative',
    padding: '1.2rem 2rem',
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  },
  even: {
    backgroundColor: '#FAFAFA',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontWeight: 'bold',
      fontSize: '.8rem',
      color: '#636363',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      marginTop: '1rem',
    },
    '&.inner': {
      color: '#636363',
      '& > span': {
        color: '#636363',
      },
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  innerContainer: {
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '0',
    margin: '1rem 0rem',
  },
  piecesWarning: {
    marginTop: '.1rem',
    fontSize: '.8rem',
    lineHeight: '1.2rem !important',
    fontWeight: 600,
    color: theme.palette.warning.dark,
  },
}));

export interface IProductProps {
  cart: ICart;
  product: IProductSection;
  item: IProductItem;
  index: number;
  session: ISession;
  showPrices: boolean;
  selected: boolean;
  onSelect(): void;
  onUnselect(): void;
}

function ProductRow(props: IProductProps, context: IAppContext) {
  const { item, index, session, cart, showPrices, selected, onSelect, onUnselect } = props;
  const { lang } = context;
  const classes = itemStyles();

  const [isOpen, setOpen] = React.useState<boolean>(false);
  const showAccordion = item.attributes.length > 3 || item.document !== null;

  const [quantity, setQuantity] = React.useState<number>(0);
  const [favourite, setFavourite] = React.useState<boolean>(
    item.isFavorite ?? false
  );
  const [settingFavourite, setSettingFavourite] = React.useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = parseInt(event.target.value, 10);
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    setQuantity(newQuantity);
  };

  const onAdd = () => {
    const newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (newQuantity % pieces !== 0) {
      setQuantity(newQuantity + (pieces - (newQuantity % pieces)));
    } else {
      setQuantity(newQuantity + pieces);
    }
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onToggleFavourite = () => {
    setSettingFavourite(true);
    context.actions.session
      .setFavouriteProductItem({
        articleId: item.id,
        favourite: !favourite,
      })
      .then(({ error, payload }) => (error ? Promise.reject(payload) : Promise.resolve()))
      .then(
        () => {
          const newFavourite = !favourite;
          setFavourite(newFavourite);
          const key = enqueueSnackbar(
            newFavourite
              ? lang('catalogs.Products.productAddedToFavourite', {
                code: item.code,
              })
              : lang('catalogs.Products.productRemovedFromFavourite', {
                code: item.code,
              }),
            {
              variant: 'success',
              action: (
                <Button
                  style={{ color: 'white' }}
                  onClick={() => closeSnackbar(key)}
                >
                  <CloseRounded />
                </Button>
              ),
            }
          );
        },
        () => {
          console.error('unable to set favorite item');
          const key = enqueueSnackbar(
            lang('catalogs.Products.favouriteRequestError'),
            {
              variant: 'error',
              action: (
                <Button
                  style={{ color: 'white' }}
                  onClick={() => closeSnackbar(key)}
                >
                  <CloseRounded />
                </Button>
              ),
            }
          );
        }
      )
      .finally(() => setSettingFavourite(false));
  };

  const onRemove = () => {
    let newQuantity = quantity;
    const pieces = parseInt(item.pieces, 10);
    if (quantity % pieces !== 0) {
      newQuantity = quantity - (quantity % pieces);
    } else {
      newQuantity = quantity - pieces;
    }
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    setQuantity(newQuantity);
  };

  const addToCart = () => {
    if (quantity > 0) {
      const pieces = parseInt(item.pieces, 10);
      const { product } = props;
      const article = {
        id: item.id,
        code: item.code,
        description: item.label,
        quantity:
          quantity % pieces === 0
            ? quantity
            : quantity + (pieces - (quantity % pieces)),
        preview: product.preview,
        price: item.price,
        url: `/catalogs/${product.catalog}/${product.family}/${product.category}/${product.subcategory}/${product.slug}`,
        pieces: item.pieces,
      };

      context.actions.session.addArticlesToCart(cart, [article]);
      setQuantity(0);
      enqueueSnackbar(lang('catalogs.Products.productAddedToCart'), {
        variant: 'success',
        action: (
          <Button
            variant="text"
            style={{ color: 'white', textTransform: 'none', fontWeight: 600 }}
            onClick={() => {
              closeSnackbar();
              context.dispatch(context.push('/checkout'));
            }}
          >
            {lang('catalogs.Products.goToCheckout')}
          </Button>
        ),
      });
    }
  };

  const showPiecesNumberWarning = quantity % parseInt(item.pieces, 10) !== 0;
  const packsCount = (quantity
      + (parseInt(item.pieces, 10) - (quantity % parseInt(item.pieces, 10))))
    / parseInt(item.pieces, 10);
  const piecesCount = quantity
    + (parseInt(item.pieces, 10) - (quantity % parseInt(item.pieces, 10)));

  return (
    <Box
      className={`${classes.row} ${index % 2 !== 0 ? classes.even : ''}`}
      style={{
        border: selected ? `1px solid ${getPrimaryColor(Catalog.AIREX).main}` : '1px solid transparent'
      }}
      onClick={onSelect}
    >
      <Grid
        container
        className={classes.container}
        key={`product_item_${index}`}
        onClick={() => {
          if (item.attributes.length > 3 || item.document !== null) {
            setOpen(!isOpen);
          }
        }}
      >
        <ChevronRightRounded
          style={{
            display: showAccordion ? 'block' : 'none',
            position: 'absolute',
            right: '4rem',
            top: '1.5rem',
            transform: `rotate(${isOpen ? '90' : '-90'}deg)`,
            transition: 'transform .5s',
          }}
        />
        <Grid
          item
          xs={12}
          md={2}
          className={classes.label}
          style={{ fontWeight: isOpen ? 600 : 400 }}
        >
          <Hidden mdUp>
            <span>{lang('catalogs.Products.code')}</span>
          </Hidden>
          {item.code}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={7}
          style={{ fontWeight: isOpen ? 600 : 400 }}
        >
          {item.attributes.slice(0, 3).map((attribute: any, i: any) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                className={classes.label}
                key={`attr_${i}`}
              >
                {i === 0 ? (
                  <Hidden mdUp>
                    <span>{lang('catalogs.Products.details')}</span>
                  </Hidden>
                ) : null}
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <Hidden smDown>{`${Object.values(attribute)[0]}`}</Hidden>
                  <Hidden mdUp>
                    {`${Object.keys(attribute)[0].trimEnd()}: ${
                      Object.values(attribute)[0]
                    }`}
                  </Hidden>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          className={classes.label}
          style={{ fontWeight: isOpen ? 600 : 400 }}
        >
          <Hidden mdUp>
            <span>{lang('catalogs.Products.lotSize')}</span>
          </Hidden>
          {item.pieces}
        </Grid>
        {showPrices
        && (
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              md={2}
              className={classes.label}
              style={{ fontWeight: isOpen ? 600 : 400 }}
            >
              <Hidden mdUp>
                <span>{lang('catalogs.Products.price')}</span>
              </Hidden>
              {priceToString('€', item.price, lang('global.priceUponRequest'))}
            </Grid>
          </Hidden>
        )}
        <Grid item xs={12} md={1} style={{ textAlign: 'right' }}>
          {session.authenticated && (
            <IconButton
              size="small"
              edge="start"
              style={{ opacity: settingFavourite ? '.8' : '1' }}
              disabled={settingFavourite}
              onClick={(e) => {
                e.stopPropagation();
                onToggleFavourite();
                onSelect();
              }}
            >
              {favourite ? (
                <Star htmlColor="#467abf" />
              ) : (
                <StarOutline htmlColor="#467abf" />
              )}
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isOpen}>
            <Grid container className={classes.innerContainer}>
              <Hidden xsUp={item.attributes.length <= 3}>
                <Grid item xs={12} lg={3} className={`${classes.label} inner`}>
                  <span>{lang('catalogs.Products.moreDetails')}</span>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {item.attributes.slice(3).map((attribute: any) => {
                      return `${Object.keys(attribute)[0].trimEnd()}: ${
                        Object.values(attribute)[0]
                      }\n`;
                    })}
                  </div>
                </Grid>
              </Hidden>
              <Hidden xsUp={item.document === null}>
                <Grid item xs={12} lg={3} className={`${classes.label} inner`}>
                  <span>{lang('catalogs.Products.dataSheet')}</span>
                  <a href={item.document} style={{ fontSize: '.9rem' }}>
                    {lang('catalogs.Products.download')}
                  </a>
                </Grid>
              </Hidden>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <Hidden xsUp={!session.authenticated}>
        <Box flex="0 0 14rem">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FormControl
              variant="standard"
              margin="dense"
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <OutlinedInput
                id={`product-quantity-${item.code}`}
                type="number"
                value={quantity.toString()}
                onChange={handleChange}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelect();
                }}
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                startAdornment={(
                  <InputAdornment position="start">
                    <IconButton
                      size="small"
                      edge="start"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemove();
                        onSelect();
                      }}
                    >
                      <RemoveCircleOutlineRounded />
                    </IconButton>
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAdd();
                        onSelect();
                      }}
                    >
                      <AddCircleOutlineRounded />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addToCart();
                onUnselect();
              }}
            >
              <AddShoppingCartRounded />
            </IconButton>
          </div>
          <Collapse in={showPiecesNumberWarning}>
            <span className={classes.piecesWarning}>
              {lang('catalogs.Products.orderPackageSize', {
                smart_count: packsCount,
                packageSize: piecesCount,
              })}
            </span>
          </Collapse>
        </Box>
        {showPrices
        && (
        <Hidden smDown>
          <Box flex="0 0 8rem" style={{ textAlign: 'right' }}>
            {priceToString('€', item.price, lang('global.priceUponRequest'))}
          </Box>
        </Hidden>
        )}
      </Hidden>
    </Box>
  );
}

ProductRow.contextTypes = { ...AppContext };
