/* eslint-disable @typescript-eslint/camelcase */
import Redux from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';
import initialState from '../initial-state';
import types from '../action-types';
import { IApi } from '../data-types';

export default function apiReducer(state: IApi = initialState.api, action: Redux.AnyAction): IApi {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state,
        search: {
          products: [],
          loading: false,
          error: null,
        },
        contacts: {
          loading: false,
          error: null,
        }
      };
    case types.API_LOGOUT_SUCCESS:
      return initialState.api;
    case types.GET_BRANDS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          brands: [],
        }
      };
    case types.GET_BRANDS_SUCCESS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          brands: action.payload.brands || [],
        }
      };
    case types.GET_CATALOGS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          product: undefined,
          items: [],
          breadcrumbs: [],
          loading: true
        }
      };
    case types.GET_CATALOGS_SUCCESS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          product: action.payload.product,
          items: action.payload.catalogs || action.payload.categories,
          breadcrumbs: action.payload.breadcrumbs,
          loading: false,
          error: action.payload.status === false ? action.payload.error : undefined,
        },
        meta: action.payload.meta,
      };
    case types.GET_CATALOGS_FAILURE:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          loading: false
        }
      };
    case types.GET_CATALOGS_ITEMS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          product: undefined,
          items: [],
          breadcrumbs: [],
          loading: true
        }
      };
    case types.GET_CATALOGS_ITEMS_SUCCESS:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          product: action.payload.product,
          items: action.payload.categories,
          breadcrumbs: action.payload.breadcrumbs,
          loading: false,
          error: action.payload.status === false ? action.payload.error : undefined,
        },
        meta: action.payload.meta,
      };
    case types.GET_CATALOGS_ITEMS_FAILURE:
      return { ...state,
        catalogs: {
          ...state.catalogs,
          loading: false
        }
      };
    case types.SEARCH_PRODUCTS:
      return { ...state,
        search: {
          ...state.search,
          loading: true
        }
      };
    case types.SEARCH_PRODUCTS_SUCCESS:
      return { ...state,
        search: {
          products: action.payload.products,
          loading: false,
          error: null,
        }
      };
    case types.SEARCH_PRODUCTS_FAILURE:
      return { ...state,
        search: {
          ...state.search,
          loading: false,
        }
      };
    case types.GET_NEWS:
      return {
        ...state,
        news: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_NEWS_SUCCESS:
      return { ...state,
        news: {
          items: action.payload.news,
          loading: false,
          error: null,
        }
      };
    case types.GET_NEWS_FAILURE:
      return { ...state,
        news: {
          ...state.news,
          loading: false
        }
      };
    case types.GET_FEATURED:
      return { ...state,
        featured: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_FEATURED_SUCCESS:
      return { ...state,
        featured: {
          items: action.payload.products,
          loading: false,
          error: null,
        }
      };
    case types.GET_FEATURED_FAILURE:
      return { ...state,
        featured: {
          ...state.featured,
          loading: false
        }
      };
    case types.GET_RECENTLY_SEEN:
      return { ...state,
        recentlySeen: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_RECENTLY_SEEN_SUCCESS:
      return { ...state,
        recentlySeen: {
          items: action.payload.products,
          loading: false,
          error: null,
        }
      };
    case types.GET_RECENTLY_SEEN_FAILURE:
      return { ...state,
        recentlySeen: {
          ...state.featured,
          loading: false
        }
      };
    case types.GET_BANNERS:
      return { ...state,
        banners: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_BANNERS_SUCCESS:
      return { ...state,
        banners: {
          items: action.payload.banners,
          loading: false,
          error: null,
        }
      };
    case types.GET_BANNERS_FAILURE:
      return { ...state,
        banners: {
          ...state.banners,
          loading: false
        }
      };
    case types.GET_REVIEWS:
      return { ...state,
        reviews: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_REVIEWS_SUCCESS:
      return { ...state,
        reviews: {
          items: action.payload.reviews,
          loading: false,
          error: null,
        }
      };
    case types.GET_REVIEWS_FAILURE:
      return { ...state,
        reviews: {
          ...state.reviews,
          loading: false
        }
      };
    case types.GET_PARTNERS:
      return { ...state,
        partners: {
          items: [],
          loading: true,
          error: null,
        }
      };
    case types.GET_PARTNERS_SUCCESS:
      return { ...state,
        partners: {
          items: action.payload.partner,
          loading: false,
          error: null,
        }
      };
    case types.GET_PARTNERS_FAILURE:
      return { ...state,
        partners: {
          ...state.partners,
          loading: false
        }
      };
    case types.SEND_FORM:
      return { ...state,
        contacts: {
          loading: !action.error,
          error: action.error,
        }
      };
    case types.SEND_FORM_SUCCESS:
      return { ...state,
        contacts: {
          loading: false,
          error: action.payload.status ? null : action.payload.error,
        }
      };
    case types.SEND_FORM_FAILURE:
      return { ...state,
        contacts: {
          loading: false,
          error: action.error,
        }
      };
    case types.API_META_INFO:
      return { ...state,
        meta: {
          meta_title: '',
          meta_description: '',
        }
      };
    case types.API_META_INFO_SUCCESS:
      return { ...state,
        meta: action.payload.meta,
      };
    case types.API_GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          items: [],
          loading: true,
          // page: 0,
        }
      };
    case types.API_GET_ORDERS_SUCCESS:
      return { ...state,
        orders: {
          ...state.orders,
          items: action.payload.status ? action.payload.orders : [],
          loading: false,
          pageCount: action.payload.status ? action.payload.page : 0,
        }
      };
    case types.API_GET_ORDERS_FAILURE:
      return { ...state,
        orders: {
          ...state.orders,
          loading: false,
        }
      };
    case types.API_REFRESH_ORDERS:
      return { ...state,
        orders: {
          ...state.orders,
          refreshing: true
        },
        backOrders: {
          ...state.backOrders,
          refreshing: true
        }
      };
    case types.API_REFRESH_ORDERS_SUCCESS:
      return { ...state,
        orders: {
          ...state.orders,
          refreshing: false
        },
        backOrders: {
          ...state.backOrders,
          refreshing: false
        }
      };
    case types.API_REFRESH_ORDERS_FAILURE:
      return { ...state,
        orders: {
          ...state.orders,
          refreshing: false
        },
        backOrders: {
          ...state.backOrders,
          refreshing: false
        }
      };
    case types.API_GET_QUOTES:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          items: [],
          loading: true,
        }
      };
    case types.API_GET_QUOTES_SUCCESS:
      return { ...state,
        quotes: {
          ...state.quotes,
          items: action.payload.status ? action.payload.wishlists : [],
          loading: false,
          pageCount: action.payload.status ? action.payload.page : 0,
        }
      };
    case types.API_GET_QUOTES_FAILURE:
      return { ...state,
        quotes: {
          ...state.quotes,
          loading: false,
        }
      };
    case types.API_GET_BACK_ORDERS:
      return {
        ...state,
        backOrders: {
          ...state.backOrders,
          items: [],
          loading: true,
          // page: 0,
        }
      };
    case types.API_GET_BACK_ORDERS_SUCCESS:
      return { ...state,
        backOrders: {
          ...state.backOrders,
          items: action.payload.status ? action.payload.orders : [],
          loading: false,
          pageCount: action.payload.status ? action.payload.page : 0,
        }
      };
    case types.API_GET_BACK_ORDERS_FAILURE:
      return { ...state,
        backOrders: {
          ...state.backOrders,
          loading: false,
        }
      };
    case types.GET_DOCUMENT_CATEGORIES:
      return {
        ...state,
        documents: {
          ...state.documents,
          items: [],
          categories: [],
          loading: true
        }
      };
    case types.GET_DOCUMENT_CATEGORIES_SUCCESS:
      return { ...state,
        documents: {
          ...state.documents,
          categories: action.payload.documentsCategory,
          loading: false
        }
      };
    case types.GET_DOCUMENT_CATEGORIES_FAILURE:
      return { ...state,
        documents: {
          ...state.documents,
          loading: false
        }
      };
    case types.GET_DOCUMENT_CATEGORY_FILES:
      return {
        ...state,
        documents: {
          ...state.documents,
          breadcrumbs: [],
          items: [],
          loading: true
        }
      };
    case types.GET_DOCUMENT_CATEGORY_FILES_SUCCESS:
      return { ...state,
        documents: {
          ...state.documents,
          breadcrumbs: action.payload.breadcrumbs,
          items: action.payload.documents,
          loading: false
        },
        meta: action.payload.meta,
      };
    case types.GET_DOCUMENT_CATEGORY_FILES_FAILURE:
      return { ...state,
        documents: {
          ...state.documents,
          loading: false
        }
      };
    case types.API_GET_FAVOURITE_PRODUCTS:
      return { ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          loading: true,
          error: null,
        },
      };
    case types.API_GET_FAVOURITE_PRODUCTS_SUCCESS:
      return { ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          products: action.payload.products,
          pageCount: action.payload.status ? (action.payload.page ?? 0) : 0,
          loading: false,
          error: null,
        },
      };
    case types.API_GET_FAVOURITE_PRODUCTS_FAILURE:
      return { ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}
