import { RSAA } from 'redux-api-middleware';

const requiredHeadersMiddleware = () => (next: any) => (action: any) => {
  if (action[RSAA]) {
    const newAction = { ...action };
    if (!newAction[RSAA].headers) {
      newAction[RSAA].headers = {};
    }
    newAction[RSAA].headers.TimeZoneOffset = `${new Date().getTimezoneOffset()}`; // eslint-disable-line no-param-reassign
    newAction[RSAA].headers.Accept = 'application/json'; // eslint-disable-line no-param-reassign
    // newAction[RSAA].headers['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign

    return next(newAction);
  }
  return next(action);
};

export default requiredHeadersMiddleware;
